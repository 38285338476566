import React from 'react'

export default function Loader() {
  return (
    <div className='loader-section'>
      <div className="loader">
        <img src={require('../src/assets/images/loading.gif')} alt="loading" />
        <span></span>
      </div>
    </div>
  )
}
