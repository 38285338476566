
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blog from "../pages/blog";
import BlogList from "../pages/blog/BlogList";
import Home from "../Home";
import DestinationList from "../InnerScreen";
import DestinationDetailPage from "../InnerScreen/DetailPage";
import TourList from '../Tours';
import TourDetailPage from '../Tours/DetailPage';
import About from '../pages/about';
import Contact from '../pages/contact';
import Gallery from '../pages/gallery';
import Signup from '../pages/auth/Signup'
import Header from "../Header";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";
import BlogDetail from "../pages/blog/BlogDetails";
import Review from "../pages/review/Review";
import PropertyList from '../pages/Continent';
import Destination from '../pages/Continent/List';
import Experience from '../pages/Experience/List';
import Whos from '../pages/Whos/List';
import Month from '../pages/Month/List';
import Search from '../pages/search';
import Sitemap from "../pages/sitemap";
import HotelsDetails from '../InnerScreen/DetailPageOne'
import Dashboard from "../pages/Dashboard";
import * as Utils from '../Utils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Profile from "../pages/auth/Profile";
import GenerateSitemap from "../pages/generateSitemap";
import Route404 from "../pages/auth/Route404";
import PrivacyPolicy from "../pages/about/PrivacyPolicy";
import Disclaimer from "../pages/about/Disclaimer";
import TermsandConditions from "../pages/about/TermsandConditions";
import FilterResult from "../pages/filterResult";
// import VCard from "../pages/vCard";

export default function Router() {

  const [headerData, setHeaderData] = useState(null);
  const [popularData, setPopularData] = useState([]);
  const [filters, setFilters] = useState({ dest: [], exp: [], who: [], month: [] });
  const [isVcard, setIsVcard] = useState(false);


  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={Utils?.googleClientId}>
          {!isVcard &&
            <Header setHeaderData={setHeaderData} setPopularData={setPopularData} />
          }
          <Routes>
            <Route path="/" element={<Home headerData={headerData} popularData={popularData} />} />
            {/* <Route path="/generate-sitemap" element={<GenerateSitemap />}/> */}
            <Route path="/continent/:name" element={<Destination headerData={headerData} />} />
            <Route path="/continent/:id/:name" element={<PropertyList headerData={headerData} />} />
            <Route path="/country/:id/:name" element={<DestinationList headerData={headerData} />} />
            <Route path="/month/:name" element={<Month headerData={headerData} />} />
            <Route path="/month/:id/:name" element={<DestinationList headerData={headerData} />} />
            <Route path="/experience/:name" element={<Experience headerData={headerData} />} />
            <Route path="/experience/:id/:name" element={<DestinationList headerData={headerData} />} />
            <Route path="/inspiration/:id" element={<DestinationList headerData={headerData} />} />
            <Route path="/who/:name" element={<Whos headerData={headerData} />} />
            <Route path="/who/:id/:name" element={<DestinationList headerData={headerData} />} />
            <Route path="/destination/:id/:name" element={<DestinationDetailPage headerData={headerData} />} />
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/review/:id" element={<Review />} />
            <Route path="/search" element={<Search headerData={headerData} />} />
            <Route path="/sitemap" element={Sitemap} />
            <Route path="/hotels/:id/:name" element={<HotelsDetails />} />
            <Route path="/blog" element={<Blog headerData={headerData} popularData={popularData} />} />
            <Route path="/blog-list" element={<BlogList headerData={headerData} popularData={popularData} />} />
            <Route path="/blog-list/:id/:name" element={<BlogList headerData={headerData} popularData={popularData} />} />
            <Route path="/blog/:id/:name" element={<BlogDetail headerData={headerData} popularData={popularData} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/terms-and-conditions" element={<TermsandConditions />} />
            <Route path="/filter/:name" element={<FilterResult />} />
            {/* <Route path="/vcard/shree-associates" element={<VCard setIsVcard={setIsVcard} />} /> */}

            <Route path="*" element={<Route404 />} />
          </Routes>
          {!isVcard &&
           <>
            <Footer headerData={headerData} filters={filters} setFilters={setFilters} />
           
           </>
          }
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  );
}