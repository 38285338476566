import React from 'react'
import './style.css'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { get, post } from '../../helper/helper_api';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import { Helmet } from 'react-helmet';

export default function BlogDetails(props) {
	const params = useParams();
	const location = useLocation();
	const user = useSelector((state) => state.user.user);
	let navigate = useNavigate();
	const [propertydetail, setPropertydetail] = useState(null);
	const [propertyId, setPropertyId] = useState();
	const [homedata, setHomedata] = useState(null);
	const [catList, setCatList] = useState(null);
	const [nextBlog, setNextBlog] = useState(undefined);
	const [prevBlog, setPrevBlog] = useState(undefined);
	const [similer, setSimiler] = useState([]);
	const [desc, setDesc] = useState("");
	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [loading, setLoading] = useState(true);
	const [loadingMoreStories, setLoadingMoreStories] = useState(false);
	const [scrollLoadHeight, setScrollLoadHeight] = useState(undefined);

	useEffect(() => {
		window.scrollTo(0, 0);
		let path = location.pathname.split("/");
		// console.log('blog detail page',params?.id);
		// if (path.length > 1)
		setPropertyId(params?.id);
		setHomedata(props?.headerData)
	}, [params])

	useEffect(() => {
		if (propertyId)
			getPropertyDetail();
	}, [propertyId]);


	useEffect(() => {
		getBlogs(0);
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, propertydetail, data, scrollLoadHeight]);

	const getPropertyDetail = () => {
		// setLoader(true);
		get('/blog/detail?blogId=' + propertyId).then(async (json) => {
			// console.log('bank detail',json)
			console.log('propertyId detail', json?.data);
			setLoading(false);
			if (json?.statusCode == 200) {
				setPropertydetail(json?.data);
				setNextBlog(json?.next);
				setPrevBlog(json?.prev);
				setSimiler(json?.similer);
			}
			//   setLoader(false);
		}).catch((err) => {
			console.log(err);
			setLoading(false);
			//   setLoader(false);
		})
		get('/category/list').then(async (json) => {
			// console.log('bank detail',json)
			console.log('propertyId detail', json?.data);
			if (json?.statusCode == 200) {
				setCatList(json?.data);

			}
			//   setLoader(false);
		}).catch((err) => {
			console.log(err)
			//   setLoader(false);
		})
	}

	const getBlogs = (cp) => {
		setLoadingMoreStories(true);
		if (!loadingMoreStories) {
			post("/blog/related", { currentPage: cp })
				.then(res => {
					setLoadingMoreStories(false);
					if (res?.statusCode == 200) {
						let temp = cp == 0 ? [] : Object.assign([], data);
						console.log('data to set', [...temp, ...res?.data]);
						setData([...temp, ...res?.data]);
						setTotalPage(res?.totalPage);
						setCurrentPage(res?.currentPage + 1);
					} else {
						toast.error("We are having a problem to load the blog, Please Try again later!")
					}
				})
				.catch(err => {
					setLoadingMoreStories(false);
					console.log('error while getting blogs', err);
					toast.error("We are having a problem to load the blog, Please Try again later!")
				});
		}
	}

	const openBlog = (item) => {
		return "/blog/" + compressId(item?._id) + "/" + parseName(item?.titleShort);
	}

	const openCat = (item) => {
		if (item?._id)
			return '/blog-list/' + compressId(item?._id) + "/top-blogs-for-" + parseName(item?.title)
	}

	const openExp = (item) => {
		return '/experience/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name);
	}

	const openWho = (item) => {
		return '/who/' + compressId(item?._id) + "/" + "top-travel-destinations-for-" + parseName(item?.name)
	}

	const openCountry = (item) => {
		return `/country/${compressId(item?.countryId)}/top-travel-destinations-in-${parseName(item?.continent[0]?.name)}-${item?.country[0]?.name}`;
	}

	const openProp = (item) => {
		return "/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" +
			parseName(item?.country[0]?.name) + "-" +
			parseName(item?.continent[0]?.name);
	}

	const compressId = (id) => {
		const temp = id.slice(id.length - 4, id.length);
		// console.log('compressing id', temp);
		return temp;
	}

	const parseName = (str) => {
		if (str) {
			let temp = str.split(",").join("");
			return temp.split(" ").join("-").toLowerCase();
		} else
			return "";
	}

	const handleSubmitComment = (e, v) => {
		console.log('commenting', v);
		if (user) {
			let body = {
				...v,
				blogId: propertydetail?._id,
				token: user?.token,
			}
			console.log('sending reviews', body)
			post('/review/add', body)
				.then((res => {
					console.log('response from adding review', res);
					if (res?.statusCode == 200) {
						toast.success(res?.message);
						getPropertyDetail();
						setDesc("");
					}
					else if (res?.statusCode == 208) {
						toast.error(res?.error);
					}
					else
						toast.error(res?.error);
				}))
				.catch(err => {
					console.log('error whiling adding rating', err);
					toast.error('Something went wrong!');
				})
		} else
			navigate("/login");
	}

	const handleValidInquiry = (e, v) => {
		const body = {
			...v
		}
		post('/query/add', body)
			.then((res => {
				console.log('response from subscribe');
				if (res?.statusCode == 200)
					toast.success(res?.message);
				else
					toast.error(res?.error);
			}))
			.catch(error => {
				console.log('error while subscribing', error);
				toast.error('Something went wrong');
			})
	}

	const handleScroll = () => {
		let divHeight = document.getElementById("scrollableView")?.scrollHeight;
		let divDescHeight = document.getElementById("desc")?.scrollHeight;
		if (!scrollLoadHeight)
			setScrollLoadHeight(divDescHeight);
		console.log('scrolling', window.scrollY, divHeight, divDescHeight, scrollLoadHeight);
		if (window?.scrollY < (scrollLoadHeight - 652))
			if ((window?.scrollY - divHeight) > 544)
				if (totalPage > currentPage)
					getBlogs(currentPage);
	}

	return (
		<>
			{
				loading && <Loader />
			}
			<Helmet>
				<title>{propertydetail?.metaTitle}</title>
				<meta name="keyword" content={propertydetail?.metaKeyword} />
				<meta name="description" content={propertydetail?.metaDesc} />
				<meta name="dc.language" content="English" />
				<meta name="dc.source" content={`https://woowdestinations.com${location?.pathname}`} />
				<meta name="dc.title" content={propertydetail?.metaTitle} />
				<meta name="dc.keywords" content={propertydetail?.metaKeyword} />
				<meta name="dc.description" content={propertydetail?.metaDesc} />
				<meta name="Author" content={propertydetail?.author[0]?.title} />
				<meta name="robots" content="index, follow" />
				<meta property="og:locale" content="en-in" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Woow Destinations" />
				<meta name="og:title" content={propertydetail?.metaTitle} />
				<meta name="og:description" content={propertydetail?.metaDesc} />
				<meta name="og:image" content={propertydetail?.banner} />
				<meta property="og:image:width" content="400" />
				<meta property="og:image:height" content="400" />
				<meta property="og:image:type" content="image/png" />
				<meta name="theme-color" content="#ffffff" />

				<meta name="twitter:title" content={propertydetail?.metaTitle} />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:site" content="@Woow_Destinations" />
				<meta name="twitter:description" content={propertydetail?.metaDesc} />
				<meta name="twitter:image" content={propertydetail?.banner} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="canonical" href={`https://woowdestinations.com${location?.pathname}`} />
				<script type="application/ld+json">
					{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Article",
						"mainEntityOfPage": {
							"@type": "WebPage",
							"@id": "https://woowdestinations.com${location?.pathname}"
						  },
                        "headline": "${propertydetail?.metaTitle}",
						"description":"${propertydetail?.metaDesc}",
						"image": "${propertydetail?.banner}",
						"author": {
							"@type": "Person",
							"name": "${propertydetail?.author[0]?.title}"
						  },  
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "${5}",
                            "bestRating": "5",
                            "ratingCount": "${propertydetail?.reviews?.length}"
                        },
						"publisher": {
							"@type": "Organization",
							"name": "Woow Destinations",
							"logo": {
							  "@type": "ImageObject",
							  "url": "https://woowdestinations.com/facicon.png"
							}
						  },
						  "datePublished": "${propertydetail?.createdAt}",
						  "dateModified": "${propertydetail?.updatedAt}"
                    }
                `}
				</script>
			</Helmet>
			<section className="blog-section blog_section_main blog_section_main_detail pb-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-8" id="desc">
							<div className="single-post">
								<div className="single-post-content">
									<div className="post-content">
										<div className="post-social">
											<span>Share</span>
											<ul className="share-post">
												<li><a href="#" className="facebook"><i className="fab fa-facebook"></i></a></li>
												<li><a href="#" className="twitter"><i className="fab fa-twitter"></i></a></li>
												<li><a href="#" className="pinterest"><i className="fab fa-pinterest"></i></a></li>
											</ul>
										</div>
										<div className="post-content-text">
											<a className="text-link" >
												<span style={{ color: '#e74c3c' }}>By</span>  {propertydetail?.author[0]?.title}
											</a>
											<h1 style={{ textTransform: 'capitalize' }}>{propertydetail?.titleShort}</h1>
											<ul className="post-tags">
												<li>Last Updated : {propertydetail?.updatedAt}</li>
												<li>{propertydetail?.reviews?.length} comments</li>
											</ul>
											<img src={propertydetail?.banner} alt={propertydetail?.titleShort} style={{ marginBottom: '10px' }} />
											<h2 style={{ textTransform: 'capitalize' }}>
												{propertydetail?.title}
											</h2>
											<div className='description_section' dangerouslySetInnerHTML={{ __html: propertydetail?.desc }}></div>
											<div className="share-tags-box mb-4">
												<ul className="tags">
													{
														propertydetail?.category?.map((item, index) => {
															return (
																<li key={index}><Link target='_blank' to={openCat(item)}>{item?.title}</Link></li>
															)
														})
													}
												</ul>
												<ul className="shares-likes">
													<li><a href="#" className="likes">Like <i className="fab fa-heart-o"></i></a></li>
													<li><a href="#" className="facebook"><i className="fab fa-facebook"></i></a></li>
													<li><a href="#" className="twitter"><i className="fab fa-twitter"></i></a></li>
													<li><a href="#" className="pinterest"><i className="fab fa-pinterest"></i></a></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="prev-next-box">
										<div className="prev-box">
											{prevBlog?.length > 0 &&
												<div className='pagination_box'>
													<div className='circle_img'>
														<img src={prevBlog[0]?.image} alt={prevBlog[0]?.titleShort} />
													</div>
													<div className='left_nbtn_box'>
														<Link target='_blank' className="text-link" to={openBlog(prevBlog[0])}><i className="fa fa-angle-left"></i> Previous Post</Link>
														<h2><Link to={openBlog(prevBlog[0])}>{prevBlog[0]?.titleShort}</Link></h2>
													</div>

												</div>
											}
										</div>
										<div className="next-box">
											{nextBlog?.length > 0 &&
												<div className='pagination_box_p'>

													<div className='left_nbtn_box'>
														<Link target='_blank' className="text-link next-link" to={openBlog(nextBlog[0])}>Next Post <i className="fa fa-angle-right"></i></Link>
														<h2><Link target='_blank' to={openBlog(nextBlog[0])}>{nextBlog[0]?.titleShort}</Link></h2>
													</div>
													<div className='circle_img'>
														<img src={nextBlog[0]?.image} alt={nextBlog[0]?.titleShort} />
													</div>
												</div>
											}
										</div>
									</div>
								</div>
								{/* <div className="advertise-box">
								<a href="#"><img src="http://nunforest.com/mite-demo/upload/banners/ban3.jpg" alt="" /></a>
							</div> */}
							</div>
						</div>
						<div className="col-lg-4" id="right">
							<div className="sidebar">
								<div className="widget social-widget">
									<h2>Social</h2>
									<ul className="social-list">
										<li>
											<a href="https://www.facebook.com/woowdestinations" target='_blank'>
												<i className="fab fa-facebook"></i>
												facebook
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
												twitter
											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/woowdestinationsofficial/" target='_blank'>
												<i className="fab fa-instagram"></i>
												instagram
											</a>
										</li>
									</ul>
								</div>
								<div className=''>
									<div className="widget category-widget">
										<h2>Categories</h2>
										<ul className="category-list list_posts_tag">
											{
												catList?.map((item, index) => {
													return (
														<Link target='_blank' key={index} to={openCat(item)}>
															{item?.title}
														</Link>
													)
												})
											}
										</ul>
									</div>
									<div className="widget list-widget">
										<h2>Popular mentions</h2>
										<ul className="list-posts list_posts_tag">
											{
												homedata?.experiences?.map((item, index) => {
													return (
														<Link target='_blank' className="text-link" key={index} to={openExp(item)}>{item?.name}</Link>
													)
												})
											}
											{
												homedata?.whos?.map((item, index) => {
													return (
														<Link target='_blank' className="text-link" key={index} to={openWho(item)}>{item?.name}</Link>
													)
												})
											}
										</ul>
									</div>
								</div>
								<div className="widget subscribe-widget2">
									<h2>Join Our Newsletter</h2>
									<p>Sign up for our free newsletters to receive the latest news. Don't worry we won't do spam.</p>
									<AvForm onValidSubmit={handleValidInquiry} className="subscibe-form">
										<AvField
											type="email"
											name="email"
											// className="rlr-subscribe__input rlr_subscribe__input_new"
											placeholder="Enter your email"
											required
										/>
										<button type='submit' id="submit" className="btn" >Subscribe</button>
									</AvForm>
								</div>
								{propertydetail?.properties?.length > 0 &&
									<section className="fresh-section2 fresh_section2_new p-0">
										<div className="container-fluid" style={{ paddingLeft: '0', paddingRight: '0' }}>
											<div className="title-section text-center">
												<h2>Destinations</h2>
											</div>
											<div className="fresh-box">
												<div className="row">
													<div className="col-lg-12 col-md-12">
														<ul className="list-thumb-posts">
															{propertydetail?.properties?.map((item) => (
																<li style={{ width: '100%' }}>
																	<div className="image-holder"><img src={item?.banner} style={{ width: 70, height: 70, objectFit: 'cover' }} alt={item?.name} /></div>
																	<div className="list-post-content">
																		<Link target='_blank' className="text-link" to={openCountry(item)}>{item?.country[0]?.name} | {item?.continent[0]?.name}</Link>
																		<h2><Link target='_blank' to={openProp(item)}>{item?.name}</Link></h2>
																		<ul className="post-tags">
																			<li>{item?.nights}</li>
																			<li>{item?.avgRating ? Number(item?.avgRating).toFixed(1) : 0} Ratings</li>
																		</ul>
																	</div>
																</li>
															))}
														</ul>
														{/* <div className='load_mod'>
														<a className="button-one" href="#">Load More</a>
													</div> */}
													</div>
												</div>
											</div>
										</div>
									</section>
								}
								<div className="widget list-widget mt-3" id="scrollableView">
									<h2>Latest Stories</h2>
									<ul className="list-posts">
										{
											data?.map((item, index) => {
												return (
													<li key={index} className='list_'>
														<div>{item?.category?.map((item1, index1) => {
															return (
																<Link target='_blank' className="text-link text_link_" key={index1} to={openCat(item1)}>{item1?.title}</Link>
															)
														})}</div>
														<h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
														<ul className="post-tags">
															<li>{item?.updatedAt}</li>
															<li>{item?.reviews?.length} comments</li>
														</ul>
													</li>
												)
											})
										}
									</ul>
									{currentPage < (totalPage / 12).toFixed(0) && loadingMoreStories &&
										<div className="center-button">
											<a className="button-one" >Loading...</a>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className='row'>
						<div className="single-post">
							<div className="single-post-content">
								{similer?.length > 0 &&
									<div className="related-box">
										<h2>Related Posts</h2>
										<div className="row">
											{similer?.map((item) => (
												<div className="col-lg-4 col-md-4">
													<div className="news-post standard-post text-left">
														<div className="image-holder">
															<Link target='_blank' to={openBlog(item)}><img src={item?.image} alt={item?.titleShort} /></Link>
														</div>
														{/* <Link className="text-link" to={openCat(item?.category[0])}>{item?.category[0]?.title}</Link> */}
														<h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
														<ul className="post-tags">
															<li>by {item?.author[0]?.title}</li>
															<li>{item?.updatedAt}</li>
														</ul>
													</div>
												</div>
											))}
										</div>
									</div>
								}
								{propertydetail?.reviews?.length > 0 &&
									<div className="comments">
										<h2 className="comments-title">
											{propertydetail?.reviews?.length} Comments
										</h2>
										<ul className="comments__list">
											{propertydetail?.reviews?.map((item) => (
												<li className="comments__list-item">
													{item?.user?.length > 0 ?
														<img className="comments__list-item-image" src={item?.user[0]?.profile_picture ? item?.user[0]?.profile_picture : "http://nunforest.com/mite-demo/upload/single/th1.jpg"} alt={item?.user[0]?.name} />
														:
														<img className="comments__list-item-image" src={item?.avatar ? item?.avatar : "http://nunforest.com/mite-demo/upload/single/th1.jpg"} alt="" />
													}
													<div className="comments__list-item-content">
														{item?.user?.length > 0 ?
															<h3 className="comments__list-item-title">
																{item?.user[0]?.name}
															</h3>
															:
															<h3 className="comments__list-item-title">
																{item?.userName}
															</h3>
														}
														<span className="comments__list-item-date">
															Posted {item?.createdAt}
														</span>
														{/* <a className="comments__list-item-reply" href="#">
														<i className="la la-mail-forward"></i>
														Reply
													</a> */}
														<p className="comments__list-item-description">
															{item?.desc}
														</p>
													</div>
												</li>
											))}
										</ul>
									</div>
								}
								{propertydetail?.reviews?.findIndex(element => element?.userId == user?._id) == -1 &&
									<AvForm onValidSubmit={handleSubmitComment} className="contact-form" id="comment-form">
										<h2 className="contact-form__title">
											Write a Comment
										</h2>
										{/* <div className="row">
									<div className="col-md-6">
										<input className="contact-form__input-text" type="text" name="name" id="name" placeholder="Name:" />
									</div>
									<div className="col-md-6">
										<input className="contact-form__input-text" type="text" name="mail" id="mail" placeholder="Email:" />
									</div>
								</div> */}
										<AvField
											className="contact-form__textarea"
											name="desc"
											id="comment"
											placeholder="Comment"
											required
											type="textarea"
											value={desc}
											onChange={(e => setDesc(e.target.value))}
											rows={4}
										/>
										<button className="contact-form__submit"
											type="submit"
											name="submit-contact"
											id="submit_contact"
											value="Send Comment">Submit</button>
									</AvForm>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="container detail_page_blog_cat">
					<div className="title-section text-center">

						<div className="title-section text-center">
							{/* <h1></h1> */}
							<div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
								{/* <!-- Section heading --> */}
								<div className="rlr-section__title">
									<h2 className="rlr-section__title--main ">Blog by category</h2>

								</div>

							</div>
						</div>
					</div>
					<div className="widget category-widget">

						<ul className="category-list list_posts_tag list_posts_tag_d">
							{
								catList?.map((item, index) => {
									return (
										<Link target='_blank' to={openCat(item)}>
											{item?.title}
										</Link>
									)
								})
							}

						</ul>
					</div>
				</div>
			</section>
		</>
	)
}
