import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
// import Header from '../Header';
// import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import { json, useLocation, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, put, post } from '../helper/helper_api';
import Loader from '../Loader';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, FreeMode, Thumbs } from "swiper";
import HTMLRenderer from 'react-html-renderer';
import { Row, Col } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import Share from '../component/ShareModal';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import HotelWidget from '../component/HotelWidget';
import MapContainer from '../component/MapContainer';
import * as Utils from "../Utils";

export default function DetailPage() {
  const swiperRef = useRef();
  const swiperRef1 = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState();
  const [loader, setLoader] = useState(true);
  const [propertydetail, setPropertydetail] = useState(null);
  const user = useSelector((state) => state.user.user);
  const [ads, setAds] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [shareLink, setShareLink] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [liteBox, setLiteBox] = useState(false);
  const [currentLiteImg, setCurrentLiteImg] = useState(0);
  const [currentViews, setCurrentViews] = useState(5);
  const autoCompleteRef = useRef(null);
  const [placeName, setPlaceName] = useState("");

  // console.log('user from redux', user);

  useEffect(() => {
    window.scrollTo(0, 0);
    let path = location.pathname.split("/");
    if (path.length > 1)
      setPropertyId(path[2]);
  }, [location?.pathname])

  useEffect(() => {
    if (propertyId)
      getPropertyDetail();
  }, [propertyId]);

  useEffect(() => {
    // console.log('property details page', swiperRef.current);
    swiperRef.current.slideTo(currentBanner);
  }, [currentBanner]);

  useEffect(() => {
    if (propertydetail) {
      getGooglePlaceDetail();
    }
  }, [propertydetail]);

  useEffect(() => {
    // console.log('places', autoCompleteRef.current);
  }, [placeName]);

  const getPropertyDetail = () => {
    setLoader(true);
    get("/ads/list?screen=detail")
      .then(res => {
        // console.log('response from ads list', res)
        if (res?.statusCode == 200)
          setAds(res?.data);
      }).catch(err => {
        console.log('error while getting ads list', err);
      })
    get('/property/detail?propertyId=' + propertyId).then(async (json) => {
      // console.log('bank detail',json)
      // console.log('propertyId detail', json?.data);
      if (json?.statusCode == 200) {
        json?.data?.banners.push(json?.data?.banner);
        setPropertydetail(json?.data);
        getHotelWidget();
      }
      setLoader(false);
    }).catch((err) => {
      console.log(err)
      setLoader(false);
    })
  }

  let map;
  let center;

  const getGooglePlaceDetail = async () => {
    // console.log("propertydetail", propertydetail);
    // let lat = Number(propertydetail?.lat);
    // let long = Number(propertydetail?.long);
    // let map = new window.google.maps.Map(document.getElementById("map"), {
    //   center: { lat: lat, lng: long }
    // });

    // const { LatLng, LatLngBounds } = await google.maps.importLibrary("core");
    // center = new LatLng(lat, long);
    // console.log('Lat long bound', LatLng, LatLngBounds);
    // map = new Map(document.getElementById("map"), {
    //   center: { lat: lat, lng: long },
    //   zoom: 11,
    // });
    // findPlaces(LatLng, LatLngBounds);


    // setPlaceName(propertydetail?.name);
    // console.log('chaning place name', placeName);
    // fetch(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${long}&radius=500&key=${Utils.googleMapApiKey}`,
    // {
    //   headers:{
    //     'Content-Type':"application/json",
    //     "Accept":"application/json",
    //     "Access-Control-Allow-Origin":"no-cors"
    //   }
    // })
    // .then(res=>res?.json())
    // .then(res=>{
    //   console.log('response from get place', res);
    // })
    // let service = new google.maps.places.PlacesService(map);
    // // console.log('service', service);
    // service.getDetails({
    //   placeId: 'ChIJAUKRDWz2wokRxngAavG2TD8'
    // }, function (place, status) {
    //   if (status === google.maps.places.PlacesServiceStatus.OK) {
    //     // console.log('reviews from google', place.reviews);
    //     // Intended behavior is to set this.setState({places.place.reviews})
    //   }
    // })
  }

  // async function findPlaces(LatLng, LatLngBounds) {
  //   const { Place } = await google.maps.importLibrary("places");
  //   //@ts-ignore
  //   const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  //   const request = {
  //     query: "Tacos in Mountain View",
  //     fields: [
  //       "displayName",
  //       "location",
  //       "businessStatus",
  //       "hasWheelchairAccessibleEntrance",
  //     ],
  //     includedType: "restaurant",
  //     isOpenNow: true,
  //     language: "en-US",
  //     maxResultCount: 7,
  //     minRating: 3.2,
  //     region: "us",
  //     useStrictTypeFiltering: false,
  //   };
  //   //@ts-ignore
  //   const { places } = await Place.findPlaceFromQuery(request);
  //   const bound = new LatLngBounds();

  //   if (places.length) {
  //     console.log(places);
  //     // Loop through and get all the results.
  //     places.forEach((place) => {
  //       const markerView = new AdvancedMarkerElement({
  //         map,
  //         position: place.location,
  //         title: place.displayName,
  //       });

  //       bound.extend(new LatLng(place.location));
  //       console.log(place);
  //     });
  //     map.setCenter(bound.getCenter());
  //   } else {
  //     console.log("No results");
  //   }
  // }

  const handleLike = (id, type) => {
    if (user) {
      const body = {
        reviewId: id,
        type,
        token: user?.token
      }
      put('/review/like', body)
        .then((res => {
          if (res?.statusCode == 200)
            getPropertyDetail();
          else
            toast.error(res?.error);
        }))
        .catch(err => {
          console.log('error while liking', err);
          toast.error('Something went wrong');
        })
    } else {
      navigate("/login");
    }
  }

  const checkMyLike = (arr) => {
    if (arr && arr.findIndex(x => x == user?._id) != -1)
      return true;
    else
      return false;
  }

  const handleProLike = () => {
    if (user) {
      const body = {
        token: user?.token,
        propertyId: propertydetail?._id
      }
      post("/property/like", body)
        .then((res) => {
          // console.log('response from like property', res);
          if (res?.statusCode == 200) {
            // toast.success(res?.message);
            getPropertyDetail();
          } else
            toast.error(res?.error);
        })
        .catch(error => {
          console.log('error while liking property', error);
          toast.error('Something went wrong');
        })
    } else {
      navigate("/login");
    }
  }

  const copy = () => {
    // const el = document.createElement('input');
    // el.value = location.pathname;
    // el.id = "url-input";
    // document.body.appendChild(el);
    // el.select();
    // console.log(location.pathname);
    document.execCommand("copy", undefined, location.pathname);
    // el.remove();
  }

  const compressId = (id) => {
    if (id) {
      const temp = id.slice(id.length - 4, id.length);
      // console.log('compressing id', temp);
      return temp;
    }
    else
      return "";
  }

  const parseName = (str) => {
    if (str) {
      let temp = str.split(",").join("");
      return temp.split(" ").join("-").toLowerCase();
    } else
      return "";
  }

  const openProperty = (item) => {
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
    window.open("/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" + parseName(item?.country[0]?.name) + "-" + parseName(item?.country[0]?.continent[0]?.name), "_blank");
  }

  const openConti = (item) => {
    const tempId = item?._id.slice(item?._id.length - 4, item?._id.length);
    window.open('/hotels/' + tempId + "/" + "top-travel-destinations-hotels-in-" + parseName(item?.name), "_blank");
  }
  // console.log('current light', light.current);

  const getHotelWidget = () => {
    if (propertydetail) {
      return (
        <HotelWidget property={propertydetail} />
      )
    }
  }

  return (
    <>
      {
        loader && <Loader />
      }
      {propertydetail &&
        <Helmet>
          {/* <script async>
            window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'G-LBDLMH99NJ');
          </script> */}
          <title> {propertydetail?.name}, {propertydetail?.location} | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, ${propertydetail?.name}, top destination in ${propertydetail?.location}, top destination in ${propertydetail?.continent[0]?.name}, top place in ${propertydetail?.country[0]?.name}, top place to visit${propertydetail?.experience?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={propertydetail?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${propertydetail?.name}, ${propertydetail?.location} | Woow Destinations`} />
          <meta name="twitter:description" content={propertydetail?.overview} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={propertydetail?.banner} />
          <meta property="og:image:alt" content={propertydetail?.overview} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${propertydetail?.name}, ${propertydetail?.location} | Woow Destinations`} />
          <meta property="og:url" content={"http://woowdestinations.com/" + location?.pathname} />
          <meta property="og:description" content={propertydetail?.overview} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com/" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, ${propertydetail?.name}, top destination in ${propertydetail?.location}, top destination in ${propertydetail?.continent[0]?.name}, top place in ${propertydetail?.country[0]?.name}, top place to visit${propertydetail?.experience?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={propertydetail?.overview} />
          <meta name="geo.region" content="IN-GN" />
          <meta name="geo.placename" content={propertydetail?.location} />
          <meta name="geo.position" content={`${propertydetail?.lat};${propertydetail?.long}`} />
          <meta name="ICBM" content={`${propertydetail?.lat};${propertydetail?.long}`} />
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="http://woowdestinations.com/" />


        </Helmet>
      }
      <Share
        show={isShare}
        data={propertydetail}
        type={"Destination"}
        setShow={setIsShare}
        pathname={location?.pathname}
      />
      {/* <div id="map"></div> */}
      {/* <input id="pac-input"></input> */}
      <main id="rlr-main" className="rlr-main--fixed-top rlr_main_fixed_top_common">
        {/* <!-- Main Content --> */}
        <div className="container">

          {/* <!-- Media Slider --> */}
          <aside className="row">
            {/* <!-- Media main image carousel --> */}
            <div className="col-md-10 rlr-media">
              <div className=" rlr-media--wrapper rlr-js-media">
                {/* <!-- Arrows --> */}
                <div className="splide__arrows">
                  <button onClick={() => swiperRef.current?.slidePrev()} className="rlr-media__arrow splide__arrow splide__arrow--prev">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.889 14.942 8.47 8.36 1.889 1.778" stroke="var(--white)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </button>
                  <button onClick={() => swiperRef.current?.slideNext()} className="rlr-media__arrow splide__arrow splide__arrow--next">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.889 14.942 8.47 8.36 1.889 1.778" stroke="var(--white)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </button>
                </div>
                {/* <!-- Media main images --> */}
                <div className="splide__track rlr-media__strack">
                  {/* <ul id="" className="splide__list">
                    <li className="splide__slide rlr-media__image-view">
                      <img className="lazyload" data-src="./assets/images/product-images/large/03.jpg" alt="media image" />
                    </li>
                    <li className="splide__slide rlr-media__image-view">
                      <img className="lazyload" data-src="./assets/images/product-images/large/01.jpg" alt="media image" />
                    </li>
                    <li className="splide__slide rlr-media__image-view">
                      <img className="lazyload" data-src="./assets/images/product-images/large/02.jpg" alt="media image" />
                    </li>
                    <li className="splide__slide rlr-media__image-view">
                      <img className="lazyload" data-src="./assets/images/product-images/large/03.jpg" alt="media image" />
                    </li>
                    <li className="splide__slide rlr-media__image-view">
                      <img className="lazyload" data-src="./assets/images/product-images/large/01.jpg" alt="media image" />
                    </li>
                  </ul> */}
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    // loop={true}
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false,
                    // }}
                    pagination={{
                      el: '.custom-pagination',
                      type: "fraction",
                      // clickable: true
                    }}
                    onBeforeInit={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                    modules={[FreeMode, Pagination, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    {/* <SwiperSlide>
                      <img itemprop="image" className='product_card__image_wrapper_details active' src={propertydetail?.banner} alt="product-image" />
                    </SwiperSlide> */}
                    {propertydetail?.banners?.map((img) => (
                      <SwiperSlide>
                        <img itemprop="image" loading="lazy" className='product_card__image_wrapper_details active' src={img} alt={img?.name} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                {/* <!-- Media pagination counter --> */}
                <div className="rlr-media__custom-pagination rlr-js-custom-pagination">
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.2 0C.542 0 0 .558 0 1.235v11.53C0 13.442.542 14 1.2 14h15.6c.658 0 1.2-.558 1.2-1.235V1.235C18 .558 17.458 0 16.8 0H1.2zm0 .824h15.6c.228 0 .4.176.4.411v9.844l-3.506-3.95a.4.4 0 0 0-.588 0l-2.862 3.126L6.1 5.488a.4.4 0 0 0-.362-.135.4.4 0 0 0-.232.129L.8 10.687V1.235C.8 1 .972.823 1.2.823zm9.2 2.058c-.879 0-1.6.743-1.6 1.647 0 .905.721 1.647 1.6 1.647.879 0 1.6-.742 1.6-1.647 0-.904-.721-1.647-1.6-1.647zm0 .824c.447 0 .8.363.8.823 0 .46-.353.824-.8.824a.806.806 0 0 1-.8-.824c0-.46.353-.823.8-.823zm-4.606 2.67 5.912 6.8H1.2a.397.397 0 0 1-.4-.411v-.869l4.994-5.52zm7.6 1.64 3.806 4.285v.464a.397.397 0 0 1-.4.411h-4.019l-2-2.303 2.613-2.856z"
                      fill="#212529"
                    ></path>
                  </svg>
                  <span className="rlr-media__page-counter custom-pagination rlr-js-page"> 0 </span>
                </div>
              </div>
            </div>
            {/* <!-- Media Thumbnails --> */}
            <div className="col-md-2 rlr-media">
              {/* <!-- Media sidebar --> */}
              <div className=" rlr-media--wrapper rlr-media--sidebar rlr-js-thumbnail-media">

                {/* <div className="splide__arrows">
                  <button onClick={() => swiperRef1.current?.slideNext()} className="rlr-media__arrow splide__arrow splide__arrow--prev">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.889 14.942 8.47 8.36 1.889 1.778" stroke="var(--white)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </button>
                  <button onClick={() => swiperRef1.current?.slideNext()} className="rlr-media__arrow splide__arrow splide__arrow--next">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.889 14.942 8.47 8.36 1.889 1.778" stroke="var(--white)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </button>
                </div> */}
                {/* <!-- Thumbnails --> */}
                <div className="splide__track right_slider_banner rlr-media__strack">
                  <Swiper
                    direction={"vertical"}
                    spaceBetween={10}
                    slidesPerView={4}
                    // mousewheel={true}
                    grabCursor={true}
                    // centeredSlides={true}
                    // loop={true}
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false,
                    // }}
                    // onBeforeInit={(swiper) => {
                    //   swiperRef1.current = swiper;
                    // }}
                    // breakpoints={{
                    //   640: {
                    //     slidesPerView: 2,
                    //     spaceBetween: 20,
                    //   },
                    //   768: {
                    //     slidesPerView: 2,
                    //     spaceBetween: 40,
                    //   },
                    //   1024: {
                    //     slidesPerView: 2,
                    //     spaceBetween: 50,
                    //   },
                    // }}

                    modules={[FreeMode, Thumbs, Navigation]}
                    className="mySwiper"
                  >
                    {/* <SwiperSlide onClick={()=>setCurrentBanner(1)}>
                      <img itemprop="image" className=' active' src={propertydetail?.banner} alt="product-image" />
                    </SwiperSlide> */}
                    {propertydetail?.banners?.map((img, index) => (
                      <SwiperSlide onClick={() => setCurrentBanner(index)}>
                        <img itemprop="image" className=' active' src={img} alt={img?.name} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </aside>
          {/* <!-- Product Detail Sextion --> */}
          <section className="row rlr-product-detail-section mt-2">
            <div className="rlr-product-detail-section__details col-xl-8">
              {/* <!-- Product Detail Header --> */}
              <div className="rlr-product-detail-header" id="rlr-js-detail-header">
                <div className="rlr-product-detail-header__contents">

                  {propertydetail &&
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb rlr-breadcrumb__items">
                        <li className="breadcrumb-item rlr-breadcrumb__item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item rlr-breadcrumb__item"><Link to={"/continent/top-travel-destinations-by-continents"}>Continent</Link></li>
                        <li className="breadcrumb-item rlr-breadcrumb__item"><Link to={`/continent/${compressId(propertydetail?.continent[0]?._id)}/top-travel-destinations-in-${parseName(propertydetail?.continent[0]?.name)}`}>{propertydetail?.continent[0]?.name}</Link></li>
                        <li className="breadcrumb-item rlr-breadcrumb__item"><Link to={`/country/${compressId(propertydetail?.country[0]?._id)}/top-travel-destinations-in-${parseName(propertydetail?.continent[0]?.name)}-${parseName(propertydetail?.country[0]?.name)}`}>{propertydetail?.country[0]?.name}</Link></li>
                        <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">{propertydetail?.name}</li>
                      </ol>
                    </nav>
                  }
                  <h1 className="rlr-section__heading--main rlr-product-detail-header__title mb-1 mt-1">{propertydetail?.name}</h1>
                  <div className="rlr-review-stars" itemscope >
                    <div className="rlr-review-stars" itemprop="ratingValue" itemscope >
                      <StarRatings
                        rating={propertydetail?.avgRating ? propertydetail?.avgRating : 0}
                        starRatedColor={'#f78f08'}
                        starHoverColor={'#f78f08'}
                        numberOfStars={5}
                        isSelectable={false}
                        name='rating'
                        starDimension={'18px'}
                        starSpacing={'2px'}
                      />
                    </div>
                    <div className="rlr-review-stars__content">
                      <span className="rlr-review-stars__count">{propertydetail?.reviews?.length}</span>
                      <span> Reviews</span>
                    </div>
                  </div>
                </div>
                <div className="rlr-product-detail-header__actions">
                  <button type="button" onClick={() => setIsShare(!isShare)} data-bs-toggle="popover-share" data-content-id="rlr-js-share-popover" id="rlr-js-share-button" className="btn rlr-button rlr-button--circle rlr-popover-button" aria-label="share">
                    <i className="rlr-icon-font flaticon-share-1"></i>
                  </button>
                  <div className="rlr-product-detail-header__button-wrapper">
                    <button type="button" onClick={() => handleProLike()} className={propertydetail?.likes && propertydetail?.likes?.findIndex(x => x == user?._id) != -1 ? "btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button rlr-js-action-wishlist active" : "btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button rlr-js-action-wishlist"} aria-label="Save to Wishlist">
                      <i className="rlr-icon-font flaticon-heart-1"> </i>
                    </button>
                    <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                  </div>
                </div>
              </div>
              <div className="rlr-secondary-menu-desc mt-4" data-id="rlr-product-sec-overview">
                <div className="rlr-secondary-menu-desc__details">
                  <div className="rlr-overview-detail">
                    <div className="rlr-readmore-desc rlr-overview-detail__description">
                      <p className="rlr-readmore-desc__content rlr-js-desc">
                        <HTMLRenderer html={propertydetail?.desc} />
                      </p>
                      <span className="rlr-readmore-desc__readmore rlr-js-readmore">Show more...</span>
                    </div>

                  </div>
                </div>
              </div>

              <div className="rlr-secondary-menu-desc mt-4" data-id="rlr-product-sec-overview">
                <div className="rlr-secondary-menu-desc__details">
                  <Row>
                    {propertydetail?.gallery?.map((url, index) => (
                      <Col lg={6} md={6} className='mt-4' style={{ cursor: 'pointer' }} onClick={() => { setCurrentLiteImg(index); setLiteBox(true) }}>
                        <img src={url} />
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>

              {/* {liteBox && propertydetail?.gallery?.length > 0 && ( */}
              <Lightbox
                open={liteBox}
                close={() => setLiteBox(false)}
                slides={
                  propertydetail?.gallery?.map((item) => (
                    { src: item }
                  ))
                }
                index={currentLiteImg}
              />
              {/* )} */}
              {/* <!-- Reviews --> */}
              <div className="rlr-secondary-menu-desc" data-id="rlr-product-sec-review">
                {/* <!-- Icon --> */}
                <div className="rlr-secondary-menu-desc__icon">
                  <svg width="40" height="41" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M51 26.0569C51 11.9289 39.5833 0.47583 25.5 0.47583C11.4167 0.47583 0 11.9289 0 26.0569C0 40.1849 11.4167 51.6379 25.5 51.6379C29.9108 51.6379 34.1604 50.5124 37.9266 48.4C37.9928 48.4321 38.0883 48.4895 38.2244 48.5807C38.2823 48.6195 38.741 48.9376 38.907 49.0479C39.2393 49.2688 39.5554 49.4601 39.9016 49.6434C42.0271 50.7687 44.7903 51.3004 48.7583 51.0338C49.483 50.9851 49.7938 50.0869 49.2549 49.5983C47.8766 48.3484 46.4596 46.762 45.4972 45.3922C45.0172 44.7088 44.6728 44.1109 44.498 43.6599C44.43 43.4842 44.3928 43.3435 44.383 43.2488C48.6135 38.5755 51 32.502 51 26.0569ZM40.6695 48.1838C40.3757 48.0282 40.1053 47.8646 39.8158 47.6722C39.6669 47.5732 39.2115 47.2574 39.1383 47.2083C38.4835 46.7696 38.0509 46.609 37.4761 46.7944C37.3816 46.8249 37.2904 46.8654 37.2024 46.9152C33.6605 48.9194 29.6586 49.9875 25.5 49.9875C12.3253 49.9875 1.64516 39.2734 1.64516 26.0569C1.64516 12.8403 12.3253 2.12622 25.5 2.12622C38.6747 2.12622 49.3548 12.8403 49.3548 26.0569C49.3548 32.125 47.0956 37.837 43.0914 42.2203C41.9783 43.4387 43.8851 46.5383 46.7284 49.4515C44.0844 49.4414 42.173 48.9798 40.6695 48.1838Z"
                      fill="#99A3AD"
                    />
                    <path
                      d="M31.9727 35.6478L25.6956 31.2659C25.4132 31.0688 25.0384 31.0688 24.756 31.2659L18.4789 35.6478L20.6934 28.3049C20.793 27.9745 20.6771 27.6169 20.403 27.4084L14.3091 22.7736L21.9548 22.6173C22.2987 22.6103 22.602 22.3892 22.715 22.0632L25.2258 14.8169L27.7366 22.0632C27.8496 22.3892 28.1529 22.6103 28.4969 22.6173L36.1425 22.7736L30.0486 27.4084C29.7745 27.6169 29.6586 27.9745 29.7582 28.3049L31.9727 35.6478ZM25.2258 32.9486L32.9755 38.3584C33.6156 38.8053 34.4585 38.191 34.2327 37.4421L31.4987 28.3766L39.0222 22.6545C39.6437 22.1819 39.3218 21.1879 38.542 21.1719L29.1027 20.979L26.0028 12.0327C25.7467 11.2937 24.7049 11.2937 24.4488 12.0327L21.349 20.979L11.9096 21.1719C11.1299 21.1879 10.8079 22.1819 11.4294 22.6545L18.9529 28.3766L16.219 37.4421C15.9931 38.191 16.836 38.8053 17.4761 38.3584L25.2258 32.9486Z"
                      fill="#99A3AD"
                    />
                  </svg>
                </div>
                <div className="rlr-secondary-menu-desc__details">
                  {/* <!-- Review --> */}
                  {propertydetail?.reviews?.map((item, index) => {
                    if (index < currentViews)
                      return (
                        <article className="rlr-review-card" itemscope >
                          <div className="rlr-review-card__contact">
                            {/* <!--Using in Components --> */}
                            <div className="rlr-avatar">
                              {item?.user?.length > 0 ?
                                <>
                                  <img className="rlr-avatar__media--rounded" src={item?.user[0]?.profile_picture ? item?.user[0]?.profile_picture : require('../assets/images/woow-destination.png')}
                                    style={{ width: 50, height: 'auto' }}
                                    itemprop="avatar" alt="avatar icon" />
                                  <span className="rlr-avatar__name" itemprop="name">{item?.user[0]?.name}</span>
                                </>
                                :
                                <>
                                  <img className="rlr-avatar__media--rounded" src={item?.avatar ? item?.avatar : require('../assets/images/woow-destination.png')}
                                    style={{ width: 50, height: 'auto' }}
                                    itemprop="avatar" alt="avatar icon" />
                                  <span className="rlr-avatar__name" itemprop="name">{item?.userName}</span>
                                </>
                              }
                            </div>
                            <div>
                              <div className="rlr-review-stars" itemprop="ratingValue" itemscope>
                                <div className='like_section'>
                                  {user && item?.userId == user?._id &&
                                    <h4><i className="fa-solid fa fa-pencil" onClick={() => navigate("/review/" + item?._id, { state: { data: item } })}></i></h4>
                                  }
                                  <h4 className={checkMyLike(item?.likes) ? "active" : ""}><i className="fa-regular fa-thumbs-up" onClick={() => { if (!checkMyLike(item?.likes)) handleLike(item?._id, 'like') }}></i><span>{item?.likes?.length}</span></h4>
                                  <h4 className={checkMyLike(item?.dislikes) ? "active" : ""}><i className="fa-regular fa-thumbs-down" onClick={() => { if (!checkMyLike(item?.dislikes)) handleLike(item?._id, 'dislike') }}></i><span>{item?.dislikes?.length}</span></h4>
                                </div>
                                <StarRatings
                                  rating={item?.star}
                                  starRatedColor={'#f78f08'}
                                  starHoverColor={'#f78f08'}
                                  numberOfStars={5}
                                  isSelectable={false}
                                  name='rating'
                                  starDimension={'18px'}
                                  starSpacing={'2px'}
                                />
                              </div>
                              {/* <div className="rlr-review-card__title"> */}
                              {/* <h3 className="rlr-review-card__title-review"></h3> */}
                              <p className="rlr-review-card__review-date" style={{ textAlign: 'right',fontSize:10 }} itemprop="review date">Reviewed {item?.createdAt}</p>
                              {/* </div> */}
                            </div>
                          </div>
                          <div className="rlr-review-card__details">
                            <div className="rlr-review-card__comments" itemprop="review description">
                              <div className="rlr-readmore-desc">
                                <p className="rlr-readmore-desc__content rlr-js-desc">{item?.desc}</p>
                                <span className="rlr-readmore-desc__readmore rlr-js-readmore">Show more...</span>
                              </div>
                            </div>
                          </div>
                        </article>
                      )
                  })}
                  {/* <!-- Pagination --> */}
                  <div className="rlr-secondary-menu-desc__footer">
                    {/* <span className="rlr-secondary-menu-desc__show-more">Show 10+ more reviews</span> */}
                    {user ?
                      <>
                        {propertydetail?.reviews?.findIndex(x => x?.userId == user?._id) == -1 ?
                          <Link to={"/review/" + propertyId} className="rlr-secondary-menu-desc__review-link">Write a Review</Link>
                          :
                          <Link className="rlr-secondary-menu-desc__review-link"></Link>
                        }
                      </>
                      :
                      <Link to={"/login"} className="rlr-secondary-menu-desc__review-link">Write a Review</Link>
                    }
                    {propertydetail?.reviews?.length > currentViews &&
                      <Link className="rlr-secondary-menu-desc__review-link" onClick={() => setCurrentViews(currentViews + 5)}>Show more reviews</Link>
                    }
                  </div>
                </div>
              </div>
            </div>
            <aside className="col-xl-4 col-xxxl-3 d-xl-block offset-xxxl-1 mt-5 mt-lg-0">
              <div className='rlr_overview_detail__icon_groupset_new_fixed'>
                <div className="rlr-overview-detail__icon-groupset rlr_overview_detail__icon_groupset_new" style={{ flexWrap: 'wrap' }}>
                  <Row lg={12}>
                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group">
                        <span className="rlr-overview-detail__label">Capital</span>
                        <div className="rlr-overview-detail__icons">
                          <figure className="rlr-icon-text"><i className="rlr-icon-font flaticon-star-1"> </i> {propertydetail?.country[0]?.capital}</figure>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group">
                        <span className="rlr-overview-detail__label">Language</span>
                        <div className="rlr-overview-detail__icons">
                          <figure className="rlr-icon-text"><i className="rlr-icon-font fa fa-language"> </i> {propertydetail?.country?.[0].language}</figure>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group">
                        <span className="rlr-overview-detail__label">Time Diffrence</span>
                        <div className="rlr-overview-detail__icons">
                          <figure className="rlr-icon-text"><i className="rlr-icon-font fa fa-clock"> </i> {propertydetail?.country[0]?.timeDiff}</figure>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group">
                        <span className="rlr-overview-detail__label">Currency</span>
                        <div className="rlr-overview-detail__icons">
                          <figure className="rlr-icon-text"><i className="rlr-icon-font fa fa-usd"> </i> {propertydetail?.country[0]?.currency}</figure>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group">
                        <span className="rlr-overview-detail__label">Duration</span>
                        <div className="rlr-overview-detail__icons">
                          <figure className="rlr-icon-text"><i className="rlr-icon-font flaticon-three-o-clock-clock"> </i> <span className="">{propertydetail?.nights}</span></figure>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group">
                        <span className="rlr-overview-detail__label">Location</span>
                        <div className="rlr-overview-detail__icons">
                          <figure className="rlr-icon-text"><i className="rlr-icon-font flaticon-map-marker"> </i> <span className="">{propertydetail?.location}, {propertydetail?.country ? propertydetail?.country[0]?.name : ""} </span></figure>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="rlr-overview-detail__icon-group" style={{ width: '100%' }}>
                        <span className="rlr-overview-detail__label">Best Months</span>
                        <div className="rlr-overview-detail__icons" style={{ width: '100%' }}>
                          <figure className="rlr-icon-text">
                            <i className="rlr-icon-font flaticon-beach"> </i>
                            <div className='months_box'>
                              {propertydetail?.month?.map((item) => (<span className="">{item?.name}, </span>))}
                            </div>
                          </figure>
                        </div>
                      </div>
                    </Col>
                  </Row>

                </div>

                {propertydetail &&
                  getHotelWidget()
                }

              </div>
            </aside>
          </section>
          {ads.map((item) => {
            if (item?.position == 'top')
              return (
                <div className='addSection addSection_inner_two' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                  <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} /></a>
                </div>
              )
          })}
          {/* <!-- Similar Products --> */}
          {propertydetail?.similer?.length > 0 &&
            <section className="rlr-section rlr-section__mt rlr-related-product-wrapper">
              {/* <!-- Section heading --> */}
              <div className="rlr-section-header">
                {/* <!-- Section heading --> */}
                <div className="rlr-section__title">
                  <h2 className="rlr-section__title--main">More Destination in {propertydetail ? propertydetail?.country[0]?.name : ""}</h2>
                  <span className="rlr-section__title--sub">Explore the diverse array of destinations within this beautiful country.</span>
                </div>
                <div className="button-row">
                <Link target='_blank' to={`/country/${compressId(propertydetail?.country[0]?._id)}/top-travel-destinations-in-${parseName(propertydetail?.continent[0]?.name)}-${parseName(propertydetail?.country[0]?.name)}`} className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
                </div>
              </div>
              <div className="row rlr-featured__cards">
                {propertydetail?.similer?.map((item) => (
                  <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-offset="250" data-aos-duration="700" style={{ cursor: 'pointer' }} onClick={() => openProperty(item)}>
                    {/* <!-- Featured prodcut card --> */}
                    <article className="rlr-product-card rlr-product-card--featured" itemscope>
                      {/* <!-- Image --> */}
                      <figure className="rlr-product-card__image-wrapper ">
                        <img itemprop="image" src={item?.banner} data-src={item?.banner} data-srcset={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                      </figure>
                      {/* <!-- Card body --> */}
                      <div className="rlr-product-card--featured__inner">
                        {item?.avgRating > 0 &&
                          <span className="rlr-badge rlr-badge--right rlr-badge-- rlr-badge--abs rlr-badge--abs-right"> {item?.avgRating} Ratings </span>
                        }
                        <div className="rlr-product-card--featured__body card-img-overlay">
                          {/* <div className="rlr-product-card--featured__duration">
                          <p className="body">10 Days | 09 Nights</p>
                          <h4 className="type-h4">$895.50</h4>
                        </div> */}
                          <div className="rlr-product-detail-header__actions justify-content-end" style={{ width: '100%' }}>
                            {/* <div className="rlr-product-detail-header__button-wrapper">
                            <button type="button" className="btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--no-bg rlr-wishlist-button rlr-js-action-wishlist" aria-label="Save to Wishlist">
                              <i className="rlr-icon-font flaticon-heart-1"> </i>
                            </button>
                            <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                          </div> */}
                            <div className="btn rlr-button product-card-buttonrlr-button--medium rlr-button--rounded rlr-button--brand"> Explore </div>
                          </div>
                        </div>
                      </div>
                      {/* <a href="./product-detail-page.html" className="rlr-product-card__anchor rlr-product-card__anchor--featured"></a> */}
                    </article>
                    {/* <!-- Summary --> */}
                    <div className="rlr-product-card--featured__summary">
                      <h4 className="type-h6-semi-bold pb-0">{item?.name}</h4>
                      <p className="type-body">{item?.location} | {item?.country[0]?.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          }
          {propertydetail &&
            <div className='map_right  map_right_footer'>
              <MapContainer lat={Number(propertydetail?.lat)} long={Number(propertydetail?.long)} title={propertydetail?.name} />
            </div>
          }
          {/* Hotels */}
          {propertydetail?.hotel?.length > 0 &&
            <section className="rlr-section rlr-section__mt rlr-related-product-wrapper">
              {/* <!-- Section heading --> */}
              <div className="rlr-section-header">
                {/* <!-- Section heading --> */}
                <div className="rlr-section__title">
                  <h2 className="rlr-section__title--main">Hotels Nearby {propertydetail ? propertydetail?.name : ""}</h2>
                  <span className="rlr-section__title--sub">Explore the diverse array of destinations within this beautiful country.</span>
                </div>
                <div className="button-row">
                  <Link className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
                </div>
              </div>
              <div className="row rlr-featured__cards">
                {propertydetail?.hotel?.map((item) => (
                  <div className="col-md-4 col-lg-4" data-aos="fade-up" data-aos-offset="250" data-aos-duration="700" style={{ cursor: 'pointer' }} onClick={() => openConti(item)}>
                    {/* <!-- Featured prodcut card --> */}
                    <article className="rlr-product-card rlr-product-card--featured" itemscope >
                      {/* <!-- Image --> */}
                      <figure className="rlr-product-card__image-wrapper ">
                        <img itemprop="image" src={item?.banner} data-src={item?.banner} data-srcset={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                      </figure>
                      {/* <!-- Card body --> */}
                      <div className="rlr-product-card--featured__inner">
                        <span className="rlr-badge rlr-badge--right rlr-badge-- rlr-badge--abs rlr-badge--abs-right"> {item?.city} </span>
                        <div className="rlr-product-card--featured__body card-img-overlay">
                          {/* <div className="rlr-product-card--featured__duration">
                          <p className="body">10 Days | 09 Nights</p>
                          <h4 className="type-h4">$895.50</h4>
                        </div> */}
                          <div className="rlr-product-detail-header__actions justify-content-end" style={{ width: '100%' }}>
                            {/* <div className="rlr-product-detail-header__button-wrapper">
                            <button type="button" className="btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--no-bg rlr-wishlist-button rlr-js-action-wishlist" aria-label="Save to Wishlist">
                              <i className="rlr-icon-font flaticon-heart-1"> </i>
                            </button>
                            <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                          </div> */}
                            <div className="btn rlr-button product-card-buttonrlr-button--medium rlr-button--rounded rlr-button--brand"> Explore </div>
                          </div>
                        </div>
                      </div>
                      {/* <a href="./product-detail-page.html" className="rlr-product-card__anchor rlr-product-card__anchor--featured"></a> */}
                    </article>
                    {/* <!-- Summary --> */}
                    <div className="rlr-product-card--featured__summary">
                      <h4 className="type-h6-semi-bold pb-0">{item?.name}</h4>
                      {/* <p className="type-body">{item?.location} | {item?.city}</p> */}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          }
          {ads.map((item) => {
            if (item?.position == 'bottom')
              return (
                <div className='addSection addSection_inner_two' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} /></a>
                </div>
              )
          })}
        </div>
      </main>
    </>
  )
}

// DetailPage.propTypes = {
//   error: PropTypes.any,
//   success: PropTypes.any
// }

// const mapStatetoProps = state => {
//   const { user } = state.Profile;
//   return { user }
// }

// export default withRouter(
//   connect(mapStatetoProps, { })(DetailPage)
// )
