import React, { Component, useEffect } from 'react';
// import GoogleMapReact from 'google-map-react';
import { GoogleMap, Marker, useLoadScript, useJsApiLoader } from '@react-google-maps/api';
// import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import * as Utils from '../../Utils';
import "./style.css";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function MapContainer(props) {
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: Utils.googleMapApiKey
  // });
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Utils.googleMapApiKey
  });
  const { lat, long, title } = props;
  const defaultProps = {
    center: {
      lat,
      lng: long
    },
    zoom: 10
  };
  const [map, setMap] = React.useState(null);

  useEffect(() => {
    if (map) {
      // getReviews();
    }
  }, [map]);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(defaultProps?.center);
    // map.fitBounds(bounds);
    setMap(map);
    const marker = new window.google.maps.Marker({
      position: defaultProps.center,
      map,
      // onLoad: (e => console.log('loaded marker', e))
    });
    // let service = new google.maps.places.PlacesService(map);
    // // console.log('service', service);
    // service.getDetails({
    //   // placeId: 'ChIJAUKRDWz2wokRxngAavG2TD8'
    // }, function (place, status) {
    //   if (status === google.maps.places.PlacesServiceStatus.OK) {
    //     console.log('reviews from google', place.reviews);
    //     // Intended behavior is to set this.setState({places.place.reviews})
    //   }
    // })
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const getReviews = async () => {
    const { Place } = await google.maps.importLibrary("places");
    const request = {
      query: title,
      fields: [
        "displayName",
        "location",
        // "businessStatus",
        // "hasWheelchairAccessibleEntrance",
      ],
      // includedType: "restaurant",
      // isOpenNow: true,
      language: "en-US",
      maxResultCount: 7,
      minRating: 3.2,
      // region: "us",
      useStrictTypeFiltering: false,
    };
    const place = await google.maps.places.Place.findPlaceFromQuery(request);
    console.log('place', place);
  }

  // console.log('map', map);


  if (!isLoaded)
    return (
      <div style={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span>Loading...</span>
      </div>
    );
  else return (
    <div style={{ height: '60vh', width: '100%' }}>
      {/* <span>{defaultProps?.center?.lat}</span> */}
      <GoogleMap
        zoom={defaultProps.zoom}
        center={defaultProps.center}
        mapContainerClassName="map-container"
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* {map && */}
        {/* <Marker
          position={defaultProps.center}
          onLoad={(e) => console.log("marker loaded", e)}
        /> */}
        {/* } */}
      </GoogleMap>
    </div>
  );
}

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAhf6IGLhFAbwKMH3ExQE2JELycSkl-oq0'
// })(MapContainer);

// import GoogleMapReact from 'google-map-react';
// const AnyReactComponent = ({ text }) => <div>{text}</div>;
// export default function SimpleMap() {
//   const defaultProps = {
//     center: {
//       lat: 10.99835602,
//       lng: 77.01502627
//     },
//     zoom: 11
//   };

//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '60vh', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyAhf6IGLhFAbwKMH3ExQE2JELycSkl-oq0" }}
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       >
//         <AnyReactComponent
//           lat={59.955413}
//           lng={30.337844}
//           text="My Marker"
//         />
//       </GoogleMapReact>
//     </div>
//   );
// }