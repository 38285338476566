import React, { useEffect, useState } from 'react';
import Loader from '../../Loader';
import ScriptTag from 'react-script-tag';

function HotelWidget(props) {

    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props?.property)
            setProperty(props?.property);
    }, [props]);

    useEffect(() => {
        let added = document.getElementById("1234567890");
        if (added)
            added.remove();
        setLoading(true);
        // setLoading(false);
        if (property) {
            setTimeout(() => {
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.id = "1234567890";
                script.async = true;
                script.function = ((d, sc, u) => {
                    var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
                    // console.log('getting element',s, p)
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = u + '?v=' + (+new Date());
                    p.parentNode.insertBefore(s, p);
                })(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
                document.body.appendChild(script);
                setLoading(false);
            }, 500);
        }
    }, [property]);

    return (
        <div style={{ width: '100%', height: 590 }} className='booking_sticky_setion'>
            <ins class="bookingaff" data-aid="2312218" data-target_aid="2312218" data-prod="map" data-width="100%" data-height="590" data-lang="ualng" data-dest_id="0" data-dest_type="landmark" data-latitude={property?.lat} data-longitude={property?.long} data-landmark_name={property?.name} data-mwhsb="0" data-address={property?.name + ", " + property?.location}>
                {/* {loading && */}
                <div style={{ width: '100%', height: '100%', display: 'flex', textDecoration:'none', justifyContent: 'center', alignItems: 'center',flexDirection:'column',textAlign:'center' }}>
                        <img src={require('../../assets/images/loading.gif')} style={{width:80,height:80}} alt="loading" />
                        <span style={{textDecoration:'none'}}>Loading...</span>
                    </div>
                {/* } */}
            </ins>
        </div>
    )
}

export default HotelWidget;