import React from 'react'
export default function index() {
  return (
    <>
     {/* <Header /> */}
     <main id="rlr-main" className="rlr-main--fixed-top">
      <section className="rlr-icon-font-page rlr-section rlr-section__content--md-top">
        <div className="container">
          {/* <!-- Section heading --> */}
          <div className="rlr-section-header">
            {/* <!-- Section heading --> */}
            <div className="rlr-section__title">
              <h2 className="rlr-section__title--main">Travel gallery</h2>
              <span className="rlr-section__title--sub">Sost Brilliant reasons Emprise should be your one-stop-shop!</span>
            </div>
            <div className="button-row">
              <a href="#" className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand"> View tours </a>
            </div>
          </div>
          <div className="rlr-lightbox--gallery">
            <div className="rlr-lightbox--gallery__wrapper">
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/aman-kvknav96-di-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/aman-kvknav96-di-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>1</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/aman-mzeyep3symi-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/aman-mzeyep3symi-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>2</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/andreas-m-88intec2q5q-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/andreas-m-88intec2q5q-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>3</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/anna-sullivan-g7b2bqwtluq-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/anna-sullivan-g7b2bqwtluq-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>4</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/anna-sullivan-nnu2nafn34c-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/anna-sullivan-nnu2nafn34c-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>5</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/anna-sullivan-zvuy4-sbaum-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/anna-sullivan-zvuy4-sbaum-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>6</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/christian-lambert-ycyxpdhcnp8-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/christian-lambert-ycyxpdhcnp8-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>7</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/cory-bjork-752uo3zewig-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/cory-bjork-752uo3zewig-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>8</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/david-vives-elf8m-ywrty-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/david-vives-elf8m-ywrty-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>9</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/gerson-repreza-nx3sdckkfww-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/gerson-repreza-nx3sdckkfww-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>10</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/gerson-repreza-pw3tjkrksy8-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/gerson-repreza-pw3tjkrksy8-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>11</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/helen-ngoc-n-p9vszt2lnny-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/helen-ngoc-n-p9vszt2lnny-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>12</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/james-johnston-dwhaprpqvmi-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/james-johnston-dwhaprpqvmi-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>13</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/josh-hammond-vonmtj81t-e-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/josh-hammond-vonmtj81t-e-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>14</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/meg-jerrard-zevmf9g-4ky-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/meg-jerrard-zevmf9g-4ky-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>15</span>
                  </figcaption>
                </figure>
              </a>
              <a data-fslightbox="demo-images" data-foo="bar" href="./assets/images/gallery/1568/suzy-brooks-nygy9pb5mp4-unsplash1568.jpg">
                <figure className="rlr-lightbox--gallery__figure">
                  <img className="rlr-lightbox--gallery__img" src="./assets/images/gallery/thumbs/suzy-brooks-nygy9pb5mp4-unsplash507.jpg" alt="Anchor Button" />
                  <figcaption className="rlr-lightbox--gallery__figcaption">
                    <span>16</span>
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
     {/* <Footer /> */}
    </>
  )
}
