import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';

function Share(props) {
    const { show, data, type, setShow, remove } = props;
    console.log('video ', data?.url)
    return (
        <Modal isOpen={show} toggle={() => setShow(false)} backdrop="static" centered className='video_modal'>
            <ModalBody>
                <div id="" className={""}>
                    <div className="video_iframe">
                        <iframe width="100%" src={`https://www.youtube.com/embed/` + data?.url?.split('/')[data?.url?.split('/')?.length - 1]}>
                        </iframe>
                        <button className='btn btn-danger' onClick={() => setShow(false)}>
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default Share;