import React from 'react'

function Route404() {
    return (
        <div>
            <main id="rlr-main" class="rlr-main--fixed-top">
                <section class="rlr-section rlr-error">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 rlr-error__content">
                                <p class="type-lead-semibold">404 Error</p>
                                <h1 class="type-h1">Page not found</h1>
                                <span class="rlr-section__heading--sub">Sorry but the page you are looking for has been removed or had its name changed. Please try your search again!</span>
                                {/* <form class="rlr-error__search">
                                    <label class="rlr-form-label rlr-form-label--light"> Search emprise site </label>
                                    <div class="rlr-input-group rlr-input-group__search">
                                        <input type="search" autocomplete="off" class="form-control form-input" />
                                    </div>
                                </form> */}
                            </div>
                            <div class="offset-lg-6"></div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Route404;