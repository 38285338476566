import React, { useEffect, useState } from 'react'
// import Header from '../../Header'
// import Footer from '../../Footer'
import { Link } from 'react-router-dom'
import { get, post } from '../../helper/helper_api';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { email } from '../../Utils';
import { Helmet } from 'react-helmet';
import HTMLRenderer from 'react-html-renderer';
import Share from '../../component/ShareModal';
import { Button, Col, Row } from 'reactstrap';
import MapContainer from '../../component/MapContainerRight';

export default function Index(props) {
  const location = useLocation();
  let navigate = useNavigate();
  // console.log('property item', location?.state)
  const [proID, setProID] = useState(location?.state);
  const [loader, setLoader] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [type, setType] = useState(null);
  const [sortBy, setSortBy] = useState(false);
  const swiperRef = useRef();
  const [properties, setProperties] = useState(0);
  const [experience, setExperience] = useState([]);
  const [headerData, setHeaderData] = useState(props?.headerData);
  const [ads, setAds] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [shareData, setShareData] = useState(null);
  const [shareLink, setShareLink] = useState("");
  const [mainObj, setMainObj] = useState(undefined);
  const [totalCountries, setTotalCountries] = useState(0);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);

  useEffect(() => {
    if (props?.headerData)
      setHeaderData(props?.headerData);
  }, [props])

  useEffect(() => {
    // console.log('path on listing', location.pathname);
    window.scrollTo(0, 0);
    let path = location.pathname.split("/");
    if (path.length > 0) {
      setType(path[1]);
      setCurrentPage(0);
    }
    if (path.length > 1)
      setProID(path[2]);
    return;
  }, [location]);

  useEffect(() => {
    if (proID) {
      getCardList();
      getCountries();
    }
  }, [proID]);

  const getCardList = () => {
    // console.log('pro id type', type);
    get("/ads/list?screen=home")
      .then(res => {
        // console.log('response from ads list', res)
        if (res?.statusCode == 200)
          setAds(res?.data);
      }).catch(err => {
        console.log('error while getting ads list', err);
      });
  }

  const getCountries = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      let url = '/continent/continent/';
      let body = {
        continentId: proID,
        currentPage
      }
      post(url, body).then((json) => {
        // console.log('property filter continentId list', json);
        setLoader(false);
        setLoadingMore(false);
        if (json?.statusCode == 200) {
          if (currentPage == 0) {
            setMainObj(json?.data);
            setTotalCountries(json?.totalCountries);
            setTotalPages(json?.totalCountries / 9);
            let data = [];
            json?.data?.country?.map((con, index) => {
              data.push(con);
              if (index == 5)
                data.push({ type: 'ads' });
            });
            setData(data);
            setCurrentPage(1);
          } else {
            let temp = Object.assign([], data);
            setData([...temp, ...json?.data?.country]);
            setCurrentPage(json?.currentPage + 1);
          }
        } else {
          navigate("/404")
        }
      }).catch((err) => {
        toast.error(err);
        console.log(err);
        setLoader(false);
        setLoadingMore(false);
      })
    }
  }

  const compressId = (id) => {
    const temp = id?.slice(id?.length - 4, id?.length);
    // console.log('compressing id', temp);
    return temp;
  }

  const openCountry = (item) => {
    window.open('/country/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(mainObj?.name) + "-" + parseName(item?.name), "_blank");
  }

  const parseName = (str) => {
    let temp = str.split(",").join("");
    return temp.split(" ").join("-").toLowerCase();
  }

  const openConti = (item) => {
    window.open('/continent/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name), "_blank");
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, data]);

  const handleScroll = () => {
    setCurrentScroll(window.scrollY);
    let divHeight = document.getElementById("scrollableView")?.scrollHeight;
    if (window?.scrollY > (divHeight))
      if (totalPages > currentPage)
        getCountries();
    // toast.success("loading more...")
    // console.log('current scroll posistion', window.scrollY, divHeight);
    //     getSearch();
  }

  return (

    <>
      {
        loader && <Loader />
      }
      {mainObj &&
        <Helmet>
          <title>{mainObj?.name} | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, best top travel destination in${mainObj?.country?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={mainObj?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${mainObj?.name} | Woow Destinations`} />
          <meta name="twitter:description" content={mainObj?.desc} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={mainObj?.banner} />
          <meta property="og:image:alt" content={mainObj?.desc} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${mainObj?.name} | Woow Destinations`} />
          <meta property="og:url" content={"http://woowdestinations.com" + location?.pathname} />
          <meta property="og:description" content={mainObj?.desc} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, best top travel destination in${mainObj?.country?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={mainObj?.desc} />
          {/* <meta name="geo.region" content="IN-GN" />
         <meta name="geo.placename" content={mainObj?.location} />
         <meta name="geo.position" content={`${mainObj?.lat};${mainObj?.long}`} />
         <meta name="ICBM" content={`${mainObj?.lat};${mainObj?.long}`} /> */}
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="http://woowdestinations.com/" />
        </Helmet>
      }
      <Share
        show={isShare}
        data={shareData}
        type={"Destination"}
        setShow={setIsShare}
        pathname={shareLink}
      />
      {/* <Header setExperience={setExperience} setHeaderData={setHeaderData} /> */}
      <main id="rlr-main" className="rlr-main--fixed-top rlr_main_fixed_top_inner_page">
        <div className="rlr-search-results-page container">
          <div className="rlr-search-results-page__breadcrumb-section">
            {/* <!-- Breadcrumb --> */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb rlr-breadcrumb__items">
                <li className="breadcrumb-item rlr-breadcrumb__item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item rlr-breadcrumb__item" style={{ cursor: 'pointer' }} aria-current="page"><Link to="/continent/top-travel-destinations-by-continents">Continent</Link></li>
                <li className="breadcrumb-item rlr-breadcrumb__item active">{mainObj?.name ? mainObj?.name : ""}</li>
                {/* <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">{mainObj[0]?.name}</li> */}
              </ol>
            </nav>
            <div className="rlr-icon-text"><i className="rlr-icon-font flaticon-email mt-1" style={{ fontWeight: 'bold' }}> </i> <span className="rlr-search-results-page__phone">Questions? {email} </span></div>
          </div>
          <div className='banner-section'>
            <img src={mainObj?.banner} alt={mainObj?.banner} />
          </div>
          <aside className="row">
            {/* <!-- Search results header --> */}
            <div className="rlr-search-results-header rlr-search-results-header__wrapper">
              {/* <!-- Title --> */}
              <h1 className="rlr-search-results-header__value">
                Results for <strong>{mainObj?.name}</strong> / {totalCountries} Country / {mainObj?.properties?.length} Destinations
              </h1>
            </div>
          </aside>

          <div className="rlr-section__title mt-4 pt-0 pb-2">
            <h2 className="rlr-section__title--main">Top Countries in {mainObj?.name} Continent</h2>
            {/* <span className="rlr-section__title--sub">Embark on an epic journey of discovery and adventure with our continent-wise tours, exploring the diverse cultures and natural wonders of each corner of the world.</span> */}
          </div>

          <div className="row rlr-search-results-page__product-details" id="scrollableView">
            <div className="rlr-search-results-page__product-list col-xl-12">
              <div className="row rlr-search-results-page__card-wrapper rlr-search-results-page__card-wrapper_bot">
                {
                  data?.map((item, index) => {
                    if (ads?.length > 0 && item?.type && item?.type == 'ads')
                      return (
                        <div style={{ width: '100%' }}>
                          {ads.map((item) => {
                            if (item?.position == 'top')
                              return (
                                <div className='add_section_mid'>
                                  <img src={item?.banner} style={{ width: 'auto', height: 'auto' }} />
                                </div>
                              )
                          })}
                        </div>
                      )
                    else
                      return (
                        <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                          <article className="rlr-product-card rlr-product-card--v3" itemscope >
                            <figure className="rlr-product-card__image-wrapper" >
                              {item?.properties?.length > 0 &&
                                <span className="rlr-badge rlr-badge-- rlr-badge-- rlr-badge--abs rlr-badge--abs-left"> {item?.properties?.length} Destinations </span>
                              }
                              <img itemprop="image" style={{ height: 150 }} src={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                            </figure>
                            <div className="rlr-product-detail-header__button-wrapper" style={{ top: '25px', right: '25px' }}
                              onClick={() => {
                                setShareData(item); setShareLink('/country/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(mainObj?.name) + "-" + parseName(item?.name));
                                setIsShare(true)
                              }}>
                              <button type="button" data-bs-toggle="popover-share" data-content-id="rlr-js-share-popover" id="rlr-js-share-button" className="share_btn rlr-badge btn rlr-button rlr-button--circle rlr-popover-button" aria-label="share">
                                <i className="rlr-icon-font flaticon-share-1"></i>
                              </button>
                              <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                            </div>
                            <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                              <header className="rlr-product-card__header">
                                <div onClick={() => openCountry(item)} style={{ cursor: 'pointer' }}>
                                  <Link className="rlr-product-card__anchor-title">
                                    <h2 className="rlr-product-card__title" itemprop="name">{item.name} <span className="rlr-product-card__sub-title">({item?.capital})</span></h2>
                                  </Link>
                                  <div>
                                    <Link className="rlr-product-card__anchor-cat">
                                      <span className="rlr-product-card__sub-title">{item?.overview?.length > 95 ? item?.overview?.substring(0, 95) + "..." : item?.overview}</span>
                                    </Link>

                                  </div>
                                </div>
                              </header>
                              <div className="rlr-product-card__footer">
                                <div className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-three-o-clock-clock"> </i> <span className="">{item?.timeDiff} </span></div>
                                <ul className="rlr-product-card__icon-text-list" style={{ flexDirection: 'column' }}>
                                  <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font fa fa-language"> </i> <span className="rlr-icon-text__title">
                                    {item?.language.split(',')?.length > 0 && item?.language?.split(",")[0] + ", "}
                                    {item?.language.split(',')?.length > 1 && item?.language?.split(",")[1] + ", "}
                                    {item?.language.split(',')?.length > 2 && item?.language?.split(",")[2] + ", " + "..."}
                                  </span></li>
                                  <li className="rlr-icon-text rlr-product-card__icon-text pt-2"><i className="rlr-icon-font fa fa-usd"> </i> <span className="rlr-icon-text__title">{item?.currency} </span></li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                  })
                }
                {
                  loadingMore &&
                  <div className="loading-container container">
                    <img src={require('../../assets/images/loading.gif')} alt="loading" />
                    <span>Loading...</span>
                  </div>
                }
              </div>
              <hr className="rlr-search-results-page__divider" />
            </div>
          </div>
          <Row>
            <Col lg={8}>
              <aside className="row rlr-search-results-page__dynamic-filter-section mb-0">
                <div className="rlr-section-header description_heading">
                  {/* <!-- Section heading --> */}
                  <div className="rlr-section__title">
                    <h2 className="rlr-section__title--main">
                      About {mainObj?.name}
                    </h2>
                  </div>
                </div>
                <p className="rlr-readmore-desc__content rlr-js-desc">
                  <HTMLRenderer html={mainObj?.desc} />
                </p>
              </aside>
            </Col>
            {/* <Col lg={4}> */}
              {/* {mainObj &&
                <MapContainer
                  countries={mainObj?.country}
                />
              } */}
            {/* </Col> */}
          </Row>
          <section id="features" className="rlr-section rlr-section__mb landding__plugin">
            <div className="container">

              {ads.map((item) => {
                if (item?.position == 'bottom')
                  return (
                    <div className='addSection addSection_inner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={item?.banner} style={{ width: 'auto', height: 'auto' }} />
                    </div>
                  )
              })}
            </div>
          </section>
          <section className="rlr-section rlr-section__mt rlr-related-product-wrapper also-check-more-continents">
            {/* <!-- Section heading --> */}
            <div className="rlr-section-header">
              {/* <!-- Section heading --> */}
              <div className="rlr-section__title">
                <h2 className="rlr-section__title--main">Also Check More Continents</h2>
                <span className="rlr-section__title--sub">Explore the diverse array of destinations within this beautiful continents.</span>
              </div>
              <div className="button-row">
                <Link target='_blank' to="/continent/top-travel-destinations-by-continents" className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
              </div>
            </div>
            <div className="row rlr-featured__cards">
              <div className="row rlr-search-results-page__product-details">
                <div className="rlr-search-results-page__product-list col-xl-12">
                  <div className="row rlr-search-results-page__card-wrapper rlr-search-results-page__card-wrapper_bot">
                    {
                      headerData?.destination?.map((item, index) => {
                        if (item?._id.slice(item?._id.length - 4, item?._id.length) != proID)
                          return (
                            <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <article className="rlr-product-card rlr-product-card--v3" itemscope >
                                <figure className="rlr-product-card__image-wrapper" >
                                  {item?.tours > 0 &&
                                    <span className="rlr-badge rlr-badge-- rlr-badge-- rlr-badge--abs rlr-badge--abs-left"> {item?.tours} Tours </span>
                                  }
                                  <img itemprop="image" style={{ height: 150 }} src={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                                  <div className="rlr-product-detail-header__button-wrapper" style={{ top: '25px', right: '25px' }}
                                    onClick={() => {
                                      setShareData(item); setShareLink('/continent/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name));
                                      setIsShare(true)
                                    }}>
                                    <button type="button" data-bs-toggle="popover-share" data-content-id="rlr-js-share-popover" id="rlr-js-share-button" className="share_btn rlr-badge btn rlr-button rlr-button--circle rlr-popover-button" aria-label="share">
                                      <i className="rlr-icon-font flaticon-share-1"></i>
                                    </button>
                                    <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                                  </div>
                                </figure>
                                <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                                  <header className="rlr-product-card__header">
                                    <div style={{ cursor: 'pointer' }} onClick={() => openConti(item)}>
                                      <Link className="rlr-product-card__anchor-title">
                                        <h2 className="rlr-product-card__title" itemprop="name">{item.name} <span className="rlr-product-card__sub-title">({item?.countries?.length} Countries)</span></h2>
                                      </Link>
                                      <div>
                                        <Link className="rlr-product-card__anchor-cat">
                                          <span className="rlr-product-card__sub-title">{item?.overview?.length > 100 ? item?.overview?.substring(0, 100) + "..." : item?.overview}</span>
                                        </Link>

                                      </div>
                                    </div>

                                  </header>

                                </div>
                              </article>
                            </div>
                          )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      </main>
      {/* <Footer contis={headerData?.destination} experience={headerData?.experiences} month={headerData?.months} /> */}
    </>
  )
}
