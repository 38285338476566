import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function TermsandConditions() {
    useEffect(() => {
		window.scrollTo(0, 0);
		
	}, [])
  return (
    <section className="rlr-about rlr-section rlr-section__my">
    <div className="container">
        <div className='sectin_comm'>
            
            <h4>Terms and Conditions</h4>
             <p>Welcome to Woow Destinations! These Terms and Conditions govern your use of our website <Link to="https://www.woowdestinations.com">https://www.woowdestinations.com</Link> ("Website"), as well as any services or products provided by Woow Destinations ("Woow," "we," "us," or "our"). By accessing or using our Website, you agree to comply with these Terms and Conditions, which form a legally binding agreement between you and Woow Destinations. If you do not agree with any part of these Terms and Conditions, please refrain from using our Website.</p>
             <p>Acceptance of Terms By accessing, browsing, or using our Website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions, along with our Privacy Policy. If you are using the Website on behalf of an organization or entity, you represent and warrant that you have the authority to bind that organization to these Terms and Conditions.</p>
             <p>Changes to the Terms and Conditions Woow Destinations reserves the right to modify or update these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting. It is your responsibility to regularly review the Terms and Conditions for any updates. Continued use of the Website after any changes constitute your acceptance of the modified Terms and Conditions.</p>
             <p>Use of the Website 3.1 Eligibility: To use our Website, you must be at least 18 years old or the age of majority in your jurisdiction. By accessing the Website, you represent and warrant that you meet these requirements.</p>
             <p>3.2 User Account: Some areas of the Website may require user registration. You agree to provide accurate, complete, and up-to-date information during the registration process. You are responsible for maintaining the confidentiality of your account and password and agree to accept responsibility for all activities that occur under your account.</p>
             <p>3.3 Prohibited Activities: You agree not to use the Website for any illegal, unauthorized, or unethical purposes, including but not limited to:</p>
             <ul>
                <li>Violating any applicable laws, regulations, or third-party rights.</li>
                <li>Transmitting any material that is defamatory, harmful, abusive, obscene, or otherwise objectionable. </li>
                <li>Interfering with or disrupting the security or integrity of the Website or its content.</li>
                <li>Engaging in any activity that could harm Woow Destinations' reputation or brand.</li>
             </ul>
             <p><strong>1.	Intellectual Property 4.1 Ownership: The Website and all its content, including but not limited to text, images, graphics, logos, videos, software, and trademarks, are the exclusive property of Woow Destinations or its licensors. All rights not expressly granted are reserved.</strong></p>
             <p>4.2 Limited License: Woow Destinations grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Website for personal, non-commercial purposes only. You may not reproduce, distribute, modify, display, perform, or create derivative works of any part of the Website without our prior written consent.</p>
             <p>Third-Party Links and Content The Website may contain links to third-party websites, advertisements, or content that are not owned or controlled by Woow Destinations. We do not endorse or assume any responsibility for the content, privacy policies, or practices of these third-party websites. Your interactions with third-party websites are at your own risk, and we encourage you to review their terms and conditions and privacy policies.</p>
             <p>Limitation of Liability To the extent permitted by applicable law, Woow Destinations and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use or inability to use the Website or its content.</p>
             <p>Indemnification You agree to indemnify and hold harmless Woow Destinations and its affiliates, officers, directors, employees, agents, and licensors from and against any claims, liabilities, damages, losses, costs, or expenses, including attorney's fees, arising out of or in connection with your use of the Website or any violation of these Terms and Conditions.</p>
             <p>Governing Law and Jurisdiction These Terms and Conditions shall be governed by and construed in accordance with the laws of India / Gurgaon, without regard to its conflict of laws principles. Any dispute arising under these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts located in India / Gurgaon.</p>
             <p>Termination Woow Destinations reserves the right, in its sole discretion, to suspend, restrict, or terminate your access to the Website or any part thereof at any time and for any reason, without liability or prior notice.</p>
             <p>Contact Information If you have any questions, concerns, or feedback regarding these Terms and Conditions or the Website, please contact us at <a href="mailto:woowdestinationsweb@gmail.com">woowdestinationsweb@gmail.com</a></p>
             <p>Thank you for choosing Woow Destinations! We hope you have a delightful experience exploring our Website and discovering amazing destinations.</p>
        </div>
    </div>
</section>
  )
}
