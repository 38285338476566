import React, { useEffect, useState, useRef } from 'react'
import './style.css';
import { Link } from 'react-router-dom'
import { get, post } from '../../helper/helper_api';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { email } from '../../Utils';
import StarRatings from 'react-star-ratings';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

export default function Index(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [headerData, setHeaderData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [ads, setAds] = useState([]);
  const [filters, setFilters] = useState({ continents: [], countries: [], months: [], experiences: [], whos: [] });
  const [open, setOpen] = useState('1');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, data]);

  useEffect(() => {
    window.scroll(0, 0);
    getCardList();
    getSearch(0);
  }, [location]);

  const getCardList = () => {
    // console.log('pro id type', type);
    setLoader(true);
    get("/ads/list?screen=home")
      .then(res => {
        // console.log('response from ads list', res)
        if (res?.statusCode == 200)
          setAds(res?.data);
      }).catch(err => {
        console.log('error while getting ads list', err);
      });
  }

  const getSearch = (current) => {
    if (!loader) {
      setLoader(true);
      let body = {
        search: searchStr,
        currentPage: current == 0 ? 0 : currentPage
      }
      post('/property/search/list', body)
        .then((res) => {
          // console.log('response form getting searched property', currentPage, res?.currentPage);
          if (res?.statusCode == 200) {
            let temp = current == 0 ? [] : Object.assign([], data);
            // console.log('old data', temp?.length > 0 ? temp[0]?.name : [], res?.data?.properties[0]?.name);
            setData([...temp, ...res?.data?.properties]);
            setCurrentPage(res?.currentPage + 1);
            setTotalCount(res?.totalCount);
            setLoader(false);
          } else {
            toast.error(res?.error);
            setLoader(false)
          }
        })
        .catch(error => {
          console.log('error while getting popular', error);
          setLoader(false)
        })
    }
  }

  const compressId = (id) => {
    const temp = id.slice(id.length - 4, id.length);
    // console.log('compressing id', temp);
    return temp;
  }

  const openProperty = (item) => {
    window.open("/destination/" + compressId(item?._id) + "/" + (item?.name).split(" ").join("-").toLowerCase() + "-in-" +
      (item?.country[0]?.name).split(" ").join("-").toLowerCase() + "-" +
      (item?.continent[0]?.name).split(" ").join("-").toLowerCase(), '_blank');
  }

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    let divHeight = document.getElementById("scrollableView")?.scrollHeight;
    if (window?.scrollY > (divHeight - 300))
      if (totalCount > currentPage * 9)
        getSearch();
  }

  return (

    <>
      {/* <Header setExperience={setExperience} setHeaderData={setHeaderData} /> */}
      <main id="rlr-main" className="rlr-main--fixed-top rlr_main_fixed_top_inner_page">
        <div className="rlr-search-results-page container">
          <div className="rlr-search-results-page__breadcrumb-section">
            {/* <!-- Breadcrumb --> */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb rlr-breadcrumb__items">
                <li className="breadcrumb-item rlr-breadcrumb__item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">search</li>
                {/* <li className="breadcrumb-item rlr-breadcrumb__item">{propertydetail?.name ? propertydetail?.name : ""}</li> */}
                {/* <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">{propertydetail[0]?.name}</li> */}
              </ol>
            </nav>
            <div className="rlr-icon-text"><i className="rlr-icon-font flaticon-email mt-1" style={{ fontWeight: 'bold' }}> </i> <span className="rlr-search-results-page__phone">Questions? {email} </span></div>
          </div>
          {/* <!-- Product cards --> */}
          <div className="row rlr-search-results-page__product-details pt-3">
            {/* cards */}
            <div className='search_input'>
              <div className='search_field'>
                <input type="text" name="search" placeholder='Search Country, Place, About destination' onChange={(e) => setSearchStr(e.target.value)} />
                <button onClick={() => getSearch(0)}><i className="fa fa-search fs-6"></i> Search</button>
              </div>
            </div>
            <div className="rlr-search-results-page__product-list col-lg-12" id="scrollableView">
              <div className="row rlr-search-results-page__card-wrapper">
                {
                  data.map((item, index) => {
                    if (ads?.length > 0 && item?.type && item?.type == 'ads')
                      return (
                        <div style={{ width: '100%' }}>
                          {ads.map((item) => {
                            if (item?.position == 'top')
                              return (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                                  <Link to={item?.utm} target='_black'>
                                    <img src={item?.banner} style={{ width: 'auto', height: 'auto' }} />
                                  </Link>
                                </div>
                              )
                          })}
                        </div>
                      )
                    else
                      return (
                        <div className="col-md-6 col-xl-4 col-xxl-4" style={{ cursor: 'pointer' }} onClick={() => openProperty(item)}>
                          <article className="rlr-product-card rlr-product-card--v3" itemscope >
                            <figure className="rlr-product-card__image-wrapper">
                              {/* <span className="rlr-badge rlr-badge-- rlr-badge-- rlr-badge--abs rlr-badge--abs-left"> {item?.properties?.length} Tours </span> */}
                              <img itemprop="image" style={{ height: 150 }} src={item?.banner} data-sizes="auto" className="lazyload" alt="" />
                            </figure>
                            <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                              <header className="rlr-product-card__header">
                                <div>
                                  <a className="rlr-product-card__anchor-title">
                                    <h2 className="rlr-product-card__title" itemprop="name">{item.name}</h2>
                                  </a>
                                  <div>
                                    <a className="rlr-product-card__anchor-cat">
                                      <span className="rlr-product-card__sub-title">{item?.overview.substring(0, 100)}...</span>
                                    </a>

                                  </div>
                                </div>
                                <div className="rlr-product-detail-header__button-wrapper">
                                  {/* <button type="button" className="btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist" aria-label="Save to Wishlist">
                                    <i className="rlr-icon-font flaticon-heart-1"> </i>
                                  </button> */}
                                  <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                                </div>
                              </header>
                              <div className="rlr-product-card__footer">
                                <div className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-three-o-clock-clock"> </i> <span className="">{item?.nights} </span></div>
                                <ul className="rlr-product-card__icon-text-list">
                                  {/* <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-check"> </i> <span className="rlr-icon-text__title">{item?.language}</span></li>
                                  <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-check"> </i> <span className="rlr-icon-text__title">{item?.currency} </span></li> */}
                                  <div className="rlr-review-stars align-items-center" itemprop="ratingValue" itemscope >
                                    <StarRatings
                                      rating={typeof item?.avgRating == 'number' ? item?.avgRating : 0}
                                      starRatedColor={'rgb(247, 143, 8)'}
                                      starHoverColor={'rgb(247, 143, 8)'}
                                      numberOfStars={5}
                                      isSelectable={false}
                                      name='rating'
                                      starDimension={'20px'}
                                      starSpacing={'2px'}
                                    />
                                    {item?.reviews?.length > 0 &&
                                      <span className="rlr-product-card__from">({item?.reviews?.length})</span>
                                    }
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                  })
                }
                {
                  loader &&
                  <div className="loading-container container">
                    <img src={require('../../assets/images/loading.gif')} alt="loading" />
                    <span>Loading...</span>
                  </div>
                }
              </div>
              <hr className="rlr-search-results-page__divider" />
            </div>
          </div >
        </div >
        <div className='container'>
          {
            ads.map((item) => {
              if (item?.position == 'bottom')
                return (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to={item?.utm} target='_black'>
                      <img src={item?.banner} style={{ width: 'auto', height: 'auto' }} />
                    </Link>
                  </div>
                )
            })
          }
        </div>
      </main >
      {/* <Footer contis={headerData?.destination} experience={headerData?.experiences} month={headerData?.months} /> */}
    </>
  )
}