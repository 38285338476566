import React, { useRef, useState, useEffect } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import './assets/font/all.css'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { get, post } from './helper/helper_api';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import { useSelector } from 'react-redux';
import Loader from './Loader';
import * as Utils from './Utils';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AdSense from 'react-adsense';
// const { compress, decompress } = require('shrink-string');

export default function Home(props) {
  let navigate = useNavigate();
  // console.log('navigate on home', navigate)
  const user = useSelector((state) => state.user.user);
  const [popular, setPopular] = useState([]);
  const [ads, setAds] = useState([]);
  const [contis, setContis] = useState([]);
  const [experience, setExperience] = useState([]);
  const [month, setMonth] = useState([]);
  const [whos, setWhos] = useState([]);
  const swiperRef = useRef();
  const swiperRef1 = useRef();
  const swiperRef2 = useRef();
  const [currentContis, setCurrentContis] = useState([0, 1, 2, 3, 4, 5]);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [loader, setLoader] = useState(true);
  const [modalBox, setModalBox] = useState(false);
  const [allCategories, setAllCategories] = useState([]);



  const [quotes, setQuotes] = useState([
    {
      head: 'Excursion',
      quote: '"The world is a book, and those who do not travel read only a page." - Saint Augustine'
    },
    {
      head: 'Traveling',
      quote: '"Traveling – it leaves you speechless, then turns you into a storyteller." – Ibn Battuta'
    },
    {
      head: 'Journey',
      quote: '"The journey not the arrival matters." – T.S. Eliot'
    },
    {
      head: 'Flying',
      quote: '"Travel is fatal to prejudice, bigotry, and narrow-mindedness." – Mark Twain'
    },
    {
      head: 'Trip',
      quote: '"A good traveler has no fixed plans and is not intent on arriving." – Lao Tzu'
    },
    {
      head: 'Movement',
      quote: '"The world is too big to stay in one place and life is too short to do just one thing." - Anonymous'
    },
    {
      head: 'Escape',
      quote: '"I travel not to escape life, but for life not to escape me." – Anonymous'
    },
    {
      head: 'Discover',
      quote: 'To travel is to discover that everyone is wrong about other countries." – Aldous Huxley'
    },
    {
      head: 'Navigation',
      quote: '"Travel makes one modest. You see what a tiny place you occupy in the world." – Gustave Flaubert'
    },
    {
      head: 'Sightseeing',
      quote: '"Adventure is worthwhile." – Aesop'
    }
  ]);
  const [random, setRandom] = useState(Math.floor((Math.random() * quotes?.length)));
  const [mainBanner, setMainBanner] = useState([]);
  const [blogs, setBlogs] = useState([]);
  // const [random, setRandom] = useState(8)

  useEffect(() => {
    if (props?.popularData?.length > 0) {
      getPopulers(props?.popularData);
    }
  }, [props?.popularData]);

  useEffect(() => {
    const elem = document.getElementsByClassName("eapps-instagram-feed-container");
    if (elem?.length) {
      let itemToRemove = elem[0]?.nextElementSibling?.nextElementSibling?.nextElementSibling;
      if (itemToRemove)
        itemToRemove.style.display = 'none';
    }
  })

  useEffect(() => {
    get("/blog/random?num=9", {})
      .then(res => {
        if (res?.statusCode == 200) {
          setBlogs(res?.data);
        }
      })
    // get("/category/list", {})
    //   .then(res => {
    //     if (res?.statusCode == 200) {
    //       setAllCategories(res?.data);
    //     } else {
    //       toast.error("We are having a problem to load the blog, Please Try again later!")
    //     }
    //   })
    //   .catch(err => {
    //     console.log('error while getting blogs', err);
    //     toast.error("We are having a problem to load the blog, Please Try again later!")
    //   })
  }, []);

  useEffect(() => {
    // console.log('headerData @ Home', props?.headerData);
    if (props?.headerData) {
      setContis(props?.headerData?.destination);
      setAllCategories(props?.headerData?.blogCat);
      setWhos(props?.headerData?.whos);
      setExperience(props?.headerData?.experiences);
      setMonth(props?.headerData?.months);
    }
  }, [props?.headerData]);

  let Background = currentBanner?.banner;

  var sectionStyle = {
    width: "100%",
    height: "auto",
    backgroundAttachment: 'fixed',
    borderRadius: '1.5rem',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 85%',
    backgroundSize: 'cover',
    zIndex: 2
  };
  // console.log('Background', sectionStyle)

  const getPopulers = async (data) => {
    const delay = await localStorage?.getItem('popupDelay');
    const late = new Date().getTime();
    // console.log('popup need to show', Number(delay), late, !delay || late > Number(delay));
    if (!delay || late > Number(delay)) {
      setTimeout(() => {
        setModalBox(true);
      }, 1000);
    }
    setPopular(data);
    setCurrentBanner(data[Math.floor((Math.random() * data?.length))]);
    let myArray = [];
    let banner = [];
    for (let i = 0; i < 3; i++) {
      while (myArray?.length != 3) {
        let newRandomInt = Math.floor(Math.random() * data?.length);
        if (!myArray.includes(newRandomInt)) {
          myArray.push(newRandomInt);
          // console.log('main banner generated index', newRandomInt);
          banner.push(data[newRandomInt]);
        }
      }
    }
    setMainBanner(banner);
    setLoader(false);
    // console.log('main banner', banner);
    get("/ads/list?screen=home")
      .then(res => {
        // console.log('response from ads list', res)
        if (res?.statusCode == 200)
          setAds(res?.data);
      }).catch(err => {
        console.log('error while getting ads list', err);
      })
  }

  const handleProLike = (id) => {
    if (user) {
      const body = {
        token: user?.token,
        propertyId: id
      }
      post("/property/like", body)
        .then((res) => {
          // console.log('response from like property', );
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            let popularTemp = Object.assign([], popular);
            let findPopular = popularTemp.findIndex(x => x?._id == id);
            if (findPopular != -1) {
              const foundMe = popularTemp[findPopular]?.likes.findIndex(x => x == user?._id);
              console.log('found like and user', findPopular, foundMe);
              if (foundMe == -1)
                popularTemp[findPopular]?.likes.push(user?._id);
              else {
                popularTemp[findPopular]?.likes.splice(foundMe, 1);
              }
              setPopular(popularTemp);
            }
          } else
            toast.error(res?.error);
        })
        .catch(error => {
          console.log('error while liking property', error);
          toast.error('Something went wrong');
        })
    } else {
      navigate("/login");
    }
  }

  const compressId = (id) => {
    const temp = id.slice(id.length - 4, id.length);
    // console.log('compressing id', temp);
    return temp;
  }

  const openDestination = (item) => {
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
    window.open("/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" + parseName(item?.country[0]?.name) + "-" + parseName(item?.country[0]?.continent[0]?.name), "_blank");
  }

  const openConti = (item) => {
    window.open("/continent/" + compressId(item?._id) + "/top-travel-destinations-in-" + parseName(item?.name), "_blank");
  }

  const openWho = (item) => {
    window.open("/who/" + compressId(item?._id) + "/top-travel-destinations-for-" + parseName(item?.name), "_blank");
  }

  const openExp = (item) => {
    window.open("/experience/" + compressId(item?._id) + "/top-travel-destinations-in-" + parseName(item?.name), "_blank");
  }

  const openMonth = (item) => {
    window.open("/month/" + compressId(item?._id) + "/top-travel-destinations-in-" + parseName(item?.name), "_blank");
  }

  const parseName = (str) => {
    if (str) {
      let temp = str.split(",").join("");
      return temp.split(" ").join("-").toLowerCase();
    } else
      return "";
  }

  const openBlog = (item) => {
    return "/blog/" + compressId(item?._id) + "/" + parseName(item?.titleShort);
  }

  const openCat = (item) => {
    return '/blog-list/' + compressId(item?.category[0]._id) + "/blogs_by_" + parseName(item?.category[0]?.title)
  }
  const openCatDirect = (item) => {
    return '/blog-list/' + compressId(item?._id) + "/blogs_by_" + parseName(item?.title)
  }

  return (
    <>
      {
        loader && <Loader />
      }
      <Helmet>
        <title>Woow Destinations - Your Ultimate Travel Guide</title>
        <meta name="keyword" content="top destinations month experience group tour guides website" />
        <meta name="description" content="Looking for an adventure? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
        <meta name="dc.language" content="English" />
        <meta name="dc.source" content="http://www.woowdestinations.com" />
        <meta name="dc.title" content="Woow Destinations" />
        <meta name="dc.keywords" content="top destinations month experience group tour guides website" />
        <meta name="dc.description" content="Looking for an adventure? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
        <meta name="Author" content="Woow Destinations" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en-in" />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="http://www.woowdestinations.com" />
      </Helmet>
      {/**modal */}
      <div className={modalBox ? 'custome_popup active' : 'custome_popup'}>
        <div className={modalBox ? 'container_section active' : 'container_section'}>
          <div className='popup_left_box'>
            <img src={require('../src/assets/images/left.png')} alt="left.png" />
          </div>
          <div className='right_section_popup'>
            <button className='close_btn' onClick={() => { localStorage.setItem('popupDelay', (new Date().getTime() + 172800000)); setModalBox(false) }}>
              <img src={require('../src/assets/images/close.png')} alt="close.png" />
            </button>
            <h1>Don't miss out on the adventure of a
              lifetime - join our community of passionate travelers today!</h1>
            <p>Sign up for our email subscription to receive the latest travel tips, exclusive deals, and insider information on the world's most breathtaking woow destinations.</p>
            <form>
              <input type="email" name="" value="" placeholder='Email Address' />
              <button type="button">Subscribe now</button>
            </form>
            <h6>Subscribe to receive Latest emails from Woow Destinations.<br />
              Read our <Link to="#">Privacy Policy</Link>.
            </h6>
          </div>
        </div>
      </div>
      <div className={modalBox ? 'overflow active' : 'overflow'}>
      </div>
      {/* <Header setContis={setContis} setExperience={setExperience} setMonth={setMonth} setWhos={setWhos} /> */}
      <main id="rlr-main" className="rlr-main--fixed-top rlr_main_fixed_top_common">
        {/* <!-- Main Banner --> */}
        <aside className="rlr-hero--half-mast">
          <div className="container">
            <div id="" className=" rlr-banner-splide rlr-banner-splide--v3 ">
              <div className="splide__track rlr-banner-splide__track">

                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  // autoplay={{
                  //   delay: 2500,
                  //   disableOnInteraction: false,
                  // }}
                  pagination={{
                    clickable: true,
                  }}
                  LazyLoadImage
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper banner_slide "
                >
                  {mainBanner?.map((item, index) => (
                    <SwiperSlide style={{ cursor: 'pointer' }} key={item?._id}>
                      <li className="splide__slide rlr-banner-splide__slide ">
                        <div className="rlr-banner-splide__image-wrapper home_banner_section">
                          <div className="swiper-lazy-preloader">
                            <img src={require("./assets/images/loading.gif")} alt="loading" />
                          </div>
                          <img className="rlr-banner-splide__banner-img lazyload" loading="lazy" onClick={() => openDestination(item)} data-sizes="auto" data-src="./assets/images/banner/banner-03.jpg" src={item?.banner} alt={item.name} />
                        </div>
                        <article className="rlr-banner-splide__content-wrapper">
                          <header className="rlr-banner-splide__header">
                            <h2 className="rlr-banner-splide__slogan text_shadow">{item.name}</h2>
                            <span className="rlr-banner-splide__sub-title text_shadow">{item?.location} | {item?.country[0].name}</span>
                          </header>
                          <div className="rlr-banner-splide__content-desc">
                            <div className="rlr-banner-splide__temperature text_shadow">
                              <span>{item?.nights}</span>
                              <div className="rlr-banner-splide__arrows">
                                <button onClick={() => swiperRef.current?.slidePrev()} className="rlr-banner-splide__arrow rlr-banner-splide__arrow--prev rlr-banner-js-arrow-prev" aria-label="prev button">
                                  <span> <i className="rlr-icon-font flaticon-left"> </i> </span>
                                </button>
                                <button onClick={() => swiperRef.current?.slideNext()} className="rlr-banner-splide__arrow rlr-banner-splide__arrow--next rlr-banner-js-arrow-next" aria-label="next button">
                                  <span> <i className="rlr-icon-font flaticon-right"> </i> </span>
                                </button>
                              </div>
                            </div>
                            <div className="rlr-banner-splide__payment-option">
                              <div className="rlr-banner-splide__content-desc-right">
                                <span className="rlr-banner-splide__payment-desc">{item?.overview?.length > 80 ? item?.overview?.slice(0, 90) + "..." : item?.overview}</span>
                                <div onClick={() => openDestination(item)} className="btn rlr-button rlr-banner-splide__book-now" style={{ cursor: 'pointer' }}> Take me There </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </li>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </aside>
        <div className="container">

          <section className="rlr-section pt-5 mr-t5"></section>
        </div>
        {/* <!-- top trending destination --> */}
        <section className="rlr-section rlr-section__mb">
          <div className="container">
            {/* <!-- Swiper --> */}
            <div className="rlr-carousel__items">
              <div className=" rlr-js-product-card-swiper">
                {/* <!-- Carousel header --> */}
                <div className="rlr-section-header">
                  {/* <!-- Section heading --> */}
                  <div className="rlr-section__title">
                    <h2 className="rlr-section__title--main">Top Trending Destinations</h2>
                    <span className="rlr-section__title--sub">Experience the fusion of ancient culture and modern innovation in the top trending destinations.</span>
                  </div>
                  <div className="button-row">
                    <button type="button" onClick={() => swiperRef1.current?.slidePrev()} className="btn rlr-button button button--previous rlr-button--carousel">
                      <i className="rlr-icon-font flaticon-left-chevron"> </i>
                    </button>
                    <div className="button-group button-group--cells">
                      <button className="button is-selected">1</button>
                      <button className="button">2</button>
                    </div>
                    <button type="button" onClick={() => swiperRef1.current?.slideNext()} className="btn rlr-button button button--next rlr-button--carousel" >
                      <i className="rlr-icon-font flaticon-chevron"> </i>
                    </button>
                  </div>
                </div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  // centeredSlides={true}
                  // loop={true}
                  autoplay={{
                    // delay: 2500,
                    // disableOnInteraction: false,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                  }}
                  onBeforeInit={(swiper) => {
                    swiperRef1.current = swiper;
                  }}

                  modules={[Autoplay]}
                  className="mySwiper list_property list_property_paading"
                >
                  {popular?.map((item, index) => (
                    <SwiperSlide key={item?._id}>
                      <article className="rlr-product-card rlr-product-card--v3" itemScope >
                        {/* <!-- Product card image --> */}
                        <figure className="rlr-product-card__image-wrapper">
                          {/* <span className="rlr-badge rlr-badge-- rlr-badge--accent-red rlr-product-card__badge"> -20% Today </span> */}
                          <div className="rlr-product-detail-header__button-wrapper">
                            <button type="button" onClick={() => handleProLike(item?._id)} className={item?.likes && item?.likes?.findIndex(x => x == user?._id) != -1 ? "btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist active" : "btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist"} aria-label="Save to Wishlist"
                            >
                              <i className="rlr-icon-font flaticon-heart-1"> </i>
                            </button>
                            <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                          </div>
                          <div onClick={() => openDestination(item)} style={{ cursor: 'pointer' }} className="rlr-product-card__anchor-title">
                            <div className=" rlr-js-product-multi-image-swiper">
                              <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                // navigation={true}
                                modules={[Autoplay, Navigation]}
                                className="mySwiper list_property"
                              >
                                <SwiperSlide>
                                  <img itemprop="image" src={item?.banner} className="lazyload" alt={item?.banner} />
                                </SwiperSlide>
                                {item?.banners.map((ban) => (
                                  <SwiperSlide>
                                    <img itemprop="image" src={ban} className="lazyload" alt={ban} />
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                              {/* <button type="button" className="btn rlr-button splide__arrow splide__arrow--prev" aria-label="prev button">
                              <i className="rlr-icon-font flaticon-left-chevron"> </i>
                            </button>
                            <button type="button" className="btn rlr-button splide__arrow splide__arrow--next" aria-label="next button">
                              <i className="rlr-icon-font flaticon-chevron"> </i>
                            </button> */}
                            </div>
                          </div>
                        </figure>
                        <div onClick={() => openDestination(item)} style={{ cursor: 'pointer' }} className="rlr-product-card__anchor-title">
                          <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                            {/* <!-- Product card header --> */}
                            <header className="rlr-product-card__header">
                              <div>
                                <a className="rlr-product-card__anchor-title">
                                  <h2 className="rlr-product-card__title" itemprop="name">{item?.name}</h2>
                                </a>
                                <div>
                                  <a className="rlr-product-card__anchor-cat">
                                    <span className="rlr-product-card__sub-title">{item?.location} | {item?.country[0]?.name}</span>
                                  </a>
                                </div>
                              </div>
                            </header>
                            {/* <!-- Product card body --> */}
                            <div className="rlr-product-card__details">
                              <div className="rlr-product-card__prices" itemprop="offers" itemscope >
                                <span className="rlr-product-card__from">{item?.nights}</span>
                                {/* <span className="rlr-product-card__price"><mark itemprop="price">{item?.nights}</mark></span> */}
                              </div>
                              <div className="rlr-product-card__ratings" itemprop="aggregateRating" itemscope >
                                <div className="rlr-review-stars align-items-center" itemprop="ratingValue" itemscope >
                                  <StarRatings
                                    rating={item?.avgRating ? Number(item?.avgRating) : 0}
                                    starRatedColor={Utils.ratingColor}
                                    starHoverColor={Utils.ratingColor}
                                    numberOfStars={5}
                                    isSelectable={false}
                                    name='rating'
                                    starDimension={'20px'}
                                    starSpacing={'2px'}
                                  />
                                  {item?.reviews?.length > 0 &&
                                    <span className="rlr-product-card__from">({item?.reviews?.length}) Reviews</span>
                                  }
                                </div>
                                {/* <span className="rlr-product-card__rating-text" itemprop="reviewCount">4.7 (577)</span> */}
                              </div>
                            </div>

                            {/* <!-- Product card footer --> */}
                            {/* <div className="rlr-product-card__footer">
                            <div className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-three-o-clock-clock"> </i> <span className="">7 Days </span></div>
                            <ul className="rlr-product-card__icon-text-list">
                              <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-check"> </i> <span className="rlr-icon-text__title">New on Emprise </span></li>
                              <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-check"> </i> <span className="rlr-icon-text__title">Free Cancellation </span></li>
                            </ul>
                          </div> */}
                          </div>
                        </div>
                      </article>
                    </SwiperSlide>
                  ))}
                </Swiper>

              </div>
            </div>
          </div>
        </section>
        {/* <!-- Continents --> */}
        <section className="rlr-section rlr-section__mb">
          <div className="container">
            {/* <!-- Section heading --> */}
            <div className="rlr-section-header">
              <div className="rlr-section__title">
                <h2 className="rlr-section__title--main">Destination By Continents</h2>
                <span className="rlr-section__title--sub">Embark on an epic journey of discovery and adventure with our continent-wise tours, exploring the diverse cultures and natural wonders of each corner of the world.</span>
              </div>
              <div className="button-row">
                <Link target='_blank' className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"
                  to="/continent/top-travel-destinations-by-continents"
                > Check All </Link>
              </div>
            </div>
            <div className="rlr-masonary-grid__container rlr-masonary-grid__container_new">
              {contis?.length > 0 &&
                <div className="rlr-masonary-grid__one" style={{ cursor: 'pointer' }} onClick={() => openConti(contis[currentContis[0]])}>
                  <a className="rlr-destination-card" >
                    <div className='opacity_color'>
                      <img data-sizes="auto" src={contis[currentContis[0]].banner} data-srcset="./assets/images/dest/01@2x.jpg 2x" className="rlr-destination-card__img lazyload" alt={contis[currentContis[0]].banner} />
                    </div>
                    {contis[currentContis[0]].proInDes > 0 &&
                      < span className="rlr-badge rlr-badge--left rlr-badge-- rlr-badge--abs rlr-badge--abs-dest"> {contis[currentContis[0]].proInDes} Tours </span>
                    }
                    <div className="rlr-destination-card__info rlr-destination-card__info--left rlr-destination-card__info--bottom">
                      <h2 className="rlr-destination-card__info--main">{contis[currentContis[0]]?.name}</h2>
                      <p className="rlr-destination-card__info--sub">{contis[currentContis[0]]?.countries?.length} - {contis[currentContis[0]]?.countries?.length > 1 ? 'Countries' : 'Country'}</p>
                    </div>
                  </a>
                </div>
              }
              {contis?.length > 1 &&
                <div className="rlr-masonary-grid__two" style={{ cursor: 'pointer' }} onClick={() => openConti(contis[currentContis[1]])}>
                  <a className="rlr-destination-card" >
                    <div className='opacity_color'>
                      <img data-sizes="auto" src={contis[currentContis[1]].banner} data-srcset="./assets/images/dest/02@2x.jpg 2x" className="rlr-destination-card__img lazyload" alt={contis[currentContis[1]].banner} />
                    </div>
                    {contis[currentContis[1]].proInDes > 0 &&
                      < span className="rlr-badge rlr-badge--left rlr-badge-- rlr-badge--abs rlr-badge--abs-dest"> {contis[currentContis[1]].proInDes} Tours </span>
                    }
                    <div className="rlr-destination-card__info rlr-destination-card__info--left rlr-destination-card__info--bottom">
                      <h2 className="rlr-destination-card__info--main">{contis[currentContis[1]]?.name}</h2>
                      <p className="rlr-destination-card__info--sub">{contis[currentContis[1]]?.countries?.length} - {contis[currentContis[1]]?.countries?.length > 1 ? 'Countries' : 'Country'}</p>
                    </div>
                  </a>
                </div>
              }
              {contis?.length > 2 &&
                <div className="rlr-masonary-grid__three" style={{ cursor: 'pointer' }} onClick={() => openConti(contis[currentContis[2]])}>
                  <a className="rlr-destination-card" >
                    <div className='opacity_color'>
                      <img data-sizes="auto" src={contis[currentContis[2]]?.banner} data-srcset="./assets/images/dest/03@2x.jpg 2x" className="rlr-destination-card__img lazyload big_img_card" alt={contis[currentContis[2]].banner} />
                    </div>
                    {contis[currentContis[2]].proInDes > 0 &&
                      < span className="rlr-badge rlr-badge--left rlr-badge-- rlr-badge--abs rlr-badge--abs-dest"> {contis[currentContis[2]].proInDes} Tours </span>
                    }
                    <div className="rlr-destination-card__info rlr-destination-card__info--left rlr-destination-card__info--bottom">
                      <h2 className="rlr-destination-card__info--main">{contis[currentContis[2]]?.name}</h2>
                      <p className="rlr-destination-card__info--sub">{contis[currentContis[2]]?.countries?.length} - {contis[currentContis[2]]?.countries?.length > 1 ? 'Countries' : 'Country'}</p>
                    </div>
                  </a>
                </div>
              }
              {contis?.length > 3 &&
                <div className="rlr-masonary-grid__four" style={{ cursor: 'pointer' }} onClick={() => openConti(contis[currentContis[3]])}>
                  <a className="rlr-destination-card" >
                    <div className='opacity_color'>
                      <img data-sizes="auto" src={contis[currentContis[3]]?.banner} data-srcset="./assets/images/dest/04@2x.jpg 2x" className="rlr-destination-card__img lazyload" alt={contis[currentContis[3]].banner} />
                    </div>
                    {contis[currentContis[3]].proInDes > 0 &&
                      < span className="rlr-badge rlr-badge--left rlr-badge-- rlr-badge--abs rlr-badge--abs-dest"> {contis[currentContis[3]].proInDes} Tours </span>
                    }
                    <div className="rlr-destination-card__info rlr-destination-card__info--left rlr-destination-card__info--bottom">
                      <h2 className="rlr-destination-card__info--main">{contis[currentContis[3]]?.name}</h2>
                      <p className="rlr-destination-card__info--sub">{contis[currentContis[3]]?.countries?.length} - {contis[currentContis[3]]?.countries?.length > 1 ? 'Countries' : 'Country'}</p>
                    </div>
                  </a>
                </div>
              }
              {contis?.length > 4 &&
                <div className="rlr-masonary-grid__five" style={{ cursor: 'pointer' }} onClick={() => openConti(contis[currentContis[4]])}>
                  <a className="rlr-destination-card" >
                    <div className='opacity_color'>
                      <img data-sizes="auto" src={contis[currentContis[4]]?.banner} data-srcset="./assets/images/dest/05@2x.jpg 2x" className="rlr-destination-card__img lazyload" alt={contis[currentContis[4]].banner} />
                    </div>
                    {contis[currentContis[4]].proInDes > 0 &&
                      < span className="rlr-badge rlr-badge--left rlr-badge-- rlr-badge--abs rlr-badge--abs-dest"> {contis[currentContis[4]].proInDes} Tours </span>
                    }
                    <div className="rlr-destination-card__info rlr-destination-card__info--left rlr-destination-card__info--bottom">
                      <h2 className="rlr-destination-card__info--main">{contis[currentContis[4]]?.name}</h2>
                      <p className="rlr-destination-card__info--sub">{contis[currentContis[4]]?.countries?.length} - {contis[currentContis[4]]?.countries?.length > 1 ? 'Countries' : 'Country'}</p>
                    </div>
                  </a>
                </div>
              }
              {contis?.length > 5 &&
                <div className="rlr-masonary-grid__six" style={{ cursor: 'pointer' }} onClick={() => openConti(contis[currentContis[5]])}>
                  <a className="rlr-destination-card" >
                    <div className='opacity_color'>
                      <img data-sizes="auto" src={contis[currentContis[5]]?.banner} data-srcset="./assets/images/dest/06@2x.jpg 2x" className="rlr-destination-card__img lazyload" alt={contis[currentContis[5]].banner} />
                    </div>
                    {contis[currentContis[5]].proInDes > 0 &&
                      < span className="rlr-badge rlr-badge--left rlr-badge-- rlr-badge--abs rlr-badge--abs-dest"> {contis[currentContis[5]].proInDes} Tours </span>
                    }
                    <div className="rlr-destination-card__info rlr-destination-card__info--left rlr-destination-card__info--bottom">
                      <h2 className="rlr-destination-card__info--main">{contis[currentContis[5]]?.name}</h2>
                      <p className="rlr-destination-card__info--sub">{contis[currentContis[5]]?.countries?.length} - {contis[currentContis[5]]?.countries?.length > 1 ? 'Countries' : 'Country'}</p>
                    </div>
                  </a>
                </div>
              }
            </div>
          </div>
        </section>
        {/* <!-- who travels --> */}
        <section className="rlr-section rlr-section__mb ">
          <img src={require('../src/assets/images/pattern_2.png')} alt="pattern_2.png" className='select_category' />
          <div className="container padding_top" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="rlr-carousel__items">
              <div className=" rlr-js-category-card-swiper">
                {/* <div className="rlr-section-header">
                  <div className="rlr-section__title">
                    <h2 className="rlr-section__title--main">Who Travel ?</h2>
                    <span className="rlr-section__title--sub">Activities related to moving from one place to another for various purposes such as leisure, business, education, or personal reasons, including transportation, accommodation, and tourism services.</span>
                  </div>
                  <div className="button-row">
                    <button type="button" onClick={() => swiperRef2.current?.slidePrev()} className="btn rlr-button button button--previous rlr-button--carousel" >
                      <i className="rlr-icon-font flaticon-left-chevron"> </i>
                    </button>
                    <div className="button-group button-group--cells">
                      <button className="button is-selected">1</button>
                      <button className="button">2</button>
                    </div>
                    <button type="button" onClick={() => swiperRef2.current?.slideNext()} className="btn rlr-button button button--next rlr-button--carousel" >
                      <i className="rlr-icon-font flaticon-chevron"> </i>
                    </button>
                  </div>
                </div> */}
                <div className='container-fluid p-0'>
                  <div className='row'>
                    <div className='col-lg-5'>
                      <div className='who_left_section'>

                      </div>
                    </div>
                    <div className='col-lg-7'>
                      <div className='who_right_section'>
                        <div className="rlr-section-header">
                          <div className="rlr-section__title">
                            <h2 className="rlr-section__title--main">Who is Traveling ?</h2>
                            <span className="rlr-section__title--sub">Activities related to moving from one place to another for various purposes such as leisure, business, education, or personal reasons.</span>
                          </div>
                          <div className="button-row">
                            <button type="button" onClick={() => swiperRef2.current?.slidePrev()} className="btn rlr-button button button--previous rlr-button--carousel" >
                              <i className="rlr-icon-font flaticon-left-chevron"> </i>
                            </button>
                            <div className="button-group button-group--cells">
                              <button className="button is-selected">1</button>
                              <button className="button">2</button>
                            </div>
                            <button type="button" onClick={() => swiperRef2.current?.slideNext()} className="btn rlr-button button button--next rlr-button--carousel" >
                              <i className="rlr-icon-font flaticon-chevron"> </i>
                            </button>
                          </div>
                        </div>
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={10}
                          // centeredSlides={true}
                          // loop={true}
                          // autoplay={{
                          //   delay: 2500,
                          //   disableOnInteraction: false,
                          // }}
                          breakpoints={{
                            640: {
                              slidesPerView: 2.8,
                              spaceBetween: 10,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 10,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 10,
                            },
                          }}


                          onBeforeInit={(swiper) => {
                            swiperRef2.current = swiper;
                          }}
                          modules={[Autoplay, Navigation]}
                          className="mySwiper list_property"
                        >
                          {whos?.map((item, index) => (
                            <SwiperSlide onClick={() => openWho(item)} style={{ cursor: 'pointer' }} key={item?._id}>
                              <article className="rlr-category-card rlr_category_card_new">
                                <img itemprop="image" src={item?.banner} className="rlr_category_card_new_img" alt={item?.banner} />
                                {/* <div className="rlr-category-card__badge rlr-category-card__badge_new">
                          <span className="rlr-badge rlr-badge--right rlr-badge--"> 4.9 </span>
                        </div> */}
                                <header>
                                  <div className="rlr-category-card__title">
                                    <h4>{item?.name}</h4>
                                  </div>
                                  <div className="rlr-category-card__sub-title">
                                    <span>{item?.properties?.length} Locations</span>
                                  </div>
                                </header>
                              </article>
                            </SwiperSlide>
                          )
                          )}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
                {ads.map((item) => {
                  if (item?.position == 'top')
                    return (
                      <div className='addSection' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={item?._id}>
                        <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: "auto", height: 'auto' }} /></a>
                      </div>
                    )
                })}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Experience --> */}
        <section className="rlr-section rlr-section__mb rlr-section__cards rlr-section__cards_new pb_0_m">
          <div className="container">
            {/* <!-- Section heading --> */}
            <div className="rlr-section-header">
              {/* <!-- Section heading --> */}
              <div className="rlr-section__title">
                <h2 className="rlr-section__title--main">Destination By Experiences</h2>
                <span className="rlr-section__title--sub">Create unforgettable memories and enrich your life with our immersive tours that offer unique and authentic experiences, showcasing the best of each destination.</span>
              </div>
              <div className="button-row">
                <Link target='_blank' className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"
                  to="/experience/top-travel-destinations-by-experience"
                > Check All </Link>
              </div>
            </div>
            <div className="row rlr-featured__cards rlr-masonary-grid__container_new pb-0">
              {experience?.map((item) => (
                <div className="col-md-6 col-lg-4" key={item?._id} style={{ cursor: 'pointer' }} onClick={() => openExp(item)} data-aos="fade-up" data-aos-offset="250" data-aos-duration="700">
                  {/* <!-- Featured prodcut card --> */}
                  <article className="rlr-product-card rlr-product-card--featured" itemscope >
                    {/* <!-- Image --> */}
                    <figure className="rlr-product-card__image-wrapper">
                      <img itemprop="image" src={item?.banner} data-sizes="auto" className="lazyload " alt={item?.name} />
                    </figure>
                    {/* <!-- Card body --> */}
                    <div className="rlr-product-card--featured__inner">
                      {/* <span className="rlr-badge rlr-badge--right rlr-badge-- rlr-badge--abs rlr-badge--abs-right"> 4.9 </span> */}
                      <div className="rlr-product-card--featured__body rlr-product-card--featured__body_home card-img-overlay">
                        <div className="rlr-product-card--featured__duration">
                          <p className="body">{item?.properties?.length} Locations</p>
                          {/* <h4 className="type-h4">100 Locations</h4> */}
                        </div>
                        <div className="rlr-product-detail-header__actions">
                          <div className="rlr-product-detail-header__button-wrapper">
                            {/* <button type="button" className="btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--no-bg rlr-wishlist-button rlr-js-action-wishlist" aria-label="Save to Wishlist">
                              <i className="rlr-icon-font flaticon-heart-1"> </i>
                            </button> */}
                            <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                          </div>
                          <div className="btn rlr-button product-card-buttonrlr-button--medium rlr-button--rounded rlr-button--brand"> Explore </div>
                        </div>
                      </div>
                    </div>

                  </article>
                  {/* <!-- Summary --> */}
                  <div className="rlr-product-card--featured__summary">
                    <h4 className="type-h6-semi-bold mb-0 pb-0">{item?.name}</h4>
                    {/* <p className="type-body">{item?.desc?.length > 80 ? item?.desc?.slice(0, 80) + "..." : item?.desc}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <div className='destination-video rlr-section  rlr-section__mb mb-0'>
          <video autoPlay={true} loop={true} playsinline muted src={require('./assets/images/destination-video.mp4')} type="video/mp4">
            {/* <source src={require('./assets/images/balloon.mp4')} type="video/mp4"  /> */}
          </video>
          <div className='video_detail_box'>
            <div className='video_detail_section'>
              {/* <h4>Get in travel</h4> */}
              <h1>{quotes[random]?.head}</h1>
              <p>{quotes[random]?.quote}</p>
            </div>
          </div>
        </div>
        {/**marquee */}
        <div className="marquee">
          <div className="track">
            <div className="content">{month.map((item) => (item?.name + "  .    "))}</div>
          </div>
        </div>
        {/* <!-- Best Month--> */}
        <section className="rlr-section rlr-section__mb rlr-section__cards rlr-section__mb_bottom mb_0_m mb-0">
          <div className="container">
            {/* <!-- Section heading --> */}
            <div className="rlr-section-header">
              {/* <!-- Section heading --> */}
              <div className="rlr-section__title">
                <h2 className="rlr-section__title--main">Destination By Months</h2>
                <span className="rlr-section__title--sub">Make the most of each season and discover the hidden gems of each destination with our month-wise tours, tailored to provide an unforgettable travel experience throughout the year.</span>
              </div>
              <div className="button-row">
                <Link target='_blank' to="/month/top-travel-destinations-by-months" className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
              </div>
            </div>
            <div className="row rlr-featured__cards rlr-masonary-grid__container_new ">
              {month?.map((item) => (
                <div className="col-md-6 col-lg-3" style={{ cursor: 'pointer' }} onClick={() => openMonth(item)} key={item?._id}>
                  {/* <!-- Featured prodcut card --> */}
                  <article className="rlr-product-card rlr-product-card--featured" itemscope >
                    {/* <!-- Image --> */}
                    <figure className="rlr-product-card__image-wrapper  rlr-product-card__image-wrapper_new opacity_color ">
                      <img itemprop="image" src={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                    </figure>
                    {/* <!-- Card body --> */}
                    <div className="rlr-product-card--featured__inner">
                      {/* <span className="rlr-badge rlr-badge--right rlr-badge-- rlr-badge--abs rlr-badge--abs-right"> 4.9 </span> */}
                      <div className="rlr-product-card--featured__body justify-content-center card-img-overlay">
                        <div className="rlr-product-card--featured__duration">
                          <p className="body">{item?.properties?.length} Locations</p>
                          {/* <h4 className="type-h4">100 Locations</h4> */}
                        </div>
                        <div className="rlr-product-detail-header__actions">
                          <div className="rlr-product-detail-header__button-wrapper">
                            {/* <button type="button" className="btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--no-bg rlr-wishlist-button rlr-js-action-wishlist" aria-label="Save to Wishlist">
                              <i className="rlr-icon-font flaticon-heart-1"> </i>
                            </button> */}
                            <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                          </div>
                          {/* <a href="./product-detail-page.html" className="btn rlr-button product-card-buttonrlr-button--medium rlr-button--rounded rlr-button--brand"> Explore </a> */}
                        </div>
                      </div>
                    </div>
                    {/* <a href="./product-detail-page.html" className="rlr-product-card__anchor rlr-product-card__anchor--featured"></a> */}
                  </article>
                  {/* <!-- Summary --> */}
                  <div className="rlr-product-card--featured__summary featured__summary_new">
                    <h4 className="type-h6-semi-bold text-uppercase">{item?.name}</h4>
                    {/* <p className="type-body">{item?.desc?.length > 80 ? item?.desc?.slice(0, 80) + "..." : item?.desc}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {blogs?.length > 0 &&
          <section className="top-home-section top_home_section pt-0 pb-4 mb-3 mt-0">
            <div className="container">
              <div className="rlr-section-header">
                {/* <!-- Section heading --> */}
                <div className="rlr-section__title">
                  <h2 className="rlr-section__title--main">Travel Diaries</h2>
                  <span className="rlr-section__title--sub">Inspiring Journeys and Captivating Adventures Around the Globe.</span>
                </div>
                <div className="button-row">
                  <Link to="/blog" target='_blank' className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
                </div>
              </div>
              <div className="top-home-box top-home-box-common-overlay">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="news-post image-post image_post_blogdetail image_post_blogdetail_one">
                      <img src={blogs[0]?.image} alt={blogs[0]?.titleShort} />
                      <div className="hover-post">
                        {/* <Link className="category-link" to={openCat(blogs[0])}>{blogs[0]?.category[0]?.title}</Link> */}
                        {/* <h2><Link to={openBlog(blogs[0])}>{blogs[0]?.titleShort}</Link></h2> */}
                        <h2><Link target='_blank' to={openBlog(blogs[0])}>{blogs[0]?.titleShort?.length > 95 ? blogs[0]?.titleShort?.substring(0, 95) + "..." : blogs[0]?.titleShort}</Link></h2>
                        <ul className="post-tags">
                          <li>{blogs[0]?.createdAt}</li>
                          <li><Link to="#">{blogs[0]?.reviews?.length} comments</Link></li>
                        </ul>
                      </div>
                      <div className='overlay'></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="row">
                      {blogs?.length > 1 &&
                        <div className="col-md-6">
                          <div className="news-post standard-post left-align standard_post_left_align">
                            <div className="image-holder">
                              <img src={blogs[1]?.image} alt={blogs[1]?.titleShort} />
                              <div className='overlay'></div>
                            </div>
                            {/* <Link className="text-link" to={openCat(blogs[1])}>{blogs[1]?.category[0]?.title}</Link> */}
                            {/* <h2><Link to={openBlog(blogs[1])}>{blogs[1]?.titleShort}</Link></h2> */}
                            <h2><Link target='_blank' to={openBlog(blogs[1])}>{blogs[1]?.titleShort?.length > 40 ? blogs[1]?.titleShort?.substring(0, 40) + "..." : blogs[1]?.titleShort}</Link></h2>
                            <ul className="post-tags">
                              <li>by {blogs[1]?.author[0]?.title}</li>
                              <li>{blogs[1]?.createdAt}</li>
                            </ul>

                          </div>
                        </div>
                      }
                      {blogs?.length > 2 &&
                        <div className="col-md-6">
                          <div className="news-post standard-post left-align standard_post_left_align">
                            <div className="image-holder">
                              <img src={blogs[2]?.image} alt={blogs[1]?.titleShort} />
                              <div className='overlay'></div>
                            </div>
                            {/* <Link className="text-link" to={openCat(blogs[2])}>{blogs[2]?.category[0]?.title}</Link> */}
                            {/* <h2><Link to={openBlog(blogs[2])}>{blogs[2]?.titleShort}</Link></h2> */}
                            <h2><Link target='_blank' to={openBlog(blogs[2])}>{blogs[2]?.titleShort?.length > 40 ? blogs[2]?.titleShort?.substring(0, 40) + "..." : blogs[2]?.titleShort}</Link></h2>
                            <ul className="post-tags">
                              <li>by {blogs[2]?.author[0]?.title}</li>
                              <li>{blogs[2]?.createdAt}</li>
                            </ul>

                          </div>
                        </div>
                      }
                    </div>
                    {blogs?.length > 3 &&
                      <div className="news-post image-post image_post_blogdetail image_post_blogdetail_two image_post_blogdetail_two_m">
                        <img src={blogs[3]?.image} alt={blogs[3]?.titleShort} />
                        <div className="hover-post">
                          {/* <Link className="category-link" to={openCat(blogs[3])}>{blogs[3]?.category[0]?.title}</Link> */}
                          {/* <h2><Link to={openBlog(blogs[3])}>{blogs[3]?.titleShort}</Link></h2> */}
                          <h2><Link target='_blank' to={openBlog(blogs[3])}>{blogs[3]?.titleShort?.length > 80 ? blogs[3]?.titleShort?.substring(0, 80) + "..." : blogs[3]?.titleShort}</Link></h2>
                          <ul className="post-tags">
                            <li>by {blogs[3]?.author[0]?.title}</li>
                            <li>{blogs[3]?.createdAt}</li>
                          </ul>
                        </div>
                        <div className='overlay'></div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
        <section id="features" className="rlr-section rlr-section__mb landding__plugin">
          <div className="container">
            {ads.map((item) => {
              if (item?.position == 'bottom')
                return (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} alt={item?.banner} /></a>
                  </div>
                )
            })}
          </div>
        </section>
        <section class="fresh-section2  p-0">
          <div class="container">
            <div className="rlr-section-header">
              {/* <!-- Section heading --> */}
              <div className="rlr-section__title">
                <h2 className="rlr-section__title--main">Also check</h2>

              </div>
              <div className="button-row">
                <Link target='_blank' to="/blog" className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
              </div>
            </div>

            <div class="fresh-box pt-1">
              <div class="row">

                <div class="col-lg-12 col-md-12">

                  <ul class="list-thumb-posts list_thumb_posts">
                    {blogs?.map((item, index) => {
                      if (index > 3)
                        return (
                          <li className='list_'>
                            <div class="image-holder image_holder_com"><img src={item?.image} alt={item?.titleShort} /></div>
                            <div class="list-post-content list_post_content">

                              <h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
                              <div>
                                {item?.category?.map((item1) => {
                                  return (
                                    <Link class="text-link text_link_" target='_blank' to={openCatDirect(item1)}>{item1?.title}</Link>
                                  )
                                })}
                              </div>
                              <ul class="post-tags">
                                <li>{item?.createdAt}</li>
                                <li>{item?.reviews?.length} comments</li>
                              </ul>
                            </div>
                          </li>
                        )
                    })}
                  </ul>
                  <div className='load_mod pt-4'>
                    <Link class="button-one" to={"/blog"}>Load More</Link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section class="fresh-section2 rlr-section rlr-section__mb rlr-section__cards rlr-section__mb_bottom mt-2 pb-0 ">
          <div class="container">
            <div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
              {/* <!-- Section heading --> */}
              <div className="rlr-section__title">
                <h2 className="rlr-section__title--main text-center">Blog by category</h2>

              </div>

            </div>

            <div className="widget category-widget">

              <ul className="category-list list_posts_tag list_posts_tag_d">
                {
                  allCategories?.map((item, index) => {
                    return (
                      <Link target='_blank' key={index} to={openCatDirect(item)}>
                        {item?.title}
                      </Link>
                    )
                  })
                }

              </ul>
            </div>
          </div>
        </section>
        {/* <!-- Ads --> */}

        {/* <ins class="adsbygoogle"
          style={{"display":"block"}}
          data-ad-client="ca-pub-7101051898267419"
          data-ad-slot="5018619581"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins> */}
        {/* <AdSense.Google
          client='ca-pub-7101051898267419'
          slot='5018619581'
          responsive='true'
        /> */}

        {/* <section id="features" className="rlr-section rlr-section__mb landding__plugin mb-0">
          <div className='visite_card container'>
            <div class="elfsight-app-082634f7-6d9a-4210-88ee-ab7973fd29b0"></div>
          </div>
        </section> */}
      </main >
    </>
  )
}
