import React, { useState, useEffect } from 'react'
import { get, post } from './helper/helper_api';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { login } from './store/auth/login/reducer';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';

export default function (props) {

  let navigate = useNavigate();
  let location = useLocation();
  // const [stickyClass, setStickyClass] = useState('relative');
  const [toggle, setToggle] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [destination, setDestination] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [months, setMonths] = useState([]);
  const [whos, setWhos] = useState([]);
  const [active, setActive] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dispatch = useDispatch();
  const [toasts, setToasts] = useState([]);
  const [currentY, setCurrentY] = useState(0);
  const [currentLY, setCurrentLY] = useState(0);
  const [stickyClass, setStickyClass] = useState("slideDown");
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [currentTab, setCurrentTab] = useState("Home");
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleMenuTwo, setToggleMenuTwo] = useState(false);
  const [experiencesMenu, setExperiencesMenu] = useState(false);
  const [whoMenu, setWhoMenu] = useState(false);
  const [monthsMenu, setMonthsMenu] = useState(false);
  const [popularData, setPopularData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      let tab = location.pathname.split("/")[1];
      if (tab == "blog" || tab == 'blog-list')
        setCurrentTab("blog");
      else if (tab == "continent" || tab == 'country' || tab == 'destination' || tab == 'hotels')
        setCurrentTab("destination");
      else if (tab == "experience")
        setCurrentTab("experience");
      else if (tab == "month")
        setCurrentTab("month");
      else if (tab == "who")
        setCurrentTab("who");
      else
        setCurrentTab("home");
    }
  }, [location]);

  const getData = async () => {
    const user = JSON.parse(await localStorage.getItem("user"));
    dispatch(login(user));
    get('/property/header').then((json) => {
      if (json?.statusCode == 200) {
        // console.log('destination category', json?.data?.destination);
        if (props?.setHeaderData)
          props?.setHeaderData(json?.data);
        setDestination(json?.data?.destination);
        if (props?.setContis) {
          let arr = Object.assign([], json?.data?.destination);
          shuffle(arr);
        }
        setExperiences(json?.data?.experiences);
        if (props?.setExperience)
          props?.setExperience(json?.data?.experiences);
        setWhos(json?.data?.whos);
        if (props?.setWhos)
          props?.setWhos(json?.data?.whos);
        setMonths(json?.data?.months);
        if (props?.setMonth)
          props?.setMonth(json?.data?.months);
      }

    }).catch((err) => {
      console.log(err);
    });
    let body = {
      isPopular: true
    }
    post('/property/filter/list', body)
      .then((res) => {
        // console.log('response form getting popular property', res);
        if (res?.statusCode == 200) {
          let popular = res?.data?.properties;
          props?.setPopularData(popular);
          // if (popular?.length > 0) {
          //   let data = [];
          //   popular?.map((item) => {
          //     item?.reviews?.map((rev) => {
          //       if (rev?.user?.length > 0) {
          //         data?.push({ name: rev?.user[0]?.name, title: rev?.name, type: 'review', prop: item?.name });
          //       }
          //     });
          //     item?.user?.map((like) => {
          //       data?.push({ name: like?.name, type: 'like', prop: item?.name });
          //     })
          //   });
          //   setToasts(data);
          // }
        }
      })
      .catch(error => {
        console.log('error while getting popular', error);
      })
  }

  useEffect(() => {
    if (toasts?.length > 0) {
      const interval = setInterval(() => {
        let currentItem = toasts?.[Math.floor((Math.random() * toasts?.length))];
        // console.log('reviews to toast', toasts, currentItem);
        let toastId = 'update';
        if (!toast.isActive(toastId)) {
          if (currentItem?.type == 'review')
            toastId = toast.success(currentItem?.name + " " + currentItem?.type + " " + currentItem?.title + " to " + currentItem?.prop, {
              toastId: toastId
            });
          else
            toast.success(currentItem?.name + " " + currentItem?.type + " " + currentItem?.prop, {
              toastId: toastId
            });
        }
      }, 5 * 60000);
      return () => clearTimeout(interval);
    }
  }, [toasts]);

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    props?.setContis(array);
  }

  const openConti = (item) => {
    setIsSubMenu(true);
    setToggleMenu(false);
    setToggleMenuTwo(false);
    const tempId = item?._id.slice(item?._id.length - 4, item?._id.length);
    navigate('/continent/' + tempId + "/" + "top-travel-destinations-in-" + (item?.name).split(" ").join("-").toLowerCase());
  }

  const openCountry = (id, conti, name) => {
    setIsSubMenu(true);
    setToggleMenu(false);
    setToggleMenuTwo(false);
    const tempId = id.slice(id.length - 4, id.length);
    navigate('/country/' + tempId + "/top-travel-destinations-in-" + (conti).split(" ").join("-").toLowerCase() + "-" + (name).toLowerCase());
  }

  const openExp = (item) => {
    setIsSubMenu(true);
    setToggleMenu(false);
    setExperiencesMenu(false);
    const tempId = item?._id.slice(item?._id.length - 4, item?._id.length);
    navigate('/experience/' + tempId + "/" + "top-travel-destinations-in-" + (item?.name).split(" ").join("-").toLowerCase());
  }

  const openMonth = (item) => {
    setIsSubMenu(true);
    setToggleMenu(false);
    setMonthsMenu(false);
    const tempId = item?._id.slice(item?._id.length - 4, item?._id.length);
    navigate('/month/' + tempId + "/" + "top-travel-destinations-in-" + (item?.name).split(" ").join("-").toLowerCase());
  }

  const openWho = (item) => {
    setIsSubMenu(true);
    setToggleMenu(false);
    setWhoMenu(false);
    const tempId = item?._id.slice(item?._id.length - 4, item?._id.length);
    navigate('/who/' + tempId + "/" + "top-travel-destinations-for-" + (item?.name).split(" ").join("-").toLowerCase());
  }

  useEffect(() => {
    if (currentLY < currentY) {
      setCurrentLY(currentY);
      setStickyClass('slideUp');
    } else {
      setStickyClass('slideDown');
      setCurrentLY(currentY);
    }
    // console.log('current height', currentY, currentLY);
  }, [currentY]);

  const stickNavbar = () => {
    let windowHeight = window.scrollY;
    setCurrentY(windowHeight);
    // console.log('windowHeight', currentY, windowHeight);
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch(login(null));
  }

  const loginWithGoogle = (credentialResponse) => {
    const loginData = jwtDecode(credentialResponse.credential);
    const body = {
      name: loginData?.name,
      email: loginData?.email,
      profile_picture: loginData?.picture,
    }
    post('/loginwgoogle', body)
      .then((res => {
        if (res?.statusCode == 200) {
          console.log('request on login', res?.data);
          localStorage.setItem("user", JSON.stringify(res?.data));
          dispatch(login(res?.data));
          // navigate(-1);
        } else {
          toast.error(res?.error);
        }
      }))
      .catch(error => {
        console.log('error while login', error);
        toast.error('Something went wrong');
      })
  }

  return (
    <>
      {/* <Helmet> */}
      {/* <script src="./vendors/navx/js/navigation.min.js"></script> */}
      {/* <script src="./js/main.js"></script> */}
      {/* </Helmet> */}
      <div className='google_login'>
      {!user &&
        <GoogleLogin
          width={'100vw'}
          size={'large'}
          useOneTap
          onSuccess={loginWithGoogle}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      }
      </div>
      
      <header>
        <nav id="navigation" className={`animated 1navigation rlr-navigation default-nav fixed-top ${stickyClass}`}>
          <div className="navigation-header">
            <div className="navigation-brand-text">
              <div className="rlr-logo rlr-logo__navbar-brand rlr-logo--default">
                <Link to="/">
                  <img src={require('../src/assets/images/woow-destination.png')} alt="woow-destination.png" className="" />
                </Link>
              </div>
            </div>
            <div className="navigation-button-toggler" onClick={() => setToggleMenu(true)}>
              <span className="rlr-sVGIcon"> <i className="rlr-icon-font rlr-icon-font--megamenu flaticon-menu"> </i> </span>
            </div>
          </div>
          <div className={toggleMenu ? 'navigation-body rlr-navigation__body container active' : 'navigation-body rlr-navigation__body container'}>
            <div className="navigation-body-header rlr-navigation__body-header">
              <div className="navigation-brand-text">
                <div className="rlr-logo rlr-logo__navbar-brand rlr-logo--default">
                  <Link to="/" onClick={() => setToggleMenu(false)}>
                    <img src={require('../src/assets/images/woow-destination.png')} alt="woow-destination.png" className="" />
                  </Link>
                </div>
              </div>
              <span className="rlr-sVGIcon navigation-body-close-button" onClick={() => setToggleMenu(false)}> <i className="rlr-icon-font rlr-icon-font--megamenu flaticon-close"> </i> </span>
            </div>
            <ul className="navigation-menu rlr-navigation__menu rlr-navigation__menu--main-links">
              <li className={currentTab == 'home' ? 'navigation-item is-active' : "navigation-item"}>
                <Link className="navigation-link" to="/" onClick={() => setToggleMenu(false)}>Home</Link>
              </li>
              <li className={currentTab == 'destination' ? 'navigation-item is-active has-submenu' : "navigation-item has-submenu"} onMouseEnter={() => setToggleMenuTwo(true)} onClick={() => setToggleMenuTwo(!toggleMenuTwo)}>
                <Link className="navigation-link" to="/continent/top-travel-destinations-by-continents" onClick={() => { setToggleMenuTwo(!toggleMenuTwo); setToggleMenu(false) }}>Destination</Link>
                <button className='mobile_menu_ext_btn' onClick={() => { setToggleMenuTwo(!toggleMenuTwo) }}>+</button>
                <ul className={`navigation-megamenu navigation-submenu navigation-megamenu-half ${toggleMenuTwo ? 'active' : 'menuhidden'}`}>
                  <li className="navigation-megamenu-container">
                    <ul className="navigation-tabs">
                      <li className="rlr-navigation__tabbed-list">
                        <ul className="navigation-tabs-nav">
                          {
                            destination.map((item, index) => {
                              // console.log(item.countries)
                              return (
                                <li className={active == index ? 'navigation-tabs-nav-item is-active' : 'navigation-tabs-nav-item'}>
                                  <a onMouseEnter={() => setActive(index)} onClick={() => openConti(item)}>{item?.name}</a>
                                </li>
                              )
                            })
                          }

                        </ul>
                      </li>
                      {
                        destination?.map((item, index) => {
                          // console.log("item.name", item.countries.capital)
                          return (
                            <li className={active == index ? 'navigation-tabs-pane is-active' : 'navigation-tabs-pane'}>
                              <ul className="navigation-row">
                                {
                                  item.countries?.map((item1) => {
                                    // console.log('item1', item1);
                                    // mostPop.push({ banner: item1?.banner, desc: item1?.desc });
                                    return (
                                      <>
                                        <li className="navigation-col">
                                          <ul className="navigation-list">
                                            <li className="rlr-icon-text">
                                              <div className="rlr-icon-text__text-wrapper">
                                                <span className="rlr-icon-text__title"><a onMouseLeave={() => setCurrentItem(null)} onMouseEnter={() => { setActive(index); setCurrentItem(item1?.properties?.length > 0 ? item1?.properties[0] : null) }} onClick={() => openCountry(item1?._id, item?.name, item1?.name)}> {item1?.name}</a></span>
                                              </div>
                                            </li>
                                          </ul>
                                        </li>
                                      </>
                                    )
                                  })
                                }
                                {currentItem &&
                                  <li className="navigation-col most_popul_sect">
                                    <h1>MOST POPULAR</h1>
                                    <div className='banner_img'>
                                      <img src={currentItem?.banner} alt={currentItem?.banner} />
                                    </div>
                                    <p>{currentItem?.overview.substring(0, 150)}</p>
                                  </li>
                                }
                              </ul>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </li>
                </ul>
              </li>

              <li className={currentTab == 'experience' ? 'navigation-item is-active has-submenu' : "navigation-item has-submenu"} onMouseEnter={() => setExperiencesMenu(true)} onClick={() => setExperiencesMenu(!experiencesMenu)}>
                <Link className="navigation-link" to="/experience/top-travel-destinations-by-experience" onClick={() => { setExperiencesMenu(!experiencesMenu); setToggleMenu(false) }}>Experiences</Link>
                <button className='mobile_menu_ext_btn' onClick={() => { setExperiencesMenu(!experiencesMenu) }}>+</button>
                <ul className={`navigation-dropdown navigation-dropdown-new ${experiencesMenu ? 'active' : 'menuhidden'}`}>
                  {
                    experiences?.map((item, index) => (
                      <li className="navigation-dropdown-item" onClick={() => openExp(item)}>
                        <a className="navigation-dropdown-link">{item?.name}</a>
                      </li>
                    ))
                  }
                </ul>
              </li>
              <li className={currentTab == 'who' ? 'navigation-item is-active has-submenu' : "navigation-item has-submenu"} onMouseEnter={() => setWhoMenu(true)} onClick={() => setWhoMenu(!whoMenu)}>
                <Link className="navigation-link" to="/who/top-travel-destinations-by-who" onClick={() => { setWhoMenu(!whoMenu); setToggleMenu(false) }}>Who</Link>
                <button className='mobile_menu_ext_btn' onClick={() => { setWhoMenu(!whoMenu); }}>+</button>
                <ul className={`navigation-dropdown navigation-dropdown-new ${whoMenu ? 'active' : 'menuhidden'}`}>
                  {
                    whos?.map((item, index) => (
                      <li className="navigation-dropdown-item" onClick={() => openWho(item)}>
                        <a className="navigation-dropdown-link" >{item?.name}</a>
                      </li>
                    ))
                  }
                </ul>
              </li>
              <li className={currentTab == 'month' ? 'navigation-item is-active has-submenu' : "navigation-item has-submenu"} onMouseEnter={() => setMonthsMenu(true)} onClick={() => setMonthsMenu(!monthsMenu)}>
                <Link className="navigation-link" to="/month/top-travel-destinations-by-months" onClick={() => { setMonthsMenu(!monthsMenu); setToggleMenu(false) }}>Months</Link>
                <button className='mobile_menu_ext_btn' onClick={() => { setMonthsMenu(!monthsMenu) }}>+</button>
                <ul className={`navigation-dropdown navigation-dropdown-new navigation-dropdown-new-months ${monthsMenu ? 'active' : 'menuhidden'}`}>
                  {
                    months?.map((item, index) => (
                      <li className="navigation-dropdown-item" onClick={() => openMonth(item)}>
                        <a className="navigation-dropdown-link">{item?.name}</a>
                      </li>
                    ))
                  }
                </ul>
              </li>
              <li className={currentTab == 'blog' ? 'navigation-item is-active' : 'navigation-item'}>
                <Link className="navigation-link" target='_blank' to="/blog" onClick={() => setToggleMenu(false)}>Blog</Link>
              </li>
            </ul>
            {user ?
              <ul className="navigation-menu rlr-navigation__menu align-to-right">
                <li className="navigation-item">
                  <Link className="navigation-link web_link" to="/search"><i className='fa fa-search fs-6'></i></Link>
                  <Link className="navigation-link mobile_link" onClick={() => setToggleMenu(false)} to="/search">Search</Link>
                </li>
                <li className="navigation-item">
                  <Link className="navigation-link" href="#" onMouseEnter={() => setIsProfileOpen(true)} onMouseLeave={() => setIsProfileOpen(false)}> {user?.name}
                    <img className="ui right spaced avatar image" style={{ borderRadius: '50%' }} src={user?.profile_picture ? user?.profile_picture : require('./assets/images/woow-destination.png')} alt="woow-destination.png" />
                  </Link>
                  <ul className={isProfileOpen ? "navigation-dropdown is-visible" : "navigation-dropdown"} onMouseLeave={() => setIsProfileOpen(false)} onMouseEnter={() => setIsProfileOpen(true)}>
                    <li className="navigation-dropdown-item" >
                      <Link className="navigation-dropdown-link" onClick={() => setToggleMenu(false)} to="/profile">Profile</Link>
                    </li>
                    <li className="navigation-dropdown-item">
                      <hr className="dropdown-divider rlr-dropdown__divider" />
                    </li>
                    <li className="navigation-dropdown-item">
                      <Link className="navigation-dropdown-link" onClick={() => {
                        handleLogout()
                        setToggleMenu(false)

                      }}>Sign out</Link>
                    </li>
                  </ul>
                </li>
              </ul>
              :
              <ul className="navigation-menu rlr-navigation__menu align-to-right">
                <li className="navigation-item">
                  <Link className="navigation-link web_link" to="/search"><i className='fa fa-search fs-6'></i></Link>
                  <Link className="navigation-link mobile_link" onClick={() => setToggleMenu(false)} to="/search">Search</Link>
                </li>
                <li className="navigation-item">
                  <Link to="/login" onClick={() => setToggleMenu(false)} className="navigation-link"> Login / Sign Up </Link>
                </li>
              </ul>
            }
          </div>
        </nav >
      </header >
    </>
  )
}
