import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation'
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function FilteModal(props) {
    const navigate = useNavigate();
    const { filterModalVisible, setFilterModalVisible, headerData, filters, setFilters } = props;
    const [contis, setContis] = useState([]);
    const [experience, setExperience] = useState([]);
    const [month, setMonth] = useState([]);
    const [destination, setDestination] = useState([]);
    const [active, setActive] = useState(0);
    const [whos, setWhos] = useState([]);
    const [filterLength, setFilterLength] = useState(0);
    // const [] = useState({ dest: [], exp: [], who: [], month: [] });

    useEffect(() => {
        // console.log(headerData);
        if (headerData) {
            setDestination(headerData?.destination);
            setExperience(headerData?.experiences);
            setMonth(headerData?.months);
            setWhos(headerData?.whos);
            setMonth(headerData?.months);

        }
    }, [headerData]);

    const handleSelected = (val, to) => {
        let temp = Object.assign({ dest: [], exp: [], who: [], month: [] }, filters);
        let length = temp.dest?.length + temp?.exp?.length + temp?.who?.length + temp?.month?.length;
        console.log('total length', length);
        // setFilterLength(length);
        if (to == 'dest') {
            let found = temp.dest.findIndex(x => x == val);
            console.log('found index', found);
            if (found == -1) {
                if (length < 5)
                    temp.dest.push(val)
                else
                    toast.error("You are allow only for 5 filters!")
            } else
                temp.dest.splice(found, 1);
        } else if (to == 'exp') {
            let found = temp.exp.findIndex(x => x == val);
            console.log('found index', found);
            if (found == -1) {
                if (length < 5)
                    temp.exp.push(val)
                else
                    toast.error("You are allow only for 5 filters!")
            } else
                temp.exp.splice(found, 1);
        } else if (to == 'who') {
            let found = temp.who.findIndex(x => x == val);
            console.log('found index', found);
            if (found == -1) {
                if (length < 5)
                    temp.who.push(val)
                else
                    toast.error("You are allow only for 5 filters!")
            }
            else
                temp.who.splice(found, 1);
        } else if (to == 'month') {
            let found = temp.month.findIndex(x => x == val);
            console.log('found index', found);
            if (found == -1) {
                if (length < 5)
                    temp.month.push(val)
                else
                    toast.error("You are allow only for 5 filters!")
            }
            else
                temp.month.splice(found, 1);
        }
        setFilters(temp);
        // console.log('total length', length);
        setFilterLength(temp.dest?.length + temp?.exp?.length + temp?.who?.length + temp?.month?.length);
    }

    const handleSelectedAllDest = (item, to) => {
        let temp = Object.assign({ dest: [], exp: [], who: [], month: [] }, filters);
        if (to == 'dest') {
            let dest = [];
            if (temp.dest?.length != item?.countries?.length) {
                item?.countries?.map((con) => {
                    dest.push(con?._id);
                });
            }
            temp.dest = dest;
        } else if (to == 'exp') {
            let exp = [];
            if (temp.exp?.length != experience?.length)
                experience?.map((item) => {
                    exp.push(item?._id);
                })
            temp.exp = exp;
        } else if (to == 'who') {
            let who = [];
            if (temp.who?.length != whos?.length)
                whos?.map((item) => {
                    who.push(item?._id);
                })
            temp.who = who;
        } else if (to == 'month') {
            let monthTemp = [];
            if (temp.month?.length != month?.length)
                month?.map((item) => {
                    monthTemp.push(item?._id);
                })
            temp.month = monthTemp;
        }
        setFilters(temp);
    }

    const handleValidSubmit = (e, v) => {
        setFilterModalVisible(false);
        let url = "/filter/top";
        filters?.exp?.map((item, index) => {
            experience?.map((exp) => {
                if (index == 0 && item == exp?._id)
                    url = url + "-"+ parseName(exp.name);
                else if (item == exp?._id)
                    url = url + "&" + parseName(exp.name);
            })
        });
        url = url + "-destinations";
        filters?.who?.map((item, index) => {
            whos?.map((subitem) => {
                if (index == 0 && item == subitem?._id)
                    url = url + "-for-" + parseName(subitem.name);
                else if (item == subitem?._id)
                    url = url + "&" + parseName(subitem.name);
            })
        });
        filters?.dest?.map((item, index) => {
            destination?.map((dest) => {
                dest?.countries?.map((con) => {
                    if (index == 0 && item == con?._id)
                        url = url + "-in-" + parseName(con.name);
                    else if (item == con?._id)
                        url = url + "&" + parseName(con.name);
                })
            })
        });
        navigate(url, { state: { filters } });
    }

    const parseName = (str) => {
        if (str) {
            let temp = str.split(",").join("");
            return temp.split(" ").join("-").toLowerCase();
        } else
            return "";
    }

    // console.log('filters', filters)

    return (
        <Modal isOpen={filterModalVisible} toggle={() => setFilterModalVisible(false)} backdrop="static" centered className='filter_modal'>
            <ModalBody className='filter_modal_box'>
                <button className='btn close_btn' onClick={() => setFilterModalVisible(false)}>
                    &#10006;
                </button>
                <div id="" className={""}>
                    {/* <h3 className="rlr-share__title">Filter</h3> */}
                    <div className="filter_items_section">
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <h3>Destinations</h3>
                            <div className='destination_section filter_section'>
                                <ul className='left_listing'>
                                    {destination?.map((item, index) => (
                                        <li key={item?._id}>
                                            <button type="button" className={active == index ? 'fiter_btn active' : 'fiter_btn'} onClick={() => setActive(index)}>{item?.name}</button>
                                        </li>
                                    ))}
                                </ul>
                                <div className='right_listing_destination'>
                                    {destination?.map((item, index) => {
                                        // console.log("item.name", item.countries.capital)
                                        return (
                                            <div className={active == index ? 'right_des_list active' : 'right_des_list'}>
                                                <ul className="navigation-row">
                                                    {
                                                        item.countries?.map((item1) => {
                                                            // console.log('item1', item1);
                                                            // mostPop.push({ banner: item1?.banner, desc: item1?.desc });
                                                            return (
                                                                <>
                                                                    <li className="destination_link">
                                                                        {/* <AvGroup check> */}
                                                                        <input type="checkbox" disabled={filters?.dest?.findIndex(x => x == item1?._id) == -1 && filterLength>4} checked={filters?.dest?.findIndex(x => x == item1?._id) != -1} name={item1?.name} onChange={() => handleSelected(item1?._id, 'dest')} />
                                                                        <Label check for={item1?.name}> {item1?.name}</Label>
                                                                        {/* </AvGroup> */}
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div className='experiences_section'>
                                <h3>Experiences</h3>
                                <div >
                                    <ul className="navigation-row">
                                        {
                                            experience?.map((item, index) => {
                                                // console.log("item.name", item.countries.capital)
                                                return (
                                                    <li className="destination_link">
                                                        <input type="checkbox" name={item?.name} checked={filters?.exp?.findIndex(x => x == item?._id) != -1} onChange={() => handleSelected(item?._id, 'exp')} />
                                                        <Label check for={item?.name}> {item?.name}</Label>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='experiences_section'>
                                <h3>Who</h3>
                                <div >
                                    <ul className="navigation-row">
                                        {
                                            whos?.map((item, index) => {
                                                // console.log("item.name", item.countries.capital)
                                                return (

                                                    <li className="destination_link">
                                                        <input type="checkbox" name={item?.name} checked={filters?.who?.findIndex(x => x == item?._id) != -1} onChange={() => handleSelected(item?._id, 'who')} />
                                                        <Label check for={item?.name}> {item?.name}</Label>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            {/* <div className='experiences_section'>
                                <h3>Months</h3>
                                <div >
                                    <ul className="navigation-row">
                                        {
                                            month?.map((item, index) => {
                                                // console.log("item.name", item.countries.capital)
                                                return (
                                                    <li className="destination_link">
                                                        <input type="checkbox" name={item?.name} checked={filters?.month?.findIndex(x => x == item?._id) != -1} onChange={() => handleSelected(item?._id, 'month')} />
                                                        <Label check for={item?.name}> {item?.name}</Label>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div> */}
                            <div className="rlr-copylink filter_footer">

                                <button className='btn btn-primary' >
                                    Apply
                                </button>
                                <button className='btn btn-danger' onClick={() => setFilterModalVisible(false)}>
                                    Cancel
                                </button>

                            </div>
                        </AvForm>

                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default FilteModal;