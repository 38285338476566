import { AvForm, AvField } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { post } from './helper/helper_api'
import { toast } from 'react-toastify'
import { projectName } from './Utils';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Utils from "./Utils";
import FilteModal from './component/Filter/FilteModal';

export default function Footer(props) {

  const { headerData, filters, setFilters  } = props;
  const [contis, setContis] = useState([]);
  const [experience, setExperience] = useState([]);
  const [month, setMonth] = useState([]);
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(0);
  // console.log('contis on footer', contis);
  const [filterModalVisible, setFilterModalVisible] = useState(false);

  const handleValidInquiry = (e, v) => {
    const body = {
      ...v
    }
    post('/query/add', body)
      .then((res => {
        console.log('response from subscribe');
        if (res?.statusCode == 200)
          toast.success(res?.message);
        else
          toast.error(res?.error);
      }))
      .catch(error => {
        console.log('error while subscribing', error);
        toast.error('Something went wrong');
      })
  }

  useEffect(() => {
    let time = null;
    const getData = async () => {
      const item = await localStorage.getItem('policy');
      console.log('policy from storage', item);
      if (!item)
        time = setTimeout(() => {
          setIsDisclaimer(true)
        }, 3000);
    }
    getData();
    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    // console.log(headerData);

    if (headerData) {
      setContis(headerData?.destination);
      setExperience(headerData?.experiences);
      setMonth(headerData?.months);

    }
  }, [headerData]);

  const handleOkayDisclaimer = () => {
    setIsDisclaimer(false);
    localStorage.setItem('policy', 'agreed');
  }

  const compressId = (id) => {
    const temp = id.slice(id.length - 4, id.length);
    // console.log('compressing id', temp);
    return temp;
  }

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[])

  const handleScroll = () => {
    setCurrentScroll(window.scrollY);
    // let divHeight = document.getElementById("scrollableView")?.scrollHeight;
    // if (window?.scrollY > (divHeight))
    //   if (totalPages > currentPage)
    //     getCountries();
    // toast.success("loading more...")
    // console.log('current scroll posistion', window.scrollY, divHeight);
    //     getSearch();
  }


  return (
    <div className='footer_section'>
      <FilteModal filterModalVisible={filterModalVisible} headerData={headerData} setFilterModalVisible={setFilterModalVisible} filters={filters} setFilters={setFilters} />
      <footer className="rlr-footer rlr-section rlr-section__mt">
        <div className="container">
          <div className="rlr-footer__menu">
            <nav className="rlr-footer__menu__col">
              <h4>Destinations</h4>
              <ul>
                {contis?.map((item) => (
                  <li key={item?._id}><Link target='_blank' to={"/continent/" + compressId(item?._id) + "/top-travel-destinations-in-" + (item?.name).split(" ").join("-").toLowerCase()}>{item?.name}</Link></li>
                ))}
              </ul>
            </nav>
            <nav className="rlr-footer__menu__col">
              <h4>Experiences</h4>
              <ul>
                {experience?.map((item) => (
                  <li key={item?._id}><Link target='_blank' to={"/experience/" + compressId(item?._id) + "/top-travel-destinations-in-" + (item?.name).split(" ").join("-").toLowerCase()}>{item?.name}</Link></li>
                ))}
              </ul>
            </nav>
            <nav className="rlr-footer__menu__col">
              <h4>Months</h4>
              <ul>
                {month?.map((item) => (
                  <li key={item?._id}><Link target='_blank' to={"/month/" + compressId(item?._id) + "/top-travel-destinations-in-" + (item?.name).split(" ").join("-").toLowerCase()}>{item?.name}</Link></li>
                ))}
              </ul>
            </nav>

            <nav className="rlr-footer__menu__col rlr-footer__menu__col--lg">
              <h4>Get In Touch</h4>
              <a href="#" className="rlr-footer__menu__col__letstalk">Let’s Subscribe</a>
              {/* <form  className="rlr-subscribe" data-aos="fade-up" data-aos-offset="250" data-aos-duration="700"> */}
              <div className='d-flex'>
                <AvForm onValidSubmit={handleValidInquiry} className="rlr-subscribe aos-init aos-animate">
                  <AvField
                    type="email"
                    name="email"
                    className="rlr-subscribe__input rlr_subscribe__input_new"
                    placeholder="Enter your email"
                    required
                  />
                  <button type='btn' className="btn" >Send Now!</button>
                </AvForm>
              </div>
              {/* </form> */}
              <div className='footer_tag'>
                <div>
                  <h3>Crafted With</h3>
                  <i className="rlr-icon-font flaticon-heart-1"> </i>
                </div>
                <h3>In Gurugram</h3>

              </div>
            </nav>
          </div>

          <div className="rlr-footer__legal">
            <div className="rlr-footer__legal__row rlr-footer__legal__row--top">
              <div className="rlr-footer__legal__row__col">
              <Link target='_blank' to="/blog">Blog</Link>
                <p />
                <Link target='_blank' to="/privacy-policy">Privacy Policy</Link>
                <p />
                <Link target='_blank' to="/disclaimer">Disclaimer</Link>
                <p></p>
                <Link target='_blank' to="/terms-and-conditions">Terms and Conditions</Link>
                <p></p>
                <p style={{ fontSize: 13, color: '#b6b6b6' }}>Image Source - FreePik, Envato Elements and various hotel websites.</p>
                <p style={{ fontSize: 13, color: '#b6b6b6' }}>{Utils.email}</p>
              </div>

              <div className="rlr-footer__legal__row__col">
                <a href="#">Linkedin</a>
                <span className="separate">/</span>
                <a href="https://www.facebook.com/woowdestinations" target='_blank'>Facebook</a>
                <span className="separate">/</span>
                <a href="https://www.instagram.com/woowdestinationsofficial/" target='_blank'>Instagram</a>
              </div>
            </div>

            <div className="rlr-footer__legal__row rlr-footer__legal__row--bottom">
              <div className="rlr-footer__legal__row__col">
                <span>{new Date().getFullYear()} © {projectName}. All Rights Reserved.</span>
              </div>
            </div>

            <div className="rlr-footer__legal__row__col">
            </div>

            <div style={{ marginTop: 20 }} className={isDisclaimer ? 'disclaimer active' : 'disclaimer'}>
              <div>
                <h2>Agree to policies</h2>
                <p style={{ fontSize: 12, margin: 0 }} >
                  By continuing to use this website, you agree to our <a href='https://woowdestinations.com/privacy-policy' target='_blank' style={{color:'#10a5b2',textDecoration:'none'}}>privacy policy</a>, use of cookies, and <a target='_blank' style={{color:'#10a5b2',textDecoration:'none'}} href='https://woowdestinations.com/disclaimer'>disclaimer</a>.
                </p>
              </div>
              <Button onClick={handleOkayDisclaimer} >Okay, Got it</Button>
            </div>
          </div>
        </div>
      </footer >
      <Button type="button" onClick={() => setFilterModalVisible(true)} className="top filter_toogle_btn">
        <img src={require('./assets/images/icons8-filter.png')} />
      </Button>
      {currentScroll > 800 ?
          <Button type="button" onClick={() => window.scroll(0, 0)} className="top back_to_top">&#8593; </Button>
          : null
        }
        
    </div>
  )
}
