import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Disclaimer() {
    useEffect(() => {
		window.scrollTo(0, 0);
		
	}, [])
  return (
    <section className="rlr-about rlr-section rlr-section__my">
    <div className="container">
        <div className='sectin_comm'>
            
            <h4>Disclaimer</h4>
             <p>The information contained on the website <Link to="https://www.woowdestinations.com">https://www.woowdestinations.com</Link> (referred to as "the website") is for general informational purposes only. The website is owned and operated by an individual or organization (hereinafter referred to as "we," "our," or "us"). By accessing and using the website, you acknowledge and agree to be bound by this disclaimer.</p>
             <p>Accuracy of Information: While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website. Any reliance you place on such information is therefore strictly at your own risk.</p>
             <p>Travel Advice and Recommendations: The content provided on the website, including travel advice, recommendations, tips, and itineraries, are based on our experiences, research, and personal opinions. Travel information can change rapidly, and it is essential to verify any critical details such as visa requirements, travel advisories, safety precautions, and other factors from official sources before making any travel arrangements.</p>
             <p>Third-Party Links: The website may contain links to third-party websites or services that are not under our control. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
             <p>Limitation of Liability: In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the website.</p>
             <p>Personal Responsibility: Traveling involves inherent risks, and you agree that any activities or actions you undertake based on the information provided by the website are entirely at your own risk. We shall not be liable for any accidents, injuries, losses, damages, or any other adverse incidents that may occur during your travels.</p>
             <p>Affiliation and Endorsement: The presence of any specific product, service, brand, or company on the website does not constitute an endorsement or affiliation unless explicitly stated. Any mention of a particular product or service is based on our personal experience or research and should not be considered as a formal endorsement.</p>
             <p>Changes to the Website and Disclaimer: We reserve the right to modify, update, or discontinue any part of the website or this disclaimer at any time without prior notice. It is your responsibility to review this disclaimer periodically for any changes.</p>
             <p>By accessing and using the website, you agree to indemnify and hold us harmless from any claims, actions, demands, losses, liabilities, and expenses arising out of or related to your use of the website.</p>
             <p>If you do not agree with any part of this disclaimer, please do not use the website. If you have any questions or concerns regarding this disclaimer, please contact us through the contact information provided on the website.</p>
        </div>
    </div>
</section>
  )
}
