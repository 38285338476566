import React from 'react'
// import Header from '../../Header'
// import Footer from '../../Footer'

export default function index() {
  return (
    <>
    {/* <Header /> */}
    <main id="rlr-main" className="rlr-main--fixed-top">
      {/* <!-- Header banner --> */}
      <section className="rlr-section">
        <div className="rlr-cta rlr-cta--no-button">
          <h2>Contact us</h2>
          <p>Git in touch via phone, email or fill in the form below</p>
        </div>
      </section>
      {/* <!-- Main content --> */}
      <section className="rlr-section rlr-section__mt">
        <div className="container">
          <div className="row justify-content-around">
            {/* <!-- Contact form --> */}
            <div className="col-xl-offset-1 col-xxl-5 col-lg-5">
              <div className="rlr-contact-form">
                <div className="rlr-contact-form__header">
                  <div className="rlr-page-title">
                    <span className="rlr-page-title__icon"> <i className="rlr-icon-font flaticon-fluent-hand-left-20-regular"> </i> </span>
                    <div>
                      <h2 className="type-h3 rlr-page-title__title">Hi, we’re happy to help you!</h2>
                      <span className="rlr-page-title__desc">Please enter detials so we can reach out to you</span>
                    </div>
                  </div>
                </div>
                <div className="rlr-contact-form__body">
                  <form action="/">
                    <div className="rlr-contact-form__input-group"><label className="type-body-medium rlr-contact-form__label">First Name</label> <input type="text" autocomplete="off" className="form-control" placeholder="John" /></div>
                    <div className="rlr-contact-form__input-group"><label className="type-body-medium rlr-contact-form__label">Last Name</label> <input type="text" autocomplete="off" className="form-control" placeholder="doe" /></div>
                    <div className="rlr-contact-form__input-group"><label className="type-body-medium rlr-contact-form__label">Phone</label> <input type="tel" autocomplete="off" className="form-control" placeholder="XXXXXXXXXX" /></div>
                    <div className="rlr-contact-form__input-group"><label className="type-body-medium rlr-contact-form__label">Message</label> <textarea className="form-control form-control--text-area" placeholder="Please explain the issue your are facing." rows="12"></textarea></div>
                    <div className="rlr-contact-form__input-group"><label className="type-body-medium rlr-contact-form__label">Email</label> <input type="email" autocomplete="off" className="form-control" placeholder="johndoe@email.com" /></div>
                    <button type="submit" className="btn rlr-button btn rlr-button custom-className rlr-button--medium rlr-button--rounded rlr-button__color--white rlr-button--brand rlr-button-- rlr-button-- rlr-button__color-- rlr-button-- rlr-button--">Submit</button>
                  </form>
                </div>
              </div>
            </div>
            {/* <!-- Contact Details --> */}
            <div className="col-xl-offset-1 col-xxxl-offset-1 col-xxl-4 col-lg-5 mt-5 mt-lg-0">
              <div className="rlr-contact-details">
                <div className="rlr-contact-details__details">
                  <div className="rlr-contact-detail-item">
                    <span className="rlr-contact-detail-item__icon"> <i className="rlr-icon-font flaticon-phone-receiver-silhouette"> </i> </span>
                    <div>
                      <p className="rlr-contact-detail-item__title">Phone</p>
                      <h6 className="rlr-contact-detail-item__desc">020 8577 5772</h6>
                    </div>
                  </div>
                  <div className="rlr-contact-detail-item">
                    <span className="rlr-contact-detail-item__icon"> <i className="rlr-icon-font flaticon-email"> </i> </span>
                    <div>
                      <p className="rlr-contact-detail-item__title">Email</p>
                      <h6 className="rlr-contact-detail-item__desc">support@emprise.tours</h6>
                    </div>
                  </div>
                  <div className="rlr-contact-detail-item">
                    <span className="rlr-contact-detail-item__icon"> <i className="rlr-icon-font flaticon-map-marker"> </i> </span>
                    <div>
                      <p className="rlr-contact-detail-item__title">Address</p>
                      <h6 className="rlr-contact-detail-item__desc">27 Kensington Gardens, London, SW7</h6>
                    </div>
                  </div>
                </div>
                <div className="rlr-contact-details__map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2822.7806761080233!2d-93.29138368446431!3d44.96844997909819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b32b6ee2c87c91%3A0xc20dff2748d2bd92!2sWalker+Art+Center!5e0!3m2!1sen!2sus!4v1514524647889"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    {/* <Footer /> */}
    </>
  )
}
