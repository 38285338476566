import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { post } from '../../helper/helper_api';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import StarRatings from 'react-star-ratings';
import UnlikeModal from "../../component/UnlikeModal";


export default function Profile(props) {
    let navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [data, setData] = useState(null);
    const [loadingMore, setLoadingMore] = useState(true);
    const [isUnlikeModal, setIsUnlikeModal] = useState(false);
    const [shareData, setShareData] = useState(null);

    useEffect(() => {
        if (!user)
            navigate("/login");
        else if (user?.token)
            getUserData();
    }, [user]);

    const getUserData = () => {
        post("/user/user", {
            token: user?.token
        }).then(res => {
            setLoadingMore(false);
            if (res?.statusCode == 200) {
                setData(res?.data);

            }
        })
            .catch(err => {
                console.log('error while get user detail', err);
            })
    }

    const handleProLike = () => {
        if (user) {
            const body = {
                token: user?.token,
                propertyId: shareData?._id
            }
            post("/property/like", body)
                .then((res) => {
                    // console.log('response from like property', res);
                    if (res?.statusCode == 200) {
                        // toast.success(res?.message);
                        getUserData();
                        setIsUnlikeModal(false);
                        setShareData(null);
                    } else
                        toast.error(res?.error);
                })
                .catch(error => {
                    setIsUnlikeModal(false);
                    console.log('error while liking property', error);
                    toast.error('Something went wrong');
                })
        } else {
            navigate("/login");
        }
    }

    const compressId = (id) => {
        const temp = id?.slice(id?.length - 4, id?.length);
        // console.log('compressing id', temp);
        return temp;
    }

    const parseName = (str) => {
        if (str) {
            let temp = str.split(",").join("");
            return temp.split(" ").join("-").toLowerCase();
        } else
            return "";
    }

    const openProperty = (item) => {
        console.log('navigating item', item);
        window.open("/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" + parseName(item?.location) + "-" + parseName(item?.country[0]?.name) + "-" + parseName(item?.continent[0]?.name), "_blank");
    }

    return (
        <main id="rlr-main" className="rlr-main--fixed-top">
            <UnlikeModal
                show={isUnlikeModal}
                data={shareData}
                type={"Destination"}
                remove={handleProLike}
                setShow={setIsUnlikeModal}
            />
            <div className="rlr-search-results-page container">
                {/* <div className='banner-section'>
                    <img src="https://eeasy.s3.ap-south-1.amazonaws.com/Continent/1681273601281.JPEG" />
                </div> */}
                <div className="rlr-search-results-page container-fluid padding_35px collection_page px-0">
                    <div className="rlr-search-results-page__breadcrumb-section rlr-search-results-page__breadcrumb-section-nav">
                        {/* <!-- Breadcrumb --> */}
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb rlr-breadcrumb__items">
                                <li className="breadcrumb-item rlr-breadcrumb__item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">My Profile</li>
                                {/* <li className="breadcrumb-item rlr-breadcrumb__item">{propertydetail?.name ? propertydetail?.name : ""}</li> */}
                                {/* <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">{propertydetail[0]?.name}</li> */}
                            </ol>
                        </nav>
                        {/* <div className="rlr-icon-text"><i className="rlr-icon-font flaticon-email" style={{ fontWeight: 'bold' }}> </i> <span className="rlr-search-results-page__phone">Questions? {email} </span></div> */}
                    </div>
                    <div className='profile-page'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='left_profile_box'>
                                    <div className='profile_img'>
                                        <img src={user?.profile_picture ? user?.profile_picture : require('../../assets/images/woow-destination.png')} alt="woow-destination-user-profile.png" />
                                    </div>
                                    <h3>{user?.name}</h3>
                                    {/* <p></p> */}
                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <div className='right_profile_content'>
                                    <ul>
                                        <li><div className='left_detail'>Full Name</div><div className='right_detail'>{user?.name}</div></li>
                                        <li><div className='left_detail'>Email</div><div className='right_detail'>{user?.email}</div></li>
                                        {/* <li><div className='left_detail'>Mobile</div><div className='right_detail'>(098) 765-4321</div></li> */}
                                        {/* <li><div className='left_detail'>Address</div><div className='right_detail'>Bay Area, San Francisco, CA</div></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className='mt-3 favourites-heading'>My Favourites</h3>
                <div className="row rlr-search-results-page__product-details pt-0">
                    <div className="rlr-search-results-page__product-list col-lg-12" id="scrollableView">
                        <div className="row rlr-search-results-page__card-wrapper rlr-search-results-page__card-wrapper_bot">
                            {
                                data?.favrt?.map((item, index) => {
                                    // console.log('pro item banne', item?.banner)
                                    // if (ads?.length > 0 && item?.type && item?.type == 'ads')
                                    //     return (
                                    //         <div style={{ width: '100%' }}>
                                    //             {ads.map((item) => {
                                    //                 if (item?.position == 'top')
                                    //                     return (
                                    //                         <div className='add_section_mid'>
                                    //                             <img src={item?.banner} style={{ width: 'auto', height: 'auto' }} />
                                    //                         </div>
                                    //                     )
                                    //             })}
                                    //         </div>
                                    //     )
                                    // else
                                    return (
                                        <div className="col-md-6 col-lg-4">
                                            {/* <!-- Product card item --> */}
                                            <article className="rlr-product-card rlr-product-card--v3" itemscope >
                                                {/* <!-- Product card image --> */}
                                                <figure className="rlr-product-card__image-wrapper">
                                                    {/* <span className="rlr-badge rlr-badge-- rlr-badge--accent-red rlr-product-card__badge"> -20% Today </span> */}
                                                    <div className="rlr-product-detail-header__button-wrapper">
                                                        <button type="button" onClick={() => { setShareData(item); setIsUnlikeModal(true) }} className={item?.likes && item?.likes?.findIndex(x => x == user?._id) != -1 ? "btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist active" : "btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist"} aria-label="Save to Wishlist">
                                                            <i className="rlr-icon-font flaticon-heart-1"> </i>
                                                        </button>
                                                        <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                                                    </div>
                                                    <div className="">
                                                        <Swiper
                                                            spaceBetween={10}
                                                            centeredSlides={true}
                                                            // navigation={true}
                                                            modules={[Autoplay, Navigation]}
                                                            className="mySwiper list_property"
                                                        >
                                                            <SwiperSlide>
                                                                <img itemprop="image" src={item?.banner} className="lazyload" alt="product-image" />
                                                            </SwiperSlide>
                                                            {item?.banners?.map((img) => (
                                                                <SwiperSlide>
                                                                    <img itemprop="image" src={img} className="lazyload" alt="product-image" />
                                                                </SwiperSlide>
                                                            ))}
                                                        </Swiper>
                                                    </div>
                                                </figure>
                                                <div style={{ cursor: 'pointer' }} className="rlr-product-card__anchor-title" onClick={() => openProperty(item)}>
                                                    <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                                                        <header className="rlr-product-card__header">
                                                            <div>
                                                                <Link className="rlr-product-card__anchor-title">
                                                                    <h2 className="rlr-product-card__title" itemprop="name">{item?.name}</h2>
                                                                </Link>
                                                                <div>
                                                                    <Link className="rlr-product-card__anchor-cat">
                                                                        <span className="rlr-product-card__sub-title">{item?.location} | {item?.country[0]?.name}</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </header>
                                                        <div className="rlr-product-card__details">
                                                            <div className="rlr-product-card__prices" itemprop="offers" >
                                                                <span className="rlr-product-card__from">{item?.nights}</span>
                                                                <span className="rlr-product-card__rating-text" itemprop="reviewCount"></span>
                                                            </div>
                                                            <div className="rlr-product-card__ratings" itemprop="aggregateRating" itemscope>
                                                                <div className="rlr-review-stars" itemprop="ratingValue" itemscope >
                                                                    <StarRatings
                                                                        rating={item?.avgRating ? item?.avgRating : 0}
                                                                        starRatedColor={'#f7de00'}
                                                                        starHoverColor={'#f7de00'}
                                                                        numberOfStars={5}
                                                                        isSelectable={false}
                                                                        name='rating'
                                                                        starDimension={'20px'}
                                                                        starSpacing={'2px'}
                                                                    />
                                                                    {item?.avgRating &&
                                                                        <span className="rlr-product-card__rating-text" itemprop="reviewCount">{item?.avgRating} ({item?.reviews?.length})</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <hr className="rlr-search-results-page__divider" />
                    </div>
                    {
                        loadingMore &&
                        <div className="loading-container container">
                            <img src={require('../../assets/images/loading.gif')} alt="loading" />
                            <span>Loading...</span>
                        </div>
                    }
                </div>
            </div>
        </main>
    )
}
