import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";

function Share(props) {
    const { show, data, type, setShow, pathname } = props;
    const [shareLink, setShareLink] = useState("");
    const textRef = useRef(null);

    useEffect(() => {
        setShareLink("https://woowdestinations.com" + pathname);
    }, [props]);


    return (
        <Modal isOpen={show} toggle={() => setShow(false)} centered>
            <ModalBody>
                <div id="" className={""}>
                    <h3 className="rlr-share__title">Share with a friend</h3>
                    <div className="share-items">
                        <ul className="rlr-share__items">
                            <li className="rlr-share__list rlr-js--facebook">
                                <a href='javascript:void(0);' className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item">
                                    <FacebookShareButton
                                        url={shareLink}
                                        // url={"http://github.com"}
                                        quote={data?.overview}
                                        hashtag={"#woowdestinations"}
                                        className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item">
                                        <i className="rlr-icon-font flaticon-facebook">
                                        </i> <span className="rlr-share__title">Facebook </span>
                                    </FacebookShareButton>
                                </a>
                            </li>
                            <li className="rlr-share__list rlr-js--twitter">
                                <a href='javascript:void(0);' className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item">
                                    <TwitterShareButton
                                        className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item"
                                        url={shareLink}
                                        title={data?.overview}
                                        via={'Woowdestinations.com'}
                                        hashtag={"#woowdestinations"}
                                    > <i className="rlr-icon-font flaticon-twitter"> </i> <span className="rlr-share__title">Twitter </span> </TwitterShareButton>
                                </a>
                            </li>
                            <li className="rlr-share__list rlr-js--reddit">
                                <a href="#" className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item"> <i className="rlr-icon-font flaticon-instagram"> </i> <span className="rlr-share__title">Reddit </span> </a>
                            </li>
                            <li className="rlr-share__list rlr-js--whatsapp">
                                <a href="#" className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item"> <i className="rlr-icon-font flaticon-whatsapp"> </i> <span className="rlr-share__title">Whatsapp </span> </a>
                            </li>
                            <li className="rlr-share__list rlr-js--messenger">
                                <a href="#" className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item"> <i className="rlr-icon-font flaticon-messenger"> </i> <span className="rlr-share__title">Messenger </span> </a>
                            </li>
                            <li className="rlr-share__list rlr-js--email">
                                <a href="#" className="rlr-icon-text rlr-icon-text--anchor rlr-icon-text__block rlr-share__item"> <i className="rlr-icon-font flaticon-email-1"> </i> <span className="rlr-share__title">Email </span> </a>
                            </li>
                        </ul>
                        <div className="rlr-copylink">
                            <label className="rlr-copylink__title">Share link</label>
                            <div className="rlr-copylink__wrapper"><input type="text" disabled ref={textRef} autocomplete="off" className="form-control rlr-copylink__input" value={shareLink} />
                                <i style={{ fontSize: 30 }} onClick={() => {
                                   navigator.clipboard.writeText(shareLink);
                                }} className="rlr-icon-font flaticon-copy"> </i></div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default Share;