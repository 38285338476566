import React, { useEffect, useRef, useState } from 'react';
import './style.css'
// import './mite-assets.min.css'
import { Swiper, SwiperSlide } from "swiper/react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { toast } from 'react-toastify';
import { get } from '../../helper/helper_api';
import PortfoliorandomBlog from "./portfolio.json";
import useMasonry from "./use-masonry";
import { slugify } from "./utils";
import { Link, useNavigate } from 'react-router-dom';
import HotelWidget from '../../component/HotelWidget';
import Loader from '../../Loader';
import { Helmet } from 'react-helmet';
import VideoModal from '../../component/VideoModal'
export default function Blog(props) {
	const [randomBlog, setRandomBlog] = useState([]);
	const [loader, setLoader] = useState(true);
	const [freshStories, setFreshStories] = useState([]);
	const [trending, setTrending] = useState([]);
	const [videos, setVideos] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [mainBanner, setMainBanner] = useState(null);
	const [headerData, setHeaderData] = useState(null);
	const [isUnlikeModal, setIsUnlikeModal] = useState(false);
	const [shareData, setShareData] = useState(null);
	const [ads, setAds] = useState([]);
	// const [random, setRandom] = useState(8)
	const { categories } = useMasonry(
		randomBlog,
		".portfolio-list",
		".masonry-grid",
		".messonry-button",
		".messonry-button button"
	);

	// console.log('homerandomBlog', homerandomBlog)

	useEffect(() => {
		console.log(props?.headerData);
		if (props?.headerData)
			setHeaderData(props?.headerData);
	}, [props?.headerData])

	useEffect(() => {
		get("/blog/list", {})
			.then(res => {
				if (res?.statusCode == 200) {
					setRandomBlog(res?.data?.random);
					setFreshStories(res?.data?.freshStories);
					setTrending(res?.data?.trending);
				} else {
					toast.error("We are having a problem to load the blog, Please Try again later!")
				}
			})
			.catch(err => {
				console.log('error while getting blogs', err);
				toast.error("We are having a problem to load the blog, Please Try again later!")
			})
		get("/category/list", {})
			.then(res => {
				if (res?.statusCode == 200) {
					setAllCategories(res?.data);
					setLoader(false);
				} else {
					setLoader(false);
					toast.error("We are having a problem to load the blog, Please Try again later!")
				}
			})
			.catch(err => {
				console.log('error while getting blogs', err);
				setLoader(false);
				toast.error("We are having a problem to load the blog, Please Try again later!")
			})
		get("/video/list", {})
			.then(res => {
				if (res?.statusCode == 200) {
					setVideos(res?.data);
				} else {
					toast.error("We are having a problem to load the blog, Please Try again later!")
				}
			})
			.catch(err => {
				console.log('error while getting blogs', err);
				toast.error("We are having a problem to load the blog, Please Try again later!")
			})
		get("/ads/list?screen=home")
			.then(res => {
				// console.log('response from ads list', res)
				if (res?.statusCode == 200)
					setAds(res?.data);
			}).catch(err => {
				console.log('error while getting ads list', err);
			})
	}, []);

	const compressId = (id) => {
		const temp = id.slice(id.length - 4, id.length);
		// console.log('compressing id', temp);
		return temp;
	}

	const parseName = (str) => {
		if (str) {
			let temp = str.split(",").join("");
			return temp.split(" ").join("-").toLowerCase();
		} else
			return "";
	}

	const openBlog = (item) => {
		if (item?._id)
			return "/blog/" + compressId(item?._id) + "/" + parseName(item?.titleShort);
	}

	const openExp = (item) => {
		return '/experience/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name);
	}

	const openWho = (item) => {
		return '/who/' + compressId(item?._id) + "/" + "top-travel-destinations-for-" + parseName(item?.name)
	}

	const openCat = (item) => {
		if (item?._id)
			return '/blog-list/' + compressId(item?._id) + "/top-blogs-for-" + parseName(item?.title)
	}

	useEffect(() => {
		let added = document.getElementById("2368669");
		if (added)
			added.remove();

		// setLoading(false);
		setTimeout(() => {
			let script = document.createElement("script");
			script.type = "text/javascript";
			script.id = "2368669";
			script.async = true;
			script.function = ((d, sc, u) => {
				var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
				// console.log('getting element', s, p)
				s.type = 'text/javascript';
				s.async = true;
				s.src = u + '?v=' + (+new Date());
				p.parentNode.insertBefore(s, p);
			})(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
			document.body.appendChild(script);

		}, 500);
	}, []);

	useEffect(() => {
		let added = document.getElementById("2312218");
		if (added)
			added.remove();

		// setLoading(false);
		setTimeout(() => {
			let script = document.createElement("script");
			script.type = "text/javascript";
			script.id = "2312218";
			script.async = true;
			script.function = ((d, sc, u) => {
				var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
				// console.log('getting element', s, p)
				s.type = 'text/javascript';
				s.async = true;
				s.src = u + '?v=' + (+new Date());
				p.parentNode.insertBefore(s, p);
			})(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
			document.body.appendChild(script);

		}, 500);
	}, []);

	return (
		<>
			{
				loader && <Loader />
			}
			<Helmet>
				<title>Woow Destinations - Blogs</title>
				<meta name="keyword" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
				<meta name="description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
				<meta name="dc.language" content="English" />
				<meta name="dc.source" content="http://www.woowdestinations.com" />
				<meta name="dc.title" content="Woow Destinations" />
				<meta name="dc.keywords" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
				<meta name="dc.description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
				<meta name="Author" content="Woow Destinations" />
				<meta name="robots" content="index, follow" />
				<meta property="og:locale" content="en-in" />
				<meta property="og:type" content="website" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="canonical" href="http://www.woowdestinations.com" />
			</Helmet>
			<section className="top-images-section top_images_section_blog_main ">
				<VideoModal
					show={isUnlikeModal}
					data={shareData}
					//  type={"Destination"}
					//  remove={handleProLike}
					setShow={setIsUnlikeModal}
				/>
				<div className="container">
					<div className="top-images-box">
						<div className="row">
							{randomBlog?.map((item, index) => {
								if (index < 3)
									return (
										<div className="col-lg-4">

											<div className="news-post image-post">
												<img src={item?.image} alt={item?.titleShort} />
												<div className="hover-post text-center">
													{/* <div  className="category-link list_">{item?.category?.map((item)=>{
															return(
																<Link className='text_link_'>{item?.title}</Link>
															)
														})}</div> */}
													<h2><Link target='_blank' to={openBlog(item)} >{item?.titleShort}</Link></h2>
													<div className="category-link mt-2">
														<ul className="post-tags">
															<li>by {item?.author[0]?.title}</li>
															<li>{item?.updatedAt}</li>
														</ul>
													</div>
												</div>
											</div>

										</div>
									)
							})}
						</div>
					</div>
				</div>
			</section>
			<section className="fresh-section">
				<div className="container">
					<div className="title-section text-center">
						{/* <h1></h1> */}
						<div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
							{/* <!-- Section heading --> */}
							<div className="rlr-section__title">
								<h2 className="rlr-section__title--main text-center">Fresh Stories</h2>

							</div>

						</div>
					</div>
					<div className="fresh-box owl-wrapper">
						<OwlCarousel className='owl-theme blog_one_slider'
							loop
							margin={5}
							nav
							responsive={{
								0: {
									items: 1,
								},
								600: {
									items: 1.5,
								},
								1000: {
									items: 4,
								},
							}}>
							{freshStories?.map((item, index) => {
								return (
									<div className="item">
										<div className="news-post standard-post">
											<div className="image-holder">
												<Link target='_blank' to={openBlog(item)}><img src={item?.image} alt={item?.titleShort} /></Link>
											</div>
											{/* <a className="text-link" href="#">{item?.category[0]?.title}</a> */}
											<h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
											<ul className="post-tags">
												<li>by {item?.author[0]?.title}</li>
												<li>{item?.updatedAt}</li>
											</ul>
										</div>
									</div>
								)
							})}
						</OwlCarousel>
					</div>
					<div className="border-bottom"></div>
				</div>
			</section>
			<section id="features" className="rlr-section rlr-section__mb landding__plugin mb-0">
				<div className="container">
					{ads.map((item) => {
						if (item?.position == 'top')
							return (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} alt={item?.banner} /></a>
								</div>
							)
					})}
				</div>
			</section>
			<section className="trending-section">
				<div className="container">
					<div className="title-section">
						<div className="row">
							<div className="col-md-5">

							</div>
							<div className="title-section">
								{/* <h1></h1> */}
								<div className="rlr-section-header rlr_section_header_home_page">
									{/* <!-- Section heading --> */}
									<div className="rlr-section__title">
										<h2 className="rlr-section__title--main ">Trending Posts</h2>

									</div>

								</div>
							</div>
							{/* <div className="col-md-7">
								<ul className="filter-list">
									<li><a href="#" className="active">All</a></li>
									<li><a href="#">Lifestyle</a></li>
									<li><a href="#">Food</a></li>
									<li><a href="#">Travel</a></li>
								</ul>
							</div> */}
						</div>
					</div>
					<div className="trending-box">
						<div className="row">
							<div className="col-lg-5">
								<div className="owl-wrapper">
									<div className="news-post image-post trending_posts_section">
										<img src={trending[0]?.image} alt={trending[0]?.titleShort} />
										<div className="hover-post overlay-bg">
											{/* {trending[0]?.category.map((item) => (
												<Link target='_blank' className="category-link" to={openCat(item)}>{item?.title}</Link>
											))} */}
											{/* <Link target='_blank' className="category-link" to={openCat(trending[0]?.category[0])}>{trending[0]?.category[0]?.title}</Link> */}
											<h2><Link target='_blank' to={openBlog(trending[0])}>{trending[0]?.titleShort}</Link></h2>
											<ul className="post-tags">
												<li>by {trending[0]?.author[0]?.title}</li>
												<li>{trending[0]?.reviews?.length} comments</li>
												<li>{trending[0]?.updatedAt}</li>
											</ul>
											<p>{trending[0]?.title?.length > 150 ? trending[0]?.title?.substring(0, 150) + "..." : trending[0]?.title}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-7 col-md-6">
								<ul className="posts-list posts_list_new">
									{trending?.map((item, index) => {
										if (index > 0)
											return (
												<li className='list_'>
													<h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort?.length > 80 ? item?.titleShort?.substring(0, 80) + "..." : item?.titleShort}</Link></h2>
													<ul className="post-tags mb-2">
														<li>{item?.updatedAt}</li>
														<li>{item?.reviews?.length} comments</li>
													</ul>
													{
														item?.category?.map((item1) => {
															return (
																<Link target='_blank' className="text-link text_link_" to={openCat(item1)}>{item1?.title}</Link>
															)
														})
													}
												</li>
											)
									})}

								</ul>
							</div>

							<div className="col-lg-4 col-md-6 d-none">
								<div className="news-post image-post image_post_new">
									<ins class="bookingaff" randomBlog-aid="2368669" randomBlog-target_aid="2368669" randomBlog-prod="dfl2" randomBlog-width="300" randomBlog-height="450" randomBlog-lang="en">

										<a href="//www.booking.com?aid=2368669">Booking.com</a>
									</ins>

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{videos?.length > 0 &&
				<section className="video-section">
					<div className="container">
						<div className="title-section white-style text-center">

							<div className="title-section">
								{/* <h1></h1> */}
								<div className="rlr-section-header rlr_section_header_home_page">
									{/* <!-- Section heading --> */}
									<div className="rlr-section__title">
										<h2 className="rlr-section__title--main " style={{ color: '#fff', textAlign: 'left' }}>Popular Video</h2>

									</div>

								</div>
							</div>
						</div>
						<div className="video-box owl-wrapper">
							<OwlCarousel className='owl-theme blog_three_slider blog_two_slider_white_dots'

								margin={5}
								dots
								responsive={{
									0: {
										items: 1.5,
									},
									600: {
										items: 2,
									},
									1000: {
										items: 3,
									},
								}}>
								{videos?.map((item, index) => (
									<div className="item" key={index}>
										<div className="news-post video-post">
											<div className="image-holder">
												<img src={item?.image} alt={item?.titleShort} />
												<div className="hover-video" onClick={() => { setShareData(item); setIsUnlikeModal(true) }}>
													<Link className="video-link iframe" ><span><i className="fa fa-play"></i></span></Link>
												</div>
											</div>

											<h2><Link to="">{item?.titleShort}</Link></h2>
											<ul className="post-tags">
												<li><p style={{ color: '#fff' }}>{item?.updatedAt}</p></li>
											</ul>
										</div>
									</div>
								))}
							</OwlCarousel>

						</div>
					</div>
				</section>
			}
			<section className="blog-section blog_section_post mt-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="blog-box ">
								<div className="title-section">

									<div className="title-section">
										{/* <h1></h1> */}
										<div className="rlr-section-header rlr_section_header_home_page">
											{/* <!-- Section heading --> */}
											<div className="rlr-section__title">
												<h2 className="rlr-section__title--main ">Latest Posts</h2>

											</div>

										</div>
									</div>
								</div>
								<div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 row-cols-xl-2 portfolio-list mb-n30">
									{freshStories.length &&
										freshStories.map((item, index) => {
											if (index < 8)
												return (
													<div
														key={item.id}
														// eslint-disable-next-line react/no-unknown-property
														group={`any`}
														className={`col masonry-grid mb-30 `}
													>
														<div className="item ">
															<div className="news-post article-post">
																<div className="image-holder">
																	<img src={item?.image} alt={item?.titleShort} />
																</div>
																<div className='list_'>
																	{
																		item?.category?.map((item1) => {
																			return (
																				<Link target='_blank' className="text-link text_link_" to={openCat(item1)}>{item1?.title}</Link>
																			)
																		})
																	}
																</div>
																<h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
																<ul className="post-tags">
																	<li>{item?.updatedAt}</li>
																	<li>{item?.reviews?.length} comments</li>
																	<li>by {item?.author[0]?.title}</li>
																</ul>
																<p>{item?.title?.length > 100 ? item?.title.substring(0, 100) : item?.title}...</p>
															</div>
														</div>
													</div>
												)
										}
										)}


								</div>
								<div className="center-button">
									<Link target='_blank' className="button-one" to="/blog-list">Load More</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mt-1">
							<div className="sidebar sidebar_new">
								<div className="widget social-widget">
									<h2>Social</h2>
									<ul className="social-list">
										<li>
											<a href="https://www.facebook.com/woowdestinations" target='_blank'>
												<i className="fab fa-facebook"></i>
												facebook

											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
												twitter

											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/woowdestinationsofficial/" target='_blank'>
												<i className="fab fa-instagram"></i>
												instagram

											</a>
										</li>
									</ul>
								</div>
								<div className="widget features-widget">
									<div className="owl-wrapper">
										<OwlCarousel className='owl-theme blog_four_slider blog_two_slider_white_dots'
											loop
											margin={5}
											dots
											responsive={{
												0: {
													items: 1,
												},
												600: {
													items: 1,
												},
												1000: {
													items: 1,
												},
											}}>
											{
												mainBanner?.map((item, index) => {
													return (
														<div className="item">
															<div className="news-post image-post image_post_home_pro">
																<img src={item?.banner} alt={item?.name} />
																<div className="hover-post ">
																	<div><Link className="category-link" >{item?.location} | {item?.country[0].name}</Link></div>
																	<h2><Link >{item?.name}</Link></h2>
																	<p>{item?.overview?.length > 80 ? item?.overview?.slice(0, 90) + "..." : item?.overview}</p>
																	<ul className="post-tags">
																		<li><Link >3 comments</Link></li>
																		<li>{item?.nights}</li>
																	</ul>
																</div>
															</div>
														</div>
													)
												})
											}

										</OwlCarousel>

									</div>
								</div>
								<div className='right_sidebar'>

									<div className="widget category-widget">
										<h2>Categories</h2>
										<ul className="category-list list_posts_tag">
											{
												allCategories?.map((item, index) => {
													return (
														<Link target='_blank' to={openCat(item)}>
															{item?.title}
														</Link>
													)
												})
											}

										</ul>
									</div>
									<div className="widget list-widget">
										<h2>Popular mentions</h2>
										<ul className="list-posts list_posts_tag">
											{
												headerData?.experiences?.map((item, index) => {
													return (
														<Link target='_blank' className="text-link" to={openExp(item)}>{item?.name}</Link>
													)
												})
											}
											{
												headerData?.whos?.map((item, index) => {
													return (
														<Link target='_blank' className="text-link" to={openWho(item)}>{item?.name}</Link>
													)
												})
											}

										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="features" className="rlr-section rlr-section__mb landding__plugin">
				<div className="container">
					{ads.map((item) => {
						if (item?.position == 'bottom')
							return (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} alt={item?.banner} /></a>
								</div>
							)
					})}
				</div>
			</section>
			<section class="instagram-section">
				<div class="container">
					<div class="border-top"></div>
					<div className='visite_card container'>
						<div class="elfsight-app-082634f7-6d9a-4210-88ee-ab7973fd29b0"></div>
					</div>
				</div>
			</section>
		</>
	)
}