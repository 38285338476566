import React, { useEffect, useState } from 'react'
// import Header from '../Header'
// import Footer from '../Footer'
import { Link } from 'react-router-dom'
import { get, post } from '../helper/helper_api';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { email } from '../Utils';
import { Col, Row } from 'reactstrap';
import StarRatings from 'react-star-ratings';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import HTMLRenderer from 'react-html-renderer';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import Share from '../component/ShareModal';
import UnlikeModal from '../component/UnlikeModal';
import AdSense from 'react-adsense';

export default function Index(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  // console.log('location?.state', location?.state)
  const [propertydetail, setPropertydetail] = useState([]);
  const [proID, setProID] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loader, setLoader] = useState(true);
  const [type, setType] = useState(null);
  const [sortBy, setSortBy] = useState(false);
  const swiperRef = useRef();
  const [experience, setExperience] = useState([]);
  const [activeExp, setActiveExp] = useState(undefined);
  const [whos, setWhos] = useState([]);
  const [activeWho, setActiveWho] = useState(0);
  const [month, setMonth] = useState([]);
  const [activeMon, setActiveMon] = useState(0);
  const [mainObj, setMainObj] = useState(null);
  const [ads, setAds] = useState([]);
  const [headerData, setHeaderData] = useState(null);
  const [moreItem, setMoreItem] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [shareData, setShareData] = useState(null);
  const [shareLink, setShareLink] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [isUnlikeModal, setIsUnlikeModal] = useState(false);

  useEffect(() => {
    getCardList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log('path on listing', location.pathname.split("/"));
    let path = location.pathname.split("/");
    if (path.length > 0) {
      setType(path[1]);
      setCurrentPage(0);
    }
    if (path.length > 1)
      if (path[1] == 'experience') {
        experience.map((item) => {
          if (item?._id?.slice(item?._id?.length - 4, item?._id?.length) == path[2])
            setProID(item?._id);
        })
      } else if (path[1] == 'month') {
        month.map((item) => {
          // console.log('checking month for id match', item?._id?.slice(item?._id?.length - 4, item?._id?.length), path[2]);
          if (item?._id?.slice(item?._id?.length - 4, item?._id?.length) == path[2]) {
            setProID(item?._id);
          }
        })
      } else if (path[1] == 'who') {
        // console.log('checking whos for id match', whos?.length)
        whos.map((item) => {
          if (item?._id?.slice(item?._id?.length - 4, item?._id?.length) == path[2])
            setProID(item?._id);
        })
      }
      else {
        setProID(path[2]);
      }
  }, [experience, month, whos, location]);

  useEffect(() => {
    if (props?.headerData) {
      // console.log('props?.data', props?.headerData);
      setHeaderData(props?.headerData);
      setWhos(props?.headerData?.whos);
      setExperience(props?.headerData?.experiences);
      setMonth(props?.headerData?.months);
    }
  }, [props?.headerData]);

  useEffect(() => {
    if (proID) {
      // console.log('updating proId', proID);
      getProperties();
    }
  }, [proID]);

  const getCardList = () => {
    // console.log('pro id type', type);
    // setLoader(true);
    get("/ads/list?screen=inner")
      .then(res => {
        // console.log('response from ads list', res);
        if (res?.statusCode == 200)
          setAds(res?.data);
      }).catch(err => {
        console.log('error while getting ads list', err);
      })
  }

  const getProperties = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      let url = '/property/filter/list/';
      let body = {
        [type + "Id"]: proID,
        currentPage: currentPage
      }
      post(url, body).then(async (json) => {
        // console.log('property filter list', proID);
        setLoader(false);
        setLoadingMore(false);
        if (json?.statusCode == 200) {
          if (currentPage == 0) {
            const found = experience.findIndex(x => x._id == proID);
            if (found != -1)
              setActiveExp(found);
            const foundMon = month.findIndex(x => x._id == proID);
            if (foundMon != -1)
              setActiveMon(foundMon);
            const foundWho = whos.findIndex(x => x._id == proID);
            if (foundWho != -1)
              setActiveWho(foundWho);
            setMainObj(json?.data?.mainObj);
            // setting more items
            if (type == 'country') {
              let tempMore = 0, more = [];
              headerData?.countries?.map((item, index) => {
                if (json?.data?.mainObj?.continentId == item?.continentId)
                  if (json?.data?.mainObj?._id !== item?._id) {
                    // console.log('printing more', moreItem, item?._id);
                    if (tempMore < 6) {
                      tempMore = tempMore + 1;
                      more.push(item);
                    }
                  }
              });
              setMoreItem(more);
            }
            setTotalPages(json?.data?.totalCount / 9);
            console.log('response from property filter list', json);
            if (json?.data?.properties?.length > 0) {
              let data = json?.data?.properties;
              data.push({ type: 'ads' });
              setPropertydetail(data);
              setCurrentPage(json?.currentPage + 1);
            }
            else {
              setLoader(false);
              setPropertydetail([]);
            }
          } else {
            setCurrentPage(json?.currentPage + 1);
            setPropertydetail([...propertydetail, ...json?.data?.properties]);
          }
        } else {
          setLoader(false);
          toast.error(res?.error);
        }
      }).catch((err) => {
        toast.error(err);
        console.log(err)
        setLoader(false);
        setLoadingMore(false);
      })
    }
  }

  const handleProLike = (id) => {
    if (user) {
      const body = {
        token: user?.token,
        propertyId: id
      }
      post("/property/like", body)
        .then((res) => {
          // console.log('response from like property', res);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            let temp = Object.assign([], propertydetail);
            let find = temp.findIndex(x => x?._id == id);
            if (find != -1) {
              temp[find].likes.push(user?._id);
              console.log('removing or adding likes', temp[find]?.likes);
              setPropertydetail(temp);
            }
            // getProperties(id);
          } else
            toast.error(res?.error);
        })
        .catch(error => {
          console.log('error while liking property', error);
          toast.error('Something went wrong');
        })
    } else {
      navigate("/login");
    }
  }

  const removeLike = () => {
    const body = {
      token: user?.token,
      propertyId: shareData?._id
    }
    post("/property/like", body)
      .then((res) => {
        // console.log('response from like property', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          let temp = Object.assign([], propertydetail);
          let find = temp.findIndex(x => x?._id == shareData?._id);
          if (find != -1) {
            console.log('find prop', find, temp[find].likes);
            let found = temp[find].likes.findIndex(x => x == user?._id);
            temp[find].likes.splice(found, 1);
            setPropertydetail(temp);
            setIsUnlikeModal(false);
            setShareData(null);
          }
          // getProperties(id);
        } else
          toast.error(res?.error);
      })
      .catch(error => {
        console.log('error while liking property', error);
        toast.error('Something went wrong');
      })
  }
  // console.log('removing or adding likes', propertydetail[0]?.likes);

  const compressId = (id) => {
    const temp = id?.slice(id?.length - 4, id?.length);
    // console.log('compressing id', temp);
    return temp;
  }

  const parseName = (str) => {
    if (str) {
      let temp = str.split(",").join("");
      return temp.split(" ").join("-").toLowerCase();
    } else
      return "";
  }

  const openProperty = (item) => {
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
    window.open("/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" + parseName(item?.location) + "-" + parseName(item?.country[0]?.name) + "-" + parseName(item?.country[0]?.continent[0]?.name), "_blank");
  }

  const openCountry = (item) => {
    window.open('/country/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(mainObj?.continent[0]?.name) + "-" + (item?.name), "_blank");
  }

  const handleFilterClick = (to, item) => {
    window.open(`/${to}/${compressId(item?._id)}/top-travel-destinations-for-${parseName(item?.name)}`, '_blank');
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, propertydetail]);


  const handleScroll = () => {
    setCurrentScroll(window.scrollY);
    let divHeight = document.getElementById("scrollableView")?.scrollHeight;
    if (window?.scrollY > (divHeight))
      if (totalPages > currentPage)
        getProperties();
    // toast.success("loading more...")
    // console.log('current scroll posistion', window.scrollY, divHeight);
    // getCountries();
    //     getSearch();
  }

  return (

    <>
      {
        loader && <Loader />
      }
      {/* <HelmetProvider> */}
      {mainObj &&
        <Helmet>
          <title> {mainObj?.name} | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, best top travel destination in${experience?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={mainObj?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${mainObj?.name} | Woow Destinations`} />
          <meta name="twitter:description" content={mainObj?.desc} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={mainObj?.banner} />
          <meta property="og:image:alt" content={mainObj?.desc} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${mainObj?.name} | Woow Destinations`} />
          <meta property="og:url" content={"https://woowdestinations.com" + location?.pathname} />
          <meta property="og:description" content={mainObj?.desc} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, best top travel destination in${experience?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={mainObj?.desc} />
          {/* <meta name="geo.region" content="IN-GN" />
         <meta name="geo.placename" content={propertydetail?.location} />
         <meta name="geo.position" content={`${propertydetail?.lat};${propertydetail?.long}`} />
         <meta name="ICBM" content={`${propertydetail?.lat};${propertydetail?.long}`} /> */}
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://woowdestinations.com/" />
        </Helmet>
      }
      {type == 'experience' && experience[activeExp] &&
        <Helmet>
          <title> {experience[activeExp]?.name} | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, best top travel destination in${experience?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={experience[activeExp]?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${experience[activeExp]?.name} | Woow Destinations`} />
          <meta name="twitter:description" content={experience[activeExp]?.desc} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={experience[activeExp]?.banner} />
          <meta property="og:image:alt" content={experience[activeExp]?.name} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${experience[activeExp]?.name} | Woow Destinations`} />
          <meta property="og:url" content={"https://woowdestinations.com" + location?.pathname} />
          <meta property="og:description" content={experience[activeExp]?.desc} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, best top travel destination in${experience?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={experience[activeExp]?.desc} />
          {/* <meta name="geo.region" content="IN-GN" />
            <meta name="geo.placename" content={propertydetail?.location} />
            <meta name="geo.position" content={`${propertydetail?.lat};${propertydetail?.long}`} />
            <meta name="ICBM" content={`${propertydetail?.lat};${propertydetail?.long}`} /> */}
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://woowdestinations.com/" />
        </Helmet>
      }
      {type == 'who' && whos[activeWho] &&
        <Helmet>
          <title>{whos[activeWho]?.name} | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, best top travel destination for${whos?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={whos[activeWho]?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${whos[activeWho]?.name} | Woow Destinations`} />
          <meta name="twitter:description" content={whos[activeWho]?.desc} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={whos[activeWho]?.banner} />
          <meta property="og:image:alt" content={whos[activeWho]?.name} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${whos[activeWho]?.name} | Woow Destinations`} />
          <meta property="og:url" content={"https://woowdestinations.com" + location?.pathname} />
          <meta property="og:description" content={whos[activeWho]?.desc} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, best top travel destination for${whos?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={whos[activeWho]?.desc} />
          {/* <meta name="geo.region" content="IN-GN" />
            <meta name="geo.placename" content={propertydetail?.location} />
            <meta name="geo.position" content={`${propertydetail?.lat};${propertydetail?.long}`} />
            <meta name="ICBM" content={`${propertydetail?.lat};${propertydetail?.long}`} /> */}
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://woowdestinations.com/" />
        </Helmet>
      }
      {type == 'month' && month[activeMon] &&
        <Helmet>
          <title>{month[activeMon]?.name} | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, best top travel destination in${month?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={month[activeMon]?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${month[activeMon]?.name} | Woow Destinations`} />
          <meta name="twitter:description" content={month[activeMon]?.desc} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={month[activeMon]?.banner} />
          <meta property="og:image:alt" content={month[activeMon]?.name} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${month[activeMon]?.name} | Woow Destinations`} />
          <meta property="og:url" content={"https://woowdestinations.com" + location?.pathname} />
          <meta property="og:description" content={month[activeMon]?.desc} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, best top travel destination in${month?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={month[activeMon]?.desc} />
          {/* <meta name="geo.region" content="IN-GN" />
            <meta name="geo.placename" content={propertydetail?.location} />
            <meta name="geo.position" content={`${propertydetail?.lat};${propertydetail?.long}`} />
            <meta name="ICBM" content={`${propertydetail?.lat};${propertydetail?.long}`} /> */}
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://woowdestinations.com/" />
        </Helmet>
      }
      {/* </HelmetProvider> */}
      {/* <Header setExperience={setExperience} setWhos={setWhos} setMonth={setMonth} setHeaderData={setHeaderData} /> */}
      <Share
        show={isShare}
        data={shareData}
        type={"Destination"}
        setShow={setIsShare}
        pathname={shareLink}
      />
      <UnlikeModal
        show={isUnlikeModal}
        data={shareData}
        type={"Destination"}
        remove={removeLike}
        setShow={setIsUnlikeModal}
      />
      <main id="rlr-main" className="rlr-main--fixed-top rlr_main_fixed_top_inner_page">
        <div className="rlr-search-results-page container">
          <div className="rlr-search-results-page__breadcrumb-section">
            {/* <!-- Breadcrumb --> */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb rlr-breadcrumb__items">
                <li className="breadcrumb-item rlr-breadcrumb__item"><Link to="/">Home</Link></li>
                {/* <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">country</li> */}
                {type == 'continent' || type == 'country' &&
                  <>
                    {mainObj &&
                      <>
                        <li className="breadcrumb-item rlr-breadcrumb__item" style={{ textTransform: 'capitalize', cursor: 'pointer' }}> <Link to={"/continent/top-travel-destinations-by-continents"}>Continent</Link></li>
                        <li className="breadcrumb-item rlr-breadcrumb__item" style={{ textTransform: 'capitalize', cursor: 'pointer' }}><Link to={`/continent/${compressId(mainObj?.continent[0]?._id)}/top-travel-destinations-in-${parseName(mainObj?.continent[0]?.name)}`}>{mainObj?.continent[0]?.name ? mainObj?.continent[0]?.name : ""}</Link></li>
                        <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">{mainObj?.name ? mainObj?.name : ""}</li>
                      </>
                    }
                  </>
                }
                {
                  type == 'experience' &&
                  <>
                    <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page"><Link to={`/experience/top-travel-destinations-by-experience`}>Experience</Link></li>
                    <li className="breadcrumb-item rlr-breadcrumb__item" style={{ textTransform: 'capitalize' }}>{experience[activeExp]?.name ? experience[activeExp]?.name : ""}</li>
                  </>
                }
                {
                  type == 'who' &&
                  <>
                    <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page"><Link to={`/who/top-travel-destinations-by-who`}>Who</Link></li>
                    <li className="breadcrumb-item rlr-breadcrumb__item" style={{ textTransform: 'capitalize' }}>{whos[activeWho]?.name ? whos[activeWho]?.name : ""}</li>
                  </>
                }
                {
                  type == 'month' &&
                  <>
                    <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page"><Link to={`/month/top-travel-destinations-by-months`}>Month</Link></li>
                    <li className="breadcrumb-item rlr-breadcrumb__item" style={{ textTransform: 'capitalize' }}>{month[activeMon]?.name ? month[activeMon]?.name : ""}</li>
                  </>
                }
              </ol>
            </nav>
            <div className="rlr-icon-text"><i className="rlr-icon-font flaticon-email mt-1"> </i> <span className="rlr-search-results-page__phone">Questions? {email}</span></div>
          </div>
          <div className='banner-section'>
            {type == 'continent' &&
              <img src={mainObj?.banner} alt={propertydetail[0]?.banner} />
            }
            {type == 'country' &&
              <img src={mainObj?.banner} alt={propertydetail[0]?.banner} />
            }
            {type == 'experience' &&
              <img src={experience[activeExp]?.banner} alt={experience[activeExp]?.banner} />
            }
            {type == 'who' &&
              <img src={whos[activeWho]?.banner} alt={whos[activeWho]?.banner} />
            }
            {type == 'month' &&
              <img src={month[activeMon]?.banner} alt={month[activeMon]?.banner} />
            }
          </div>
          {/* <!-- Dynamic filter --> */}
          <aside className="row rlr-search-results-page__dynamic-filter-section rlr-search-results-page__dynamic-filter-section_new">
            {/* <!-- Swiper --> */}
            <div className="swiper_h" style={{ position: 'relative' }}>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}

                centeredSlides={true}
                loop={true}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}

                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {type == 'continent' || type == 'country' || type == 'experience' &&
                  <>
                    {experience?.map((item, index) => (
                      <SwiperSlide onClick={() => handleFilterClick("experience", item)}>
                        <Link className="rlr-icon-text btn rlr-icon-text__card rlr-icon-text--anchor"> <span className="rlr-icon-text__card-title">{item?.name} ({item?.properties?.length})</span> </Link>
                      </SwiperSlide>
                    ))}
                  </>
                }
                {type == 'who' &&
                  <>
                    {whos?.map((item, index) => (
                      <SwiperSlide onClick={() => handleFilterClick("who", item)}>
                        <Link className="rlr-icon-text btn rlr-icon-text__card rlr-icon-text--anchor"> <span className="rlr-icon-text__card-title">{item?.name} ({item?.properties?.length})</span> </Link>
                      </SwiperSlide>
                    ))}
                  </>
                }
                {type == 'month' &&
                  <>
                    {month?.map((item, index) => (
                      <SwiperSlide onClick={() => handleFilterClick("month", item)}>
                        <Link className="rlr-icon-text btn rlr-icon-text__card rlr-icon-text--anchor"> <span className="rlr-icon-text__card-title">{item?.name} ({item?.properties?.length})</span> </Link>
                      </SwiperSlide>
                    ))}
                  </>
                }
              </Swiper>
              { type == 'experience' &&
                <>
                  <button type="button" onClick={() => swiperRef.current?.slidePrev()} className="btn rlr-button splide__arrow splide__arrow--prev splide__arrow--prev_n">
                    <i className="rlr-icon-font flaticon-left-chevron"> </i>
                  </button>
                  <button type="button" onClick={() => swiperRef.current?.slideNext()} className="btn rlr-button splide__arrow splide__arrow--next splide__arrow--next_n">
                    <i className="rlr-icon-font flaticon-chevron"> </i>
                  </button>
                </>
              }
              {type == 'who' &&
                <>
                  <button type="button" onClick={() => swiperRef.current?.slidePrev()} className="btn rlr-button splide__arrow splide__arrow--prev splide__arrow--prev_n">
                    <i className="rlr-icon-font flaticon-left-chevron"> </i>
                  </button>
                  <button type="button" onClick={() => swiperRef.current?.slideNext()} className="btn rlr-button splide__arrow splide__arrow--next splide__arrow--next_n">
                    <i className="rlr-icon-font flaticon-chevron"> </i>
                  </button>
                </>
              }
              {type == 'month' &&
                <>
                  <button type="button" onClick={() => swiperRef.current?.slidePrev()} className="btn rlr-button splide__arrow splide__arrow--prev splide__arrow--prev_n">
                    <i className="rlr-icon-font flaticon-left-chevron"> </i>
                  </button>
                  <button type="button" onClick={() => swiperRef.current?.slideNext()} className="btn rlr-button splide__arrow splide__arrow--next splide__arrow--next_n">
                    <i className="rlr-icon-font flaticon-chevron"> </i>
                  </button>
                </>
              }

            </div>

            {type == 'country' &&
              <form className="rlr-banner-search rlr-banner-search--hero-half-mast mb-0">
                <div className="rlr-banner-search__input-wrapper">

                  <div className="rlr-banner-input-group rlr-banner-input-group rlr-banner-input-group--home-search rlr-js-autocomplete-demo rlr-banner-search__banner-input rlr-js-search-layout-wrapper">
                    <label className="rlr-banner-input-group__label" for="destination_input">
                      <mark>Capital</mark>
                    </label>
                    <div className="rlr-banner-input-group__input-wrapper">
                      <i className="rlr-icon-font flaticon-star-1"> </i>
                      <span className="">{mainObj?.capital}</span>
                    </div>
                  </div>

                  <div className="rlr-banner-input-group rlr-js-autocomplete-activity-demo rlr-banner-search__banner-input rlr-js-search-layout-wrapper">
                    <label className="rlr-banner-input-group__label" for="rlr-banner-input-group-activity">
                      <mark>Language</mark>
                    </label>
                    <div className="rlr-banner-input-group__input-wrapper">
                      <i className="rlr-icon-font fa fa-language"> </i>
                      <span className="">{mainObj?.language}</span>
                    </div>
                  </div>


                  <div className="rlr-banner-input-group rlr-banner-search__banner-input rlr-js-search-layout-wrapper">
                    <label className="rlr-banner-input-group__label" for="rlr-banner-input-group-dates">
                      <mark>Time Diffrence</mark>
                    </label>
                    <div className="rlr-banner-input-group__input-wrapper">
                      <i className="rlr-icon-font fa fa-clock"> </i>
                      <span className="">{mainObj?.timeDiff}</span>
                    </div>
                  </div>
                  <div className="rlr-banner-input-group rlr-banner-search__banner-input rlr-js-search-layout-wrapper">
                    <label className="rlr-banner-input-group__label" for="rlr-banner-input-group-dates">
                      <mark>Currency</mark>
                    </label>
                    <div className="rlr-banner-input-group__input-wrapper">
                      <i className="rlr-icon-font fa fa-usd"> </i>
                      <span className="">{mainObj?.currency}</span>
                    </div>
                  </div>
                </div>

              </form>
            }
            {type !== 'country' &&
              <div className='mmargin_bottom_100px'>

              </div>
            }
          </aside>
          {/* <!-- Product cards --> */}
          <div className="row rlr-search-results-page__product-details pt-0">

            <div className="rlr-search-results-page__product-list col-lg-12" id="scrollableView">
              <div className={`row rlr-search-results-page__card-wrapper rlr-search-results-page__card-wrapper_bot ${type == 'country' && 'mb-0'} ${type !== 'experience' || type !== 'month' || type !== 'who' && 'mb-0'}`}>
                {
                  propertydetail?.map((item, index) => {
                    // console.log('pro item banne', item?.banner)
                    if (ads?.length > 0 && item?.type && item?.type == 'ads')
                      return (
                        <div style={{ width: '100%' }}>
                          {ads.map((item) => {
                            if (item?.position == 'top')
                              return (
                                <div className='add_section_mid'>
                                   <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} alt={item?.banner} /></a>
                                </div>
                              )
                          })}
                        </div>
                      )
                    else
                      return (
                        <div className="col-md-6 col-lg-4">
                          {/* <!-- Product card item --> */}
                          <article className="rlr-product-card rlr-product-card--v3" itemscope >
                            {/* <!-- Product card image --> */}
                            <figure className="rlr-product-card__image-wrapper">
                              {/* <span className="rlr-badge rlr-badge-- rlr-badge--accent-red rlr-product-card__badge"> -20% Today </span> */}
                              <div className="rlr-product-detail-header__button-wrapper">
                                {item?.likes && item?.likes?.findIndex(x => x == user?._id) != -1 ?
                                  <button type="button" onClick={() => { setShareData(item); setIsUnlikeModal(true) }} className={"btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist active"} aria-label="Save to Wishlist">
                                    <i className="rlr-icon-font flaticon-heart-1"> </i>
                                  </button>
                                  :
                                  <button type="button" onClick={() => handleProLike(item?._id)} className={"btn rlr-button rlr-button--circle rlr-wishlist rlr-wishlist-button--light rlr-wishlist-button rlr-js-action-wishlist"} aria-label="Save to Wishlist">
                                    <i className="rlr-icon-font flaticon-heart-1"> </i>
                                  </button>
                                }
                                <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                              </div>
                              <div className="">
                                <Swiper
                                  spaceBetween={10}
                                  centeredSlides={true}
                                  // navigation={true}
                                  modules={[Autoplay, Navigation]}
                                  className="mySwiper list_property"
                                >
                                  <SwiperSlide>
                                    <img itemprop="image" src={item?.banner} className="lazyload" alt={item?.name} />
                                  </SwiperSlide>
                                  {item?.banners?.map((img) => (
                                    <SwiperSlide>
                                      <img itemprop="image" src={img} className="lazyload" alt={item?.name} />
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            </figure>
                            <div style={{ cursor: 'pointer' }} className="rlr-product-card__anchor-title" onClick={() => openProperty(item)}>
                              <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                                <header className="rlr-product-card__header">
                                  <div>
                                    <Link className="rlr-product-card__anchor-title">
                                      <h2 className="rlr-product-card__title" itemprop="name">{item?.name}</h2>
                                    </Link>
                                    <div>
                                      <Link className="rlr-product-card__anchor-cat">
                                        <span className="rlr-product-card__sub-title">{item?.location} | {item?.country[0]?.name}</span>
                                      </Link>
                                    </div>
                                  </div>
                                </header>
                                <div className="rlr-product-card__details">
                                  <div className="rlr-product-card__prices" itemprop="offers" itemscope>
                                    <span className="rlr-product-card__from">{item?.nights}</span>
                                    <span className="rlr-product-card__rating-text" itemprop="reviewCount"></span>
                                  </div>
                                  <div className="rlr-product-card__ratings" itemprop="aggregateRating" itemscope >
                                    <div className="rlr-review-stars" itemprop="ratingValue" itemscope >
                                      {/* <StarRatings
                                        rating={item?.avgRating ? typeof item?.avgRating == 'string' ? Number(item?.avgRating) : item?.avgRating : 0}
                                      starRatedColor={'#f7de00'}
                                      starHoverColor={'#f7de00'}
                                      numberOfStars={5}
                                      isSelectable={false}
                                      name='rating'
                                      starDimension={'20px'}
                                      starSpacing={'2px'}
                                      /> */}
                                      {/* {item?.avgRating &&
                                        <span className="rlr-product-card__rating-text" itemprop="reviewCount">{item?.avgRating} ({item?.reviews?.length})</span>
                                      } */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                  })
                }
              </div>
              <hr className="rlr-search-results-page__divider" />
            </div>
            {
              loadingMore &&
              <div className="loading-container container">
                <img src={require('../assets/images/loading.gif')} alt="loading" />
                <span>Loading...</span>
              </div>
            }
            {/* <div className="rlr-secondary-menu-desc mt-4" data-id="rlr-product-sec-overview">
              <div className="rlr-secondary-menu-desc__details">
                <div className="rlr-overview-detail">

                  <div className="rlr-readmore-desc rlr-overview-detail__description row">
                    <div className="rlr-section-header description_heading_t">
                      <div className="rlr-section__title">
                        <h2 className="rlr-section__title--main">About {mainObj?.name}</h2>
                      </div>
                    </div>
                    <div className='col-lg-9'>
                      <p className="rlr-readmore-desc__content rlr-js-desc">
                        <HTMLRenderer html={mainObj?.desc} />
                      </p>
                    </div>
                    <div className='col-lg-3'>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {ads.map((item) => {
              if (item?.position == 'bottom')
                return (
                  <div className='addSection addSection_inner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} /></a>
                  </div>
                )
            })}
            {type == 'country' &&
              <section className="rlr-section rlr-section__mt rlr-related-product-wrapper">
                {/* <!-- Section heading --> */}
                <div className="rlr-section-header">
                  {/* <!-- Section heading --> */}
                  <div className="rlr-section__title">
                    <h2 className="rlr-section__title--main">Also Check More Countries</h2>
                    <span className="rlr-section__title--sub">Explore the diverse array of destinations within this beautiful countries.</span>
                  </div>
                  <div className="button-row">
                    <Link target='_blank' to={`/continent/${compressId(mainObj?.continent[0]?._id)}/top-travel-destinations-in-${parseName(mainObj?.continent[0]?.name)}`} className="btn rlr-button rlr-button--large rlr-button--rounded rlr-button--brand rlr_button_brand"> Check All </Link>
                    {/* <span>{`/continent/${compressId(mainObj?.continent[0]?._id)}/top-travel-destinations-in-${(mainObj?.continent[0]?.name)}`}</span> */}
                  </div>
                </div>
                <div className="row rlr-featured__cards">
                  {moreItem?.map((item, index) => {
                    // console.log('more item', item);
                    return (
                      <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4 check_more_country">
                        <article className="rlr-product-card rlr-product-card--v3" itemscope >
                          <figure className="rlr-product-card__image-wrapper" >
                            <span className="rlr-badge rlr-badge-- rlr-badge-- rlr-badge--abs rlr-badge--abs-left"> {item?.properties?.length} Destinations </span>
                            <img itemprop="image" style={{ height: 150 }} src={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                          </figure>
                          <div className="rlr-product-detail-header__button-wrapper" style={{ top: '25px', right: '25px' }}
                            onClick={() => {
                              setShareData(item); setShareLink('/country/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name) + "-" + parseName(item?.continent[0]?.name));
                              setIsShare(true)
                            }}>
                            <button type="button" data-bs-toggle="popover-share" data-content-id="rlr-js-share-popover" id="rlr-js-share-button" className="share_btn rlr-badge btn rlr-button rlr-button--circle rlr-popover-button" aria-label="share">
                              <i className="rlr-icon-font flaticon-share-1"></i>
                            </button>
                            <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                          </div>
                          <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                            <header className="rlr-product-card__header">
                              <div onClick={() => openCountry(item)} style={{ cursor: 'pointer' }}>
                                <Link className="rlr-product-card__anchor-title">
                                  <h2 className="rlr-product-card__title" itemprop="name">{item.name} <span className="rlr-product-card__sub-title">({item?.capital})</span></h2>
                                </Link>
                                <div>
                                  <Link className="rlr-product-card__anchor-cat">
                                    <span className="rlr-product-card__sub-title">{item?.overview?.length > 95 ? item?.overview?.substring(0, 95) + "..." : item?.overview}</span>
                                  </Link>

                                </div>
                              </div>
                            </header>
                            <div className="rlr-product-card__footer">
                              <div className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font flaticon-three-o-clock-clock"> </i> <span className="">{item?.timeDiff} </span></div>
                              <ul className="rlr-product-card__icon-text-list">
                                <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font fa fa-language"> </i> <span className="rlr-icon-text__title">{item?.language}</span></li>
                                <li className="rlr-icon-text rlr-product-card__icon-text"><i className="rlr-icon-font fa fa-usd"> </i> <span className="rlr-icon-text__title">{item?.currency} </span></li>
                              </ul>
                            </div>
                          </div>
                        </article>
                      </div>
                    )
                  })
                  }
                </div>
              </section>
            }
          </div >
        </div >
      </main >
      {/* <Footer contis={headerData?.destination} experience={headerData?.experiences} month={headerData?.months} /> */}
    </>
  )
}
