import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { post } from '../../helper/helper_api';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

export default function ForgotPassword(props) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState("email");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showPass, setShowPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);

  const handleValidSubmit = (e, v) => {
    if (!loading) {
      setLoading(true);
      post("/forgot", { email: v?.email })
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            console.log('submitting reset form', v);
            toast.success(res?.message);
            setEmail(v?.email);
            setCurrentStep("otp");
            setMinutes(0);
            setSeconds(5);
          } else {
            console.log('error while sending otp for reseting password', res?.error);
            toast.error(res?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log('error while sending otp for reseting password', err);
          toast.error("Something Went Wrong!");
        })
    }
  }

  const verifyOTP = () => {
    if (otp?.length == 4) {
      if (!loading) {
        setLoading(true);
        post("/verify", { email: email, otp })
          .then((res) => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.message);
              setCurrentStep("password");
            } else {
              console.log('error while verifying otp for reseting password', res?.error);
              toast.error(res?.error);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log('error while verify otp for reseting password', err);
            toast.error("Something Went Wrong!");
          })
        }
      } else {
        setLoading(false);
        toast.error("Enter valid OTP!");
      }
  }

  const handleValidPass = (e, v) => {
    if (v?.password == v?.cPass) {
      let body = {
        email,
        otp,
        password: v?.password
      }
      setLoading(true);
      post("/reset_password", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            navigate(-1);
          } else {
            console.log('error while verifying otp for reseting password', res?.error);
            toast.error(res?.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log('error while verify otp for reseting password', err);
          toast.error("Something Went Wrong!");
        })
    } else {
      toast.error("Password Didn't Matched!");
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <main id="rlr-main" className="rlr-main--fixed-top">
        <div className="rlr-section__content--lg-top">
          <section className="rlr-section rlr-section__mt rlr-account">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 offset-lg-4">
                  <div className="rlr-authforms">
                    <div className="rlr-authforms__header">
                      <img src={require('../../assets/images/loading.gif')} alt="Logo" />
                      <h2>Forgot password?</h2>
                      <p>No worries, we’ll send you reset instructions.</p>
                    </div>
                    <div className="rlr-authforms__form">
                      {currentStep == "email" &&
                        <AvForm onValidSubmit={handleValidSubmit}>
                          <div className="rlr-authforms__inputgroup">
                            <label className="rlr-form-label rlr-form-label--light required"> Email </label>
                            <AvField
                              name="email"
                              placeholder="Enter your registered email"
                              type="mail"
                              required
                            />
                          </div>
                          {loading ?
                            <button type="button" className="btn rlr-button rlr-button--fullwidth rlr-button--primary">
                              <img src={require("../../assets/images/loading.gif")} style={{ width: 30, height: 30, border: '1px solid #fff', borderRadius: 20 }} />
                            </button>
                            :
                            <button type="submit" className="btn rlr-button rlr-button--fullwidth rlr-button--primary">Send OTP</button>
                          }
                        </AvForm>
                      }
                      {currentStep == "otp" &&
                        <>
                          <Label style={{ fontWeight: 500, color: '#546078' }}>Enter OTP</Label>
                          <div className="otp-input">
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={4}
                              renderSeparator={<span style={{ marginLeft: 5, marginRight: 5 }}></span>}
                              renderInput={(props) => <input {...props}
                                className='otp-input-field'
                                type="number"
                              />}
                            />
                          </div>
                          <div className='resend_otp_section'>
                            <button type="button" onClick={() => setCurrentStep("email")}>Wrong Email?</button>
                            {minutes < 1 && seconds < 1 ?
                              <button onClick={() => handleValidSubmit(null, { email })}>Resend OTP</button>
                              :
                              <button>{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}</button>
                            }
                          </div>
                          {loading ?
                            <button type="button" className="btn rlr-button rlr-button--fullwidth rlr-button--primary">
                              <img src={require("../../assets/images/loading.gif")} style={{ width: 30, height: 30, border: '1px solid #fff', borderRadius: 20 }} />
                            </button>
                            :
                            <>
                              <button type="button" onClick={verifyOTP} className="btn rlr-button rlr-button--fullwidth rlr-button--primary">Verify OTP</button>
                            </>
                          }
                        </>
                      }
                      {currentStep == "password" &&
                        <AvForm onValidSubmit={handleValidPass}>
                          <div className="rlr-authforms__inputgroup">
                            <label className="rlr-form-label rlr-form-label--light required">New Password</label>
                            <AvField
                              name="password"
                              placeholder="Enter New Password"
                              type={showPass ? "text" : "password"}
                              required
                            />
                            <i className={`fa fa-${showPass ? 'eye-slash' : 'eye'} fs-6`} onClick={()=>setShowPass(!showPass)}></i>
                          </div>
                          <div className="rlr-authforms__inputgroup">
                            <label className="rlr-form-label rlr-form-label--light required">Confirm Password</label>
                            <AvField
                              name="cPass"
                              placeholder="Enter Confirm Password"
                              type={showCPass ? "text" : "password"}
                              required
                            />
                            <i className={`fa fa-${showCPass ? 'eye-slash' : 'eye'} fs-6`} onClick={()=>setShowCPass(!showCPass)}></i>
                          </div>
                          {loading ?
                            <button type="button" className="btn rlr-button rlr-button--fullwidth rlr-button--primary">
                              <img src={require("../../assets/images/loading.gif")} style={{ width: 30, height: 30, border: '1px solid #fff', borderRadius: 20 }} />
                            </button>
                            :
                            <button type="submit" className="btn rlr-button rlr-button--fullwidth rlr-button--primary">Reset password</button>
                          }
                        </AvForm>
                      }
                    </div>
                    <div className="rlr-authforms__back">
                      <Link to="/login"><img src={require('../../assets/assets/images/back-arrow.png')} alt="back arrow" /> Back to log in</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div >
      </main >

    </>
  )
}
