
import React, { useEffect, useState } from 'react';
import './style.css'
// import './mite-assets.min.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from 'react-toastify';
import { get, post } from '../../helper/helper_api';
import useMasonry from "./use-masonry";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loader from '../../Loader';
import { Helmet } from 'react-helmet';

export default function BlogList(props) {
    let navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [dataPro, setDataPro] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [headerData, setHeaderData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPagePro, setCurrentPagePro] = useState(0);
    const [totalPagePro, setTotalPagePro] = useState(0);
    const [loadingPro, setLoadingPro] = useState(false);

    const { categories } = useMasonry(
        data,
        ".portfolio-list",
        ".masonry-grid",
        ".messonry-button",
        ".messonry-button button"
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log('path on listing', location.pathname.split("/"));
        let path = location.pathname.split("/");
        console.log("path", path[2]);
        if (path.length > 2) {
            setCategoryId(path[2]);
        } else {
            // getBlogs(0);
            getData(0);
        }
    }, [location?.pathname])

    useEffect(() => {
        // console.log(props?.headerData);
        if (props?.headerData) {
            setHeaderData(props?.headerData);
            setAllCategories(props?.headerData?.blogCat);
            getData(0);
        }
    }, [props?.headerData])

    useEffect(() => {

        getProperties();
    }, []);

    // useEffect(() => {
    //     if (categoryId)

    // }, [categoryId]);

    const getData = async (cp) => {
        post("/blog/list_by_cat", { categoryId, currentPage: cp })
            .then(res => {
                if (res?.statusCode == 200) {
                    let temp = cp == 0 ? [] : Object.assign([], data);
                    res?.data?.map((item, index) => {
                        // console.log(index%8);
                        if (index > 0 && index % 8 == 0) {
                            temp.push({ type: "tags" });
                            temp.push(item)
                        } else {
                            temp.push(item)
                        }
                    });
                    console.log('data to set', temp.length);
                    setData(temp);
                    setTotalPage(res?.totalPage);
                    setCurrentPage(res?.currentPage + 1);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("We are having a problem to load the blog, Please Try again later!")
                }
            })
            .catch(err => {
                setLoading(false);
                console.log('error while getting blogs', err);
                toast.error("We are having a problem to load the blog, Please Try again later!")
            });
    }

    // const getBlogs = (cp) => {
    //     setLoading(true);
    //     post("/blog/list_by_cat", { categoryId, currentPage: cp })
    //         .then(res => {
    //             if (res?.statusCode == 200) {
    //                 let temp = cp == 0 ? [] : Object.assign([], data);
    //                 res?.data?.map((item, index) => {
    //                     // console.log(index%8);
    //                     if (index > 0 && index % 8 == 0) {
    //                         temp.push({ type: "tags" });
    //                         temp.push(item)
    //                     } else {
    //                         temp.push(item)
    //                     }
    //                 });
    //                 console.log('data to set', temp.length);
    //                 setData(temp);
    //                 setTotalPage(res?.totalPage);
    //                 setCurrentPage(res?.currentPage + 1);
    //                 setLoading(false);
    //             } else {
    //                 setLoading(false);
    //                 toast.error("We are having a problem to load the blog, Please Try again later!")
    //             }
    //         })
    //         .catch(err => {
    //             setLoading(false);
    //             console.log('error while getting blogs', err);
    //             toast.error("We are having a problem to load the blog, Please Try again later!")
    //         });
    // }

    const getProperties = (current) => {
        if (!loadingPro) {
            setLoadingPro(true);
            let body = {
                search: "",
                currentPage: current == 0 ? 0 : currentPagePro
            }
            post('/property/search/list', body)
                .then((res) => {
                    // console.log('response form getting searched property', currentPage, res?.currentPage);
                    if (res?.statusCode == 200) {
                        let temp = current == 0 ? [] : Object.assign([], dataPro);
                        // console.log('old data', temp?.length > 0 ? temp[0]?.name : [], res?.data?.properties[0]?.name);
                        setDataPro([...temp, ...res?.data?.properties]);
                        setCurrentPagePro(res?.currentPage + 1);
                        setTotalPagePro(res?.totalCount);
                        setLoadingPro(false);
                    } else {
                        toast.error(res?.error);
                        setLoadingPro(false)
                    }
                })
                .catch(error => {
                    console.log('error while getting popular', error);
                    setLoadingPro(false)
                })
        }
    }

    const openDestination = (item) => {
        // setTimeout(() => {
        //   window.location.reload();
        // }, 100);
        navigate("/blog/" + compressId(item?._id) + "/" + (item?.name).split(" ").join("-").toLowerCase() + "-in-" + (item?.country[0]?.name).split(" ").join("-").toLowerCase() + "-" + (item?.country[0]?.continent[0]?.name).split(" ").join("-").toLowerCase());
    }

    useEffect(() => {
        let added = document.getElementById("2368669");
        if (added)
            added.remove();

        // setLoading(false);
        setTimeout(() => {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "2368669";
            script.async = true;
            script.function = ((d, sc, u) => {
                var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
                // console.log('getting element', s, p)
                s.type = 'text/javascript';
                s.async = true;
                s.src = u + '?v=' + (+new Date());
                p.parentNode.insertBefore(s, p);
            })(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
            document.body.appendChild(script);

        }, 500);
    }, []);
    useEffect(() => {
        let added = document.getElementById("2312218");
        if (added)
            added.remove();

        // setLoading(false);
        setTimeout(() => {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "2312218";
            script.async = true;
            script.function = ((d, sc, u) => {
                var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
                // console.log('getting element', s, p)
                s.type = 'text/javascript';
                s.async = true;
                s.src = u + '?v=' + (+new Date());
                p.parentNode.insertBefore(s, p);
            })(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
            document.body.appendChild(script);

        }, 500);
    }, []);

    const compressId = (id) => {
        const temp = id.slice(id.length - 4, id.length);
        // console.log('compressing id', temp);
        return temp;
    }

    const parseName = (str) => {
        if (str) {
            let temp = str.split(",").join("");
            return temp.split(" ").join("-").toLowerCase();
        } else
            return "";
    }

    const openExp = (item) => {
        return '/experience/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name);
    }

    const openWho = (item) => {
        return '/who/' + compressId(item?._id) + "/" + "top-travel-destinations-for-" + parseName(item?.name)
    }

    const openBlog = (item) => {
        return "/blog/" + compressId(item?._id) + "/" + parseName(item?.titleShort);
    }

    const openCat = (item) => {
        return '/blog-list/' + compressId(item?.category[0]._id) + "/blogs_by_" + parseName(item?.category[0]?.title)
    }

    const openCatDirect = (item) => {
        return '/blog-list/' + compressId(item?._id) + "/top-blogs-for-" + parseName(item?.title)
    }

    const openProp = (item) => {
        return "/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" +
            parseName(item?.country[0]?.name) + "-" +
            parseName(item?.continent[0]?.name);
    }

    const openCountry = (item) => {
        return `/country/${compressId(item?.countryId)}/top-travel-destinations-in-${parseName(item?.continent[0]?.name)}-${item?.country[0]?.name}`;
    }

    return (
        <>
            {
                loading && <Loader />
            }
            <Helmet>
                <title>Woow Destinations - Blogs</title>
                <meta name="keyword" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
                <meta name="description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
                <meta name="dc.language" content="English" />
                <meta name="dc.source" content="http://www.woowdestinations.com" />
                <meta name="dc.title" content="Woow Destinations" />
                <meta name="dc.keywords" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
                <meta name="dc.description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
                <meta name="Author" content="Woow Destinations" />
                <meta name="robots" content="index, follow" />
                <meta property="og:locale" content="en-in" />
                <meta property="og:type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="http://www.woowdestinations.com" />
            </Helmet>
            <section className="top-home-section top_home_section">
                <div className="container">
                    <div className="top-home-box top-home-box-common-overlay">
                        <div className="row">
                            {data?.length > 0 &&
                                <div className="col-lg-6 col-md-12">
                                    <div className="news-post image-post image_post_blogdetail image_post_blogdetail_one">
                                        <img src={data[0]?.image} alt={data[0]?.titleShort} />
                                        <div className="hover-post">
                                            {/* <Link className="category-link" to={openCat(data[0])}>{data[0]?.category[0]?.title}</Link> */}
                                            {/* <h2><Link to={openBlog(data[0])}>{data[0]?.titleShort}</Link></h2> */}
                                            <h2><Link target='_blank' to={openBlog(data[0])}>{data[0]?.titleShort?.length > 95 ? data[0]?.titleShort?.substring(0, 95) + "..." : data[0]?.titleShort}</Link></h2>
                                            <ul className="post-tags">
                                                <li>by {data[0]?.author[0]?.title}</li>
                                                <li><Link >{data[0]?.reviews?.length} comments</Link></li>
                                            </ul>
                                        </div>
                                        <div className='overlay'></div>
                                    </div>
                                </div>
                            }
                            <div className="col-lg-6 col-md-12">
                                <div className="row">
                                    {data?.length > 1 &&
                                        <div className="col-md-6">
                                            <div className="news-post standard-post left-align standard_post_left_align">
                                                <div className="image-holder" >
                                                    <Link href="#"><img src={data[1]?.image} alt={data?.titleShort} /></Link>
                                                    <div className='overlay'></div>
                                                </div>
                                                {/* <Link className="text-link" to={openCat(data[1])}>{data[1]?.category[0]?.title}</Link> */}
                                                <h2><Link target='_blank' to={openBlog(data[1])}>{data[1]?.titleShort?.length > 40 ? data[1]?.titleShort?.substring(0, 40) + "..." : data[1]?.titleShort}</Link></h2>
                                                <ul className="post-tags">
                                                    <li>by <Link>{data[1]?.author[0]?.title}</Link></li>
                                                    <li>{data[1]?.updatedAt}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {data?.length > 2 &&
                                        <div className="col-md-6">
                                            <div className="news-post standard-post left-align standard_post_left_align">
                                                <div className="image-holder" >
                                                    <Link ><img src={data[2]?.image} alt={data?.titleShort} /></Link>
                                                    <div className='overlay'></div>
                                                </div>
                                                {/* <Link className="text-link" to={openCat(data[2])}>{data[2]?.category[0]?.title}</Link> */}
                                                {/* <h2><Link to={openBlog(data[2])}>{data[2]?.titleShort}</Link></h2> */}
                                                <h2><Link target='_blank' to={openBlog(data[2])}>{data[2]?.titleShort?.length > 40 ? data[2]?.titleShort?.substring(0, 40) + "..." : data[2]?.titleShort}</Link></h2>
                                                <ul className="post-tags">
                                                    <li>by <Link >{data[2]?.author[0]?.title}</Link></li>
                                                    <li>{data[2]?.updatedAt}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {data?.length > 3 &&
                                    <div className="news-post image-post image_post_blogdetail image_post_blogdetail_two">
                                        <img src={data[3]?.image} alt={data[3]?.titleShort} />
                                        <div className="hover-post">
                                            {/* <Link className="category-link" to={openCat(data[3])}>{data[3]?.category[0]?.title}</Link> */}
                                            {/* <h2><Link to={openBlog(data[3])}>{data[3]?.titleShort}</Link></h2> */}
                                            <h2><Link target='_blank' to={openBlog(data[3])}>{data[3]?.titleShort?.length > 80 ? data[3]?.titleShort?.substring(0, 80) + "..." : data[3]?.titleShort}</Link></h2>
                                            <ul className="post-tags">
                                                <li>by {data[3]?.author[0]?.title}</li>
                                                <li><Link href="#">{data[3]?.reviews?.length} comments</Link></li>
                                            </ul>
                                        </div>
                                        <div className='overlay'></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fresh-section2 p-0">
                <div className="container">
                    <div className="title-section text-center">
                        <div className="title-section">
                            {/* <h1></h1> */}
                            <div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
                                {/* <!-- Section heading --> */}
                                <div className="rlr-section__title">
                                    <h2 className="rlr-section__title--main ">Blog by category</h2>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="widget category-widget">

                        <ul className="category-list list_posts_tag list_posts_tag_d">
                            {
                                allCategories?.map((item, index) => {
                                    return (
                                        <Link target='_blank' to={openCatDirect(item)}>
                                            {item?.title}
                                        </Link>
                                    )
                                })
                            }

                        </ul>
                    </div>
                </div>
            </section>
            <section className="blog-section mt-0 pb-0 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-box ">
                                <div className="title-section  title_section_blog_list">

                                    <div className="title-section text-center">
                                        <div className="title-section">
                                            {/* <h1></h1> */}
                                            <div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
                                                {/* <!-- Section heading --> */}
                                                <div className="rlr-section__title">
                                                    <h2 className="rlr-section__title--main ">Latest Posts</h2>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 row-cols-xl-2 portfolio-list mb-n30">
                                    {data?.length > 0 &&
                                        data.map((item, index) => {
                                            if (index > 3) {
                                                if (item?.type == 'tags') {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            // eslint-disable-next-line react/no-unknown-property
                                                            group={`any`}
                                                            className={`col masonry-grid mb-50`}>
                                                            <div className='item'>
                                                                <div className='news-post article-post'>
                                                                    <div className='tags_section image-holder'>
                                                                        <div className="widget list-widget">
                                                                            <h2>Popular mentions</h2>
                                                                            <ul className="list-posts list_posts_tag">
                                                                                {
                                                                                    headerData?.experiences?.map((item, index) => {
                                                                                        return (
                                                                                            <Link target='_blank' className="text-link" to={openExp(item)}>{item?.name}</Link>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    headerData?.whos?.map((item, index) => {
                                                                                        return (
                                                                                            <Link target='_blank' className="text-link" to={openWho(item)}>{item?.name}</Link>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </ul>
                                                                        </div>
                                                                        <div className="widget category-widget">
                                                                            <h2>Categories</h2>
                                                                            <ul className="category-list list_posts_tag">
                                                                                {
                                                                                    allCategories?.map((item, index) => {
                                                                                        return (
                                                                                            <Link target='_blank' to={openCatDirect(item)}>
                                                                                                {item?.title}
                                                                                            </Link>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            // eslint-disable-next-line react/no-unknown-property
                                                            group={`any`}
                                                            className={`col masonry-grid mb-50 `}
                                                        >
                                                            <div className="item ">
                                                                <div className="news-post article-post">
                                                                    <div className="image-holder">
                                                                        <img src={item?.image} alt={item?.titleShort} />
                                                                    </div>
                                                                    <div className='list_'>
                                                                        {
                                                                            item?.category?.map((item1) => {
                                                                                return (
                                                                                    <Link target='_blank' className="text-link text_link_" to={openCatDirect(item1)}>{item1?.title}</Link>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
                                                                    <ul className="post-tags">
                                                                        <li>{item?.updatedAt}</li>
                                                                        <li>3 comments</li>
                                                                        <li>by {item?.author[0]?.title}</li>
                                                                    </ul>
                                                                    <p>{item?.title}...</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                        )
                                    }
                                </div>
                                {currentPage < (totalPage / 12).toFixed(0) &&
                                    <div className="center-button">
                                        <a className="button-one" onClick={() => getData(currentPage + 1)}>Load More</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fresh-section2  fresh_section2 pb-0">
                <div className="container">
                    <div className="title-section text-center">

                        <div className="title-section ">
                            <div className="title-section">
                                {/* <h1></h1> */}
                                <div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
                                    {/* <!-- Section heading --> */}
                                    <div className="rlr-section__title">
                                        <h2 className="rlr-section__title--main ">Destinations you may like</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fresh-box">
                        <div className="row">

                            <div className="col-lg-12 col-md-12">

                                <ul className="list-thumb-posts list_thumb_posts">
                                    {dataPro?.map((item) => (
                                        <li>
                                            <div className="image-holder image_holder_com"><img src={item?.banner} alt={item?.name} /></div>
                                            <div className="list-post-content list_post_content">
                                                <Link target='_blank' className="text-link" to={openCountry(item)}>{item?.country[0]?.name} | {item?.continent[0]?.name}</Link>
                                                <h2><Link target='_blank' to={openProp(item)}>{item?.name}</Link></h2>
                                                <ul className="post-tags">
                                                    <li>{item?.nights}</li>
                                                    <li>{item?.avgRating ? Number(item?.avgRating).toFixed(1) : 0} Ratings</li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                {/* <span>{totalPagePro} {currentPagePro}</span> */}
                                {totalPagePro > currentPagePro * 9 &&
                                    <div className='load_mod mt-4' onClick={() => getProperties(currentPagePro)}>
                                        <Link target='_blank' className="button-one mb-0">Load More</Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}