import React from 'react'
import { useEffect } from 'react';

export default function PrivacyPolicy() {
    useEffect(() => {
		window.scrollTo(0, 0);
		
	}, [])
    return (
        <section className="rlr-about rlr-section rlr-section__my">
            <div className="container">
                <div className='sectin_comm'>
                    <p>At WooW Destinations, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit our website or use our services. By accessing or using our website, you consent to the practices described in this policy.</p>
                    <h4>Information We Collect: We may collect the following types of information when you interact with our website or services:</h4>
                    <ul>
                        <li>Personal Information: This includes but is not limited to your name, email address, contact number, postal address, and other information you provide when filling out forms or registering for an account.</li>
                        <li>Payment Information: To process transactions, we may collect payment details, such as credit card numbers, but we do not store this information on our servers. It is securely processed by our trusted payment gateway partners.</li>
                        <li>Log Data: We automatically collect certain information whenever you visit our website, such as your IP address, browser type, device information, access times, and referring website addresses.</li>
                         <li>Cookies and Similar Technologies: We use cookies and similar technologies to enhance your browsing experience, analyze usage patterns, and improve our website's performance. You can manage your cookie preferences through your browser settings.</li>
                    </ul>
                    <h4>How We Use Your Information: We use the collected information for the following purposes:</h4>
                    <ul>
                        <li>To provide and improve our services: We use your personal information to deliver the services you requested, process bookings, and respond to inquiries.</li>
                        <li>Marketing and Communication: With your consent, we may send you promotional materials, travel offers, and newsletters. You can opt-out of marketing communications at any time.</li>
                        <li>Personalization: We may use your data to tailor our website content and services to your preferences and interests.</li>
                        <li>Analytics: We analyze user behavior on our website to enhance our offerings and user experience.</li>
                        <li>Legal Compliance: We may use your information to comply with applicable laws and regulations or respond to legal requests.</li>
                    </ul>
                    <h4>How We Share Your Information: We may share your information with third parties under the following circumstances:</h4>
                    <ul>
                        <li>Service Providers: We may engage third-party service providers to assist with website operation, payment processing, marketing, and data analysis. These providers are obligated to protect your information and use it solely for the purpose of providing services to us.</li>
                        <li>Business Transfers: If WooW Destinations undergoes a merger, acquisition, or sale of assets, your personal information may be transferred as part of the business transaction.</li>
                        <li>Legal Requirements: We may disclose your information when required by law or to protect our rights, safety, or the rights and safety of others.</li>
                    </ul>
                    <h4>Your Choices and Rights: You have the right to:</h4>
                    <ul>
                        <li>Access and update your personal information. </li>
                        <li>Opt-out of marketing communications. </li>
                        <li>Request the deletion of your personal information, subject to legal obligations.</li>
                        <li>Withdraw consent to process your data.</li>
                    </ul>
                    <p><strong>Security:</strong> We employ industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no data transmission over the internet can be guaranteed 100% secure. You acknowledge this inherent risk and provide your information at your own risk.</p>
                    <p><strong>Third-Party Links:</strong> Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of any linked sites.</p>
                    <p><strong>Children's Privacy:</strong> Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a minor, please contact us to have it removed.</p>
                    <p><strong>Changes to this Privacy Policy:</strong> We may update this Privacy Policy from time to time. The revised version will be effective upon posting on our website. We encourage you to review this policy periodically.</p>
                    <p><strong>Contact Us:</strong> If you have any questions, concerns, or requests regarding this Privacy Policy or the data we hold about you, please contact us at <a href="mailto:woowdestinationsweb@gmail.com">woowdestinationsweb@gmail.com</a></p>
                    <p>By using our website or services, you acknowledge that you have read and understood this Privacy Policy, and you agree to its terms and conditions.</p>
                </div>
            </div>
        </section>
    )
}
