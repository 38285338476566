import React, { useEffect, useState } from 'react'
// import Header from '../../Header'
// import Footer from '../../Footer'
import { Link } from 'react-router-dom'
import { get, post } from '../../helper/helper_api';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { email } from '../../Utils';
import { Helmet } from 'react-helmet';
import Share from '../../component/ShareModal';

export default function List(props) {
  const location = useLocation();
  let navigate = useNavigate();
  // console.log('property item', location?.state)
  const [propertydetail, setPropertydetail] = useState([]);
  const [loader, setLoader] = useState(true);
  const [type, setType] = useState(null);
  const [sortBy, setSortBy] = useState(false);
  const swiperRef = useRef();
  const [properties, setProperties] = useState(0);
  const [experience, setExperience] = useState([]);
  const [headerData, setHeaderData] = useState(props?.headerData);
  const [destinationData, setDestinationData] = useState(headerData);
  const [ads, setAds] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [shareData, setShareData] = useState(null);
  const [shareLink, setShareLink] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log('path on listing headerData', destinationData);
    let path = location.pathname.split("/");
    if (path.length > 0)
      setType(path[1]);
    return;
  }, [location]);

  useEffect(() => {
    getCardList();
  }, []);

  useEffect(() => {
    if (props?.headerData) {
      setHeaderData(props?.headerData);
      if (props?.headerData?.destination?.map((item) => {
        let tours = 0;
        item?.countries?.map((con) => {
          tours = tours + con?.properties?.length;
        });
        item.tours = tours;
      }))
        getDestinationAdd();
    }
  }, [props.headerData])

  const getCardList = () => {
    // console.log('pro id type', type);
    get("/ads/list?screen=inner")
      .then(res => {
        // console.log('response from ads list', res)
        if (res?.statusCode == 200)
          setAds(res?.data);
      }).catch(err => {
        console.log('error while getting ads list', err);
        setLoader(false);
      });
  }

  const getDestinationAdd = () => {
    let properties = 0, data = [];
    console.log('header data on continent list', headerData?.destination);
    headerData?.destination?.map((con, index) => {
      // properties = properties + con.properties?.length;
      // data.push(con);
      if (index == 6){
        data.push({ type: 'ads' });
        data.push(con);
      }
      else
        data.push(con)
      if (index == headerData?.destination?.length - 1)
        setLoader(false);
    });
    setDestinationData({ ...headerData, destination: data })
    // console.log('datadatadatadata', data)
  }

  const shorByFunction = () => {
    setSortBy(!sortBy);
  }

  const compressId = (id) => {
    const temp = id.slice(id.length - 4, id.length);
    // console.log('compressing id', temp);
    return temp;
  }

  const parseName = (str) => {
    let temp = str.split(",").join("");
    return temp.split(" ").join("-").toLowerCase();
  }

  const openCountry = (item) => {
    navigate('/country/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + (propertydetail?.name).split(" ").join("-").toLowerCase() + "-" + (item?.name).split(" ").join("-").toLowerCase());
  }

  const openConti = (item) => {
    navigate('/continent/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name));
  }

  return (

    <>
      {
        loader && <Loader />
      }
      {destinationData?.destination?.length > 0 &&
        <Helmet>
          <title>Top Continent | Woow Destinations</title>
          <meta name="keywords" content={`Woow Destinations, best top travel destination in${destinationData?.destination?.map((item) => ` ${item?.name}`)}`} />
          <meta name="twitter:image:src" content={destinationData?.destination[0]?.banner} />
          <meta name="twitter:site" content="@woowdestinations" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`Top Continent | Woow Destinations`} />
          <meta name="twitter:description" content={destinationData?.destination[0]?.desc} />
          <meta property="og:locale" content="en-in" />
          <meta property="og:image" content={destinationData?.destination[0]?.banner} />
          <meta property="og:image:alt" content={destinationData?.destination[0]?.desc} />
          <meta property="og:site_name" content="Woow Destinations" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Top Continent | Woow Destinations`} />
          <meta property="og:url" content={"http://woowdestinations.com" + location?.pathname} />
          <meta property="og:description" content={destinationData?.destination[0]?.desc} />

          <meta name="dc.language" content="English" />
          <meta name="dc.source" content={"http://www.woowdestinations.com" + location.pathname} />
          <meta name="dc.title" content="woow destinations" />
          <meta name="dc.keywords" content={`Woow Destinations, best top travel destination in${destinationData?.destination?.map((item) => ` ${item?.name}`)}`} />
          <meta name="dc.description" content={destinationData?.destination[0]?.desc} />
          {/* <meta name="geo.region" content="IN-GN" />
         <meta name="geo.placename" content={propertydetail?.location} />
         <meta name="geo.position" content={`${propertydetail?.lat};${propertydetail?.long}`} />
         <meta name="ICBM" content={`${propertydetail?.lat};${propertydetail?.long}`} /> */}
          <meta name="Author" content="Woow Destinations" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="http://woowdestinations.com/" />
        </Helmet>
      }
      <Share
        show={isShare}
        data={shareData}
        type={"Destination"}
        setShow={setIsShare}
        pathname={shareLink}
      />
      <main id="rlr-main" className="rlr-main--fixed-top rlr_main_fixed_top_inner_page">
        <div className="rlr-search-results-page container">
          <div className="rlr-search-results-page__breadcrumb-section">
            {/* <!-- Breadcrumb --> */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb rlr-breadcrumb__items">
                <li className="breadcrumb-item rlr-breadcrumb__item"><Link to="/">Home</Link></li>
                {/* <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">country</li> */}
                <li className="breadcrumb-item rlr-breadcrumb__item">Continent</li>
                {/* <li className="breadcrumb-item rlr-breadcrumb__item active" aria-current="page">{propertydetail[0]?.name}</li> */}
              </ol>
            </nav>
            <div className="rlr-icon-text"><i className="rlr-icon-font flaticon-email mt-1" style={{ fontWeight: 'bold' }}> </i> <span className="rlr-search-results-page__phone">Questions? {email} </span></div>
          </div>
          <div className='banner-section'>
            <img src={destinationData?.destination[0]?.banner} alt={destinationData?.destination[0]?.banner} />
          </div>
          <aside className="row">
          </aside>
          <div className="rlr-section__title mt-3 pt-0 pb-2">
            <h2 className="rlr-section__title--main">Top {propertydetail?.name} Continent</h2>
          </div>

          {/* <!-- Destination cards --> */}

          <div className="row rlr-search-results-page__product-details">
            <div className="rlr-search-results-page__product-list col-xl-12">
              <div className="row rlr-search-results-page__card-wrapper rlr-search-results-page__card-wrapper_bot">
                {
                  destinationData?.destination?.map((item, index) => {
                    if (ads?.length > 0 && item?.type && item?.type == 'ads')
                      return (
                        <div style={{ width: '100%' }}>
                          {ads.map((item) => {
                            if (item?.position == 'top')
                              return (
                                <div className='add_section_mid'>
                                 <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} /></a>
                                </div>
                              )
                          })}
                        </div>
                      )
                    else
                      return (
                        <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                          <article className="rlr-product-card rlr-product-card--v3" itemscope >
                            <figure className="rlr-product-card__image-wrapper" >
                              {item?.tours > 0 &&
                                <span className="rlr-badge rlr-badge-- rlr-badge-- rlr-badge--abs rlr-badge--abs-left"> {item?.tours} Tours </span>
                              }
                              <img itemprop="image" style={{ height: 150 }} src={item?.banner} data-sizes="auto" className="lazyload" alt={item?.name} />
                              <div className="rlr-product-detail-header__button-wrapper" style={{ top: '25px', right: '25px' }}
                                onClick={() => {
                                  setShareData(item); setShareLink('/continent/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name));
                                  setIsShare(true)
                                }}>
                                <button type="button" data-bs-toggle="popover-share" data-content-id="rlr-js-share-popover" id="rlr-js-share-button" className="share_btn rlr-badge btn rlr-button rlr-button--circle rlr-popover-button" aria-label="share">
                                  <i className="rlr-icon-font flaticon-share-1"></i>
                                </button>
                                <span className="rlr-product-detail-header__helptext rlr-js-helptext"></span>
                              </div>
                            </figure>
                            <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                              <header className="rlr-product-card__header">
                                <div style={{ cursor: 'pointer' }} onClick={() => openConti(item)}>
                                  <Link className="rlr-product-card__anchor-title" >
                                    <h2 className="rlr-product-card__title" itemprop="name">{item.name} <span className="rlr-product-card__sub-title">({item?.countries?.length} Countries)</span></h2>
                                  </Link>
                                  <div>
                                    <Link className="rlr-product-card__anchor-cat">
                                      <span className="rlr-product-card__sub-title">{item?.overview?.length > 100 ? item?.overview?.substring(0, 100) + "..." : item?.overview}</span>
                                    </Link>

                                  </div>
                                </div>

                              </header>

                            </div>
                          </article>
                        </div>
                      )
                  })
                }
              </div>

            </div>
          </div>
          {/* <aside className="row rlr-search-results-page__dynamic-filter-section mb-0 px-5">
            <p className='first-paragraph' style={{ textAlign: 'center', padding: '0 100px' }}>{destinationData?.destination[0]?.overview?.split("/n").join('\n')}</p>
          </aside> */}
        </div>
        <section id="features" className="rlr-section rlr-section__mb landding__plugin">
          <div className="container">
            {ads.map((item) => {
              if (item?.position == 'bottom')
                return (
                  <div className='addSection addSection_inner pt-0' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} /></a>
                  </div>
                )
            })}
          </div>
        </section>
      </main>
    </>
  )
}
